import axios from 'axios'
import authHeader from "./auth-header";

export class IncotermsService {

    getIncoterms() {
        return axios.get('/api/incoterm', { headers: authHeader() })
            .then(res => res.data);
    }

    saveIncoterm(incoterm) {
        return axios.post('/api/incoterm', incoterm, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteIncoterm(incotermId) {
        return axios.delete(`/api/incoterm/${incotermId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}