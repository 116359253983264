import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useRef, useState} from "react";
import {FileUpload} from "primereact/fileupload";
import {DocumentsService} from "../../../service/DocumentsService";
import {useMountEffect} from "primereact/hooks";
import moment from "moment/moment";
import {isNullOrUndef} from "chart.js/helpers";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact";
import {Toast} from "primereact/toast";

export const AttachTab = (props) => {

    const [documentsList, setDocumentsList] = useState([])
    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const documentsService = new DocumentsService();

    useMountEffect(() => {
        documentsService.getAllDocuments(2, props.booking.id).then(data => {
            setDocumentsList(data)
        })
    })

    const myUploader = (e) => {
        const formData = new FormData()
        if(e.files.length > 0){
            e.files.forEach(f => {
                formData.append('file', f)
            })
        }
        documentsService.uploadDocuments(2, props.booking.id, formData).then(r => {
            if(r.status === 200){
                if (!isNullOrUndef(fileUploadRef.current)) {
                    fileUploadRef.current.clear()
                }
                let _documentsList = [...documentsList]
                _documentsList.push(r.data)
                setDocumentsList(_documentsList)
                toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
            }
        })
    }

    const deleteDocument = (docId) => {
        documentsService.deleteDocument(docId).then(r => {
            if(r.status === 200) {
                let _documentsList = [...documentsList]
                setDocumentsList(_documentsList.filter(d => d.id !== docId))
                toast.current.show({severity: 'success', summary: 'Document was deleted!', life: 3000});
            }
        })
    }

    const downloadDocument = (data) => {
        documentsService.download(data.id).then(r => {
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', data.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: `Are you sure you want to delete this [${data.fileName}] document ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteDocument(data.id),
            reject: () => null
        });
    }

    const actionTemplate = (data) => {
        return <div key={data.id} className="actions">
            <Button icon="pi pi-download" className="p-button-rounded p-button-text p-button-sm"
                        title={'Download'} onClick={() => downloadDocument(data)}/>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    disabled={props.isClosed}
                    title={'Delete'} onClick={() => confirmDelete(data)}/>
        </div>
    }

    return <div className="card">
        <Toast ref={toast} />
            <FileUpload ref={fileUploadRef} customUpload uploadHandler={myUploader} multiple
                        accept="image/*,application/pdf" maxFileSize={15000000}
                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
        <DataTable value={documentsList} showGridlines responsiveLayout="scroll"
                   size="small" dataKey="id" header={'Documents list'}>
            <Column style={{width: '150px'}} field={'fileName'} header={'File name'}/>
            <Column style={{width: '150px'}} field={'createdBy'} header={'Create'}/>
            <Column style={{width: '150px'}} field={'created'}
                    body={(d) => moment(d.create).format('YYYY-MM-DD')} header={'Date'}/>
            <Column style={{width: '55px'}} body={actionTemplate} header={'Actions'}/>
        </DataTable>
    </div>

}