import axios from 'axios'
import authHeader from "./auth-header";

export class CostsService {

    getCosts = (page, size, sortFields, filterParams) => {
        return axios.get(`/api/costs-report?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res);
    }

    exportToExcel(sortFields, filterParams, columns) {
        return axios.get(`/api/costs-report/export/excel?columns=${columns}${filterParams}&sort=${sortFields}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

}