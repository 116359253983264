import React, {useEffect, useState} from "react";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {useMountEffect} from "primereact/hooks";
import {AutoComplete} from "primereact/autocomplete";
import {CSSTransition} from 'react-transition-group'
import {Calendar} from "primereact/calendar";
import {ClientService} from "../../../service/ClientService";
import {UserService} from "../../../service/UserService";
import {Dropdown} from "primereact/dropdown";

export const CostsFilters = (props) => {

    const [filterList, setFilterList] = useState([]);
    const [filteredClients, setFilteredClients] = useState(null);
    const [managers, setManagers] = useState([]);

    const clientService = new ClientService();
    const userService = new UserService();

    const filterDateType = ['bookingDate']


    useMountEffect(() => {
        userService.getManagers().then(data => {
            data.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
            data.unshift({id: -1, name: '-----'})
            setManagers(data);
        });
        const filterListStorage = JSON.parse(window.localStorage.getItem(`costs-report-datatable-filters`));
        if (!isNullOrUndef(filterListStorage)) {
            for (const key in filterListStorage) {
                if (filterDateType.includes(key) && !isNullOrUndef(filterListStorage[key])) {
                    let dateFrom = !isNullOrUndef(filterListStorage[key][0])
                        ? moment(filterListStorage[key][0]).toDate() : null
                    let dateTo = !isNullOrUndef(filterListStorage[key][1])
                        ? moment(filterListStorage[key][1]).toDate() : null
                    filterListStorage[key] = [dateFrom, dateTo]
                }
            }
            setFilterList(filterListStorage)
        }
    })

    useEffect(() => {
        if (props.searchBooking > 0) {
            searchBooking()
        }
    }, [props.searchBooking])

    useEffect(() => {
        if (props.clearFilter > 0) {
            localStorage.removeItem(`costs-report-filter-data`);
            localStorage.removeItem(`costs-report-datatable-filters`);
            clearFilter()
        }
    }, [props.clearFilter])

    const searchBooking = () => {
        let filterParam = '', action = 0;
        window.localStorage.setItem(`costs-report-datatable-filters`, JSON.stringify(filterList))
        Object.keys(filterList).map(key => {
            if (!isNullOrUndef(filterList[key]) && filterList[key] !== '') {
                action = action + 1
                switch (key) {
                    case 'refId':
                        return filterParam += ',booking.id:' + filterList[key]
                    case 'client':
                        return filterParam += ',client.id:' + filterList[key].id
                    case 'statusId':
                        return filterParam += ',booking.status.id:' + filterList[key]
                    case 'costsManager':
                        return filterParam += ',client.costsManager.id:' + filterList[key]
                    case 'bookingManager':
                        return filterParam += ',booking.manager.id:' + filterList[key]
                    case 'containerNumber':
                        return filterParam += ',booking.cargos.containerNumber:' + filterList[key]
                    case 'bookingDate':
                        return filterParam += makeCalendarParams(filterList[key], 'booking.bookingDate')
                }
            } else if (filterList.hasOwnProperty(key)) {
                delete filterList[key]
            }
        });
        if (action === 0) {
            filterParam = null
        }
        localStorage.removeItem(`costs-report-filter-data`);
        props.setFilters(filterParam)
    }

    const makeCalendarParams = (dates, key) => {
        if (!isNullOrUndef(dates[0]) && !isNullOrUndef(dates[1])) {
            return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[1]).format('YYYY-MM-DD')}`
        } else if (!isNullOrUndef(dates[0])) {
            return `,${key}:${moment(dates[0]).format('YYYY-MM-DD')}`
        } else {
            return ''
        }

    }

    const onChangeDropDownValue = (e, columnKey) => {
        if (!isNullOrUndef(e.value) && e.value !== -1) {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const dropDownFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Dropdown id={`filter-${column.key}`} options={options}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                      onChange={(e) => onChangeDropDownValue(e, column.key)}
                      optionLabel={field} optionValue={'id'} dropdownIcon={null}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const loadFilters = () => {
        let filters = props.selectedColumns.map(c => {
            switch (c.key) {
                case 'refId':
                    return inputFilter(c);
                case 'client':
                    return autocompleteFieldFilter(c, filteredClients, searchClient, null, 'title');
                case 'bookingDate':
                    return calendarFieldFilter(c);
                case 'costsManager':
                case 'bookingManager':
                    return dropDownFieldFilter(c, managers, 'name');
                case 'containerNumber':
                    return inputFilter(c);
                case 'statusId':
                    return dropDownFieldFilter(c, props.statusOptions, 'name');
                default:
                    return ''
            }
        })

        return <div className={'p-fluid p-grid p-col-12 p-p-0'}>
            {filters}
        </div>
    }
    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.clientGroupId).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const clearFilter = () => {
        setFilterList([])
        if (props.filters !== '') {
            props.setFilters('')
        } else {
            props.getCosts()
        }
    }

    const inputFilter = (column) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <InputText id={`filter-${column.key}`}
                       value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                       className={'block'}
                       onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const autocompleteFieldFilter = (column, options, searchObj, template, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <AutoComplete id={`filter-${column.key}`}
                          value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                          suggestions={options} completeMethod={searchObj} minLength={3} showEmptyMessage
                          tooltip={'Min 3 characters'} itemTemplate={template} field={field}
                          onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const calendarFieldFilter = (column) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Calendar id={`filter-${column.key}`} selectionMode="range"
                      numberOfMonths={3} dateFormat={'yy-mm-dd'} locale={'lt'} showButtonBar
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : null}
                      onChange={(e) => setFilterList({...filterList, [column.key]: e.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    return <CSSTransition in={props.filter} timeout={{enter: 400, exit: 400}}
                          classNames="filters" unmountOnExit appear>
        <div id={'invoices-filter'} className={'mt-2'}>
            {props.selectedColumns.length > 0 ? loadFilters() : ''}
        </div>
    </CSSTransition>
}