import moment from "moment/moment";
import React from "react";
import {InvoicesService} from "./InvoicesService";
import {isNullOrUndef} from "chart.js/helpers";
import { saveAs } from 'file-saver';

export class ExportFileService {

    makeBookingExportList = (list) => {
        let exportList = []
        list.forEach(e => {
            let exp = {}
            exportList.push(exp)
        })
        return exportList;
    }

    userBodyTemplate = (data) => {
        if (data.users.length > 1) {
            return <div>
                {data.users.map(user => {
                    let manager = user.firstName + ' ' + user.lastName
                    return <div key={user.id}>{manager}</div>
                })}
            </div>
        } else {
            return data.users[0].firstName + ' ' + data.users[0].lastName
        }
    }

    makeManagersEventsExportList = (list) => {
        let exportList = []
        list.forEach(e => {
            let exp = {
                'Client': e.client.title,
                'Client Type': e.client.clientType !== null ? e.client.clientType.title : '',
                'Even Type': e.eventType.title,
                'Event status': e.eventStatus !== null ? e.eventStatus.name : '',
                'Note': e.notes,
                'Read': e.read,
                'Event Date': moment(e.date).format('YYYY-MM-DD HH:MM:SS'),
                'Done Date': moment(e.doneDateTime).format('YYYY-MM-DD HH:MM:SS'),
                'Managers': this.userBodyTemplate(e),
                'Resolution': e.resolution
            }
            exportList.push(exp)
        })
        return exportList;
    }
    makeNavisionExportList = (list) => {
        let exportData = {};
        let exportList = [];
        let idsList = [];
        list.forEach(e => {
            let exp = {
                'columnA': e.dateOfIssue,
                'columnB': e.invoiceNumber,
                'columnC': e.navisionCode,
                'columnD': e.currency,
                'columnE': e.uniqueNumber,
                'columnF': e.sum,
                'columnG': e.vatSum,
                'columnH': e.sumWithVat,
                'columnI': '',
                'columnJ': e.clientCode,
                'columnK': e.clientVatCode,
                'columnL': e.clientName,
                'columnM': e.clientAddress,
                'columnN': e.clientCity,
                'columnO': e.clientCountry,
                'columnP': 1,
                'columnQ': '',
                'columnR': '',
                'columnS': '',
            }
            idsList.push(e.uniqueNumber)
            exportList.push(exp)
        })
        exportData['exportList'] = exportList;
        exportData['invIdsList'] = idsList;
        return exportData;
    }

    exportXML = (clientId, dateFrom, dateTo, invoiceNumber, toast) => {
        const invoicesService = new InvoicesService();
        invoicesService.getInvoicesForExportXML(clientId, dateFrom, dateTo, invoiceNumber)
            .then(r => {
                const dateFromTxt = !isNullOrUndef(dateFrom) ? dateFrom : ''
                const dateToTxt = !isNullOrUndef(dateTo) ? dateTo : ''
                const fileName = dateFromTxt + '-' + dateToTxt + '_Navision_export'
                if (!isNullOrUndef(r)) {
                    const blob = new Blob([r], {type: 'text/xml'});
                    saveAs(blob, fileName + '.xml');
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: 'Invoices was not exported, please contact with administrator.',
                        life: 3000
                    });
                }
            })
    }

    exportExcel = (list, type, toast) => {
        if(list !== null && list.length > 0) {
            let exportList
            switch (type) {
                case 'Bookings':
                    exportList = this.makeBookingExportList(list)
                    break;
                case 'Managers_Events':
                    exportList = this.makeManagersEventsExportList(list)
                    break;
                case 'Navision':
                    exportList = this.makeNavisionExportList(list)
                    break;
                default:
                    toast.current.show({severity:'error', summary: 'Error Message', detail:'No found export type', life: 3000});
                    break;
            }
            if(exportList !== null) {
                let listToExport = type === 'Navision' ? exportList.exportList : exportList;
                import('xlsx').then(xlsx => {
                    const worksheet = xlsx.utils.json_to_sheet(listToExport, {skipHeader: type === 'Navision'});
                    const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
                    if(type === 'Navision'){
                        const first_sheet = workbook.Sheets[workbook.SheetNames[0]];
                        const csv = xlsx.utils.sheet_to_csv(first_sheet, {FS: ';'});
                        this.saveAsExcelFile(csv, type);
                        const invoicesService = new InvoicesService();
                        invoicesService.lockInvoices(exportList.invIdsList)
                            .then(r => {
                                if (r.success) {
                                    toast.current.show({
                                        severity: 'success',
                                        summary: 'Successful',
                                        detail: 'These invoices is locked.',
                                        life: 3000});
                                } else {
                                    toast.current.show({
                                        severity: 'error',
                                        summary: 'Error Message',
                                        detail: 'Invoices was not locked, please contact with administrator.',
                                        life: 3000
                                    });
                                }
                            })
                    } else {
                        const excelBuffer = xlsx.write(workbook, {bookType: 'xls', type: 'array'});
                        this.saveAsExcelFile(excelBuffer, type);
                    }
                });
            } else {
                toast.current.show({severity:'error', summary: 'Error Message', detail:'Cannot generate export list', life: 3000});
            }
        } else {
            toast.current.show({severity:'warn', summary: 'Warning Message', detail:'List is empty', life: 3000});
        }
    }

    saveAsExcelFile = (buffer, type) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = type === 'Navision' ? '.csv' : '.xls';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, type + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

}