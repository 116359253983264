import axios from 'axios'
import authHeader from "./auth-header";

export class CountryService {

    // getCountries() {
    //     return axios.get('assets/demo/data/countries.json')
    //         .then(res => res.data.data);
    // }

    getCountries() {
        return axios.get('/api/country', { headers: authHeader() })
            .then(res => res.data);
    }

    saveCountry(country) {
        // return axios.get('assets/demo/data/country-directions.json')
        //     .then(res => res.data.data);
        return axios.post('/api/country', country, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteCountry(countryId) {
        // return axios.get('assets/demo/data/country-directions.json')
        //     .then(res => res.data.data);
        return axios.delete(`/api/country/${countryId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}