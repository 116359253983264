import axios from 'axios'
import authHeader from "./auth-header";

export class PlacesService {

    getPlaces() {
        return axios.get(`/api/place`, { headers: authHeader() })
            .then(res => res.data);
    }

    addNewPlace(placeDTO) {
        return axios.post('/api/place', placeDTO, { headers: authHeader() })
            .then(res => res.data);
    }

    deletePlace(placeId) {
        return axios.delete(`/api/place/${placeId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    searchPlaces(name) {
        return axios.get(`/api/place?name=${name}`, { headers: authHeader() })
            .then(res => res.data);
    }
}