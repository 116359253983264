import React, {Fragment, useEffect, useRef, useState} from 'react';

import moment from "moment/moment";
import {Toast} from "primereact/toast";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact//button";
import {EventService} from "../../service/EventService";
import {Dropdown} from "primereact/dropdown";
import {UserService} from "../../service/UserService";
import {Tooltip} from "primereact/tooltip";
import {useMountEffect} from "primereact";
import {ExportFileService} from "../../service/ExportFileService";

export const ManagerByEvents = (props) => {

    const [reportsList, setReportsList] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userOptions, setUserOptions] = useState(null);
    const [userAccess, setUserAccess] = useState(false);
    const [clientsList, setClientsList] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [eventStatuses, setEventStatuses] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const eventService = new EventService();
    const userService = new UserService();
    const [multiSortMeta, setMultiSortMeta] = useState([
        { field: 'client.title', order: 1 },
        { field: 'date', order: 1 }
    ]);

    // const groupByOptions = [
    //     {title: 'Client', id: '1'},
    //     {title: 'Manager', id: '2'}
    // ]

    useMountEffect(() => {
        eventService.getEventTypes()
            .then(data => {
                setEventTypes(data);
            })
        eventService.getEventStatuses()
            .then(data => {
                setEventStatuses(data);
            });
        userService.getManagers()
            .then(data => {
                data.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
                setUserOptions(data)
            })
    });

    useEffect(() => {
        if(props.userData !== null && props.userData.authorities !== null && props.userData.authorities.length > 0){
            setUserAccess(props.userData.authorities.some(a => a === 'READ_ALL_EVENTS'))
        }
    }, [props.userData]);

    const getReportsList = () => {
        if (dateRange !== null) {
            setLoading(true)
            let from = moment(dateRange[0]).format('YYYY-MM-DD').toString()
            let to = moment(dateRange[1]).format('YYYY-MM-DD').toString()
            eventService.getManagerReportsByEvents(selectedUser, from, to)
                .then(data => {
                    setReportsList(data);
                    makeClientList(data)
                    setLoading(false);
                });
        } else {
            toast.current.show({severity:'error', summary: 'Error Message', detail:'Date range is not selected', life: 3000});
        }
    }

    const makeClientList = (data) => {
        const clients = data.map(item => item.client)
        const uniqueClients = [...new Map(clients.map(c => [c["id"], c])).values()];
        setClientsList(uniqueClients);
    }

    const renderHeader = () => {
        return (<Fragment>
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Manager by events</h5>
            </div>
            <br/>
            <div className="p-formgroup-inline p-ai-center" style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
                <div className="p-field">
                    <h5>Date Range</h5>
                    <Calendar value={dateRange} monthNavigator id={'dateRange'}
                              selectionMode="range" locale={'lt'} showButtonBar
                              onChange={(e) => setDateRange(e.value)} readOnlyInput
                              dateFormat="yy-mm-dd" placeholder="Select date range" mask="9999-99-99"/>
                </div>
                {userAccess ?
                <div className="p-field">
                    <h5>Managers</h5>
                    <Dropdown value={selectedUser} onChange={(e) => setSelectedUser(e.value)} showClear={true}
                              placeholder={'-- Select --'} options={userOptions} optionLabel={'name'} optionValue={'id'}/>
                </div> : ''}
                <div style={{margin: 'auto 0', paddingTop: '20px'}}>
                    <Button type="button" label="Generate" onClick={getReportsList}/>
                </div>
            </div>
            {/*<br/>*/}
            {/*<div>*/}
            {/*    <Button type="button" icon="pi pi-file-excel" onClick={() => exportFileService.exportExcel(reportsList, 'Managers_Events', toast)} className="p-button-success mr-2" data-pr-tooltip="XLS" />*/}
                {/*<Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />*/}
            {/*</div>*/}
        </Fragment>)
    }

    const userBodyTemplate = (data) => {
        if (data.users.length > 1) {
            return <div>
                {data.users.map(user => {
                    let manager = user.firstName + ' ' + user.lastName
                    return <div key={user.id}>{manager}</div>
                })}
            </div>
        } else {
            return data.users[0].firstName + ' ' + data.users[0].lastName
        }
    }

    const eventStatusBodyTemplate = (data) => {
        if (data.eventStatus === null)
            return;
        return <span style={{color: '#'+data.eventStatus.color}}>{data.eventStatus.name}</span>
    }

    const clientRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={clientsList} optionValue={'id'}
                         filter={true} filterMatchMode={'contains'} showClear={true}
                         onChange={(e) => options.filterApplyCallback(e.value)}
                         optionLabel="title" placeholder="-- Select --" className="p-column-filter" />;
    }

    const clientTypeRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.clientsTypes} optionValue={'id'} showClear={true}
                            onChange={(e) => options.filterApplyCallback(e.value)}
                            optionLabel="title" placeholder="-- Select --" className="p-column-filter" />;
    }

    const eventTypeRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={eventTypes} optionValue={'id'} showClear={true}
                            onChange={(e) => options.filterApplyCallback(e.value)}
                            optionLabel="title" placeholder="-- Select --" className="p-column-filter" />;
    }

    const eventStatusesRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={eventStatuses} optionValue={'id'} showClear={true}
                            onChange={(e) => options.filterApplyCallback(e.value)}
                            optionLabel="name" placeholder="-- Select --" className="p-column-filter" />;
    }

    // const getGroupBy = () => {
    //     if (groupBy === null || groupBy === undefined)
    //         return null
    //
    //     if (groupBy === 1) {
    //         return "client.title"
    //     } else if (groupBy === 2) {
    //         return "users"
    //     }
    // }

    const headerTemplate = (data) => {
        return <b style={{fontSize: '1.2rem'}}>{data.client.title}</b>
    }

    const dateBodyTemplate = (data) => {
        return moment(data.date).format('YYYY-MM-DD')
    }

    const notesResolutionBodyTemplate = (data) => {
        return <Fragment>
            <Tooltip target={'.notes-' + data.id} />
            <i className={'pi pi-bookmark notes-'+data.id} data-pr-position="top"
               style={{'fontSize': '1.3em'}} data-pr-tooltip={data.notes}/> /
            <Tooltip target={'.resolution-' + data.id} />
            <i className={'pi pi-bookmark-fill resolution-'+data.id} data-pr-position="top"
               style={{'fontSize': '1.3em'}} data-pr-tooltip={data.resolution}/>
        </Fragment>
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card" style={{ height: 'calc(100vh - 100px)' }}>
                    <Toast ref={toast}/>
                    <DataTable ref={dt} value={reportsList} dataKey="id" rowGroupMode={'subheader'}
                               header={renderHeader} paginator rows={10} rowHover
                               rowsPerPageOptions={[5, 10, 25]} removableSort filterDisplay="row"
                               id={'reports-event-datatable'} size="small" groupRowsBy={'client.title'}
                               sortMode="multiple" sortOrder={-1} scrollable scrollHeight="flex"
                               rowGroupHeaderTemplate={headerTemplate} multiSortMeta={multiSortMeta}
                               className="datatable-responsive" loading={loading} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                               emptyMessage="No reports.">
                        <Column field="client.title" header="Client" filter filterField="client.id"
                                filterElement={clientRowFilterTemplate} showFilterMenu={false} filterMatchMode={'equals'}
                                filterMenuStyle={{ width: '14rem'}} style={{ minWidth: '14rem' }}/>
                        <Column field="client.clientType.title" header="Type" filter filterField="client.clientType.id"
                                filterElement={clientTypeRowFilterTemplate} showFilterMenu={false} filterMatchMode={'equals'}
                                filterMenuStyle={{ width: '14rem'}} style={{ minWidth: '14rem' }}/>
                        <Column field="users" header="Managers" body={userBodyTemplate} showFilterMenu={false}/>
                        <Column field="eventType.title" header="Type" filter filterField="eventType.id" filterMatchMode={'equals'}
                                filterElement={eventTypeRowFilterTemplate} showFilterMenu={false}/>
                        <Column field="eventStatus" header="Status" body={eventStatusBodyTemplate} filter filterMatchMode={'equals'}
                                filterElement={eventStatusesRowFilterTemplate} filterField="eventStatus.id" showFilterMenu={false}/>
                        <Column field="date" header="Done" body={dateBodyTemplate} showFilterMenu={false}/>
                        <Column header="Notes/Resolution" body={notesResolutionBodyTemplate} showFilterMenu={false}/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}