import axios from 'axios'
import authHeader from "./auth-header";
import {isNullOrUndef} from "chart.js/helpers";

export class InvoicesService {

    getInvoices(page, size, type, sortFields, filterParams) {
        //types CREDIT/DEBIT/PURCHASE
        // is empty get CREDIT and DEBIT
        return axios.get(`/api/invoice?page=${page}&size=${size}&type=${type}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
                .then(res => res.data);
    }

    createInvoice(invoiceDTO) {
        return axios.post('/api/invoice', invoiceDTO, {headers: authHeader()})
            .then(res => res.data);
    }

    getInvoice = (invoiceId, type) => {
        return axios.get(`/api/invoice/${invoiceId}?type=${type}`, {headers: authHeader()})
            .then(res => res.data);
    }

    searchInvoice = (clientId, number) => {
        return axios.get(`/api/invoice/search-unused-credit?clientId=${clientId}&number=${number}`,
            {headers: authHeader()}).then(res => res.data);
    }

    getVatList = () => {
        return axios.get(`/api/vat`,
            {headers: authHeader()}).then(res => res.data);
    }

    getTaxTypes = () => {
        return axios.get(`/api/tax-type`,
            {headers: authHeader()}).then(res => res.data);
    }

    getInvoicePdf = (invoiceId) => {
        return axios.get(`/api/invoice/pdf/${invoiceId}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

    combineInvoices(invoices) {
        return axios.post('/api/invoice/combine', invoices, {headers: authHeader()})
            .then(res => res.data);
    }

    recalculate(invoice) {
        return axios.post('/api/invoice/recalculate', invoice, {headers: authHeader()})
            .then(res => res.data);
    }

    searchPendingDebitInvoices(clientId) {
        return axios.get(`/api/invoice/search-pending-debit?clientId=${clientId}`, {headers: authHeader()})
            .then(res => res.data);
    }

    detachInvoices(invoice) {
        return axios.post('/api/invoice/detach', invoice, {headers: authHeader()})
            .then(res => res.data);
    }

    getPaymentsSummary(filterParams, type) {
        return axios.get(`/api/invoice/summary?type=${type}${filterParams}`, {headers: authHeader()})
            .then(res => res.data);
    }

    getInvoicesForExport(clientId, dateFrom, dateTo, invoiceNumber) {
        let clientData = ''
        if(!isNullOrUndef(clientId)){
            clientData = `clientId=${clientId}&`
        }
        let invoiceNrData = ''
        if(!isNullOrUndef(invoiceNumber) && invoiceNumber.length > 0){
            invoiceNrData = `&invoiceNumber=${invoiceNumber}`
        }
        return axios.get(`/api/invoice/for-export?${clientData}dateFrom=${dateFrom}&dateTo=${dateTo}${invoiceNrData}`, {headers: authHeader()})
            .then(res => res.data);
    }

    getInvoicesForExportXML(clientId, dateFrom, dateTo, invoiceNumber) {
        let clientData = ''
        if(!isNullOrUndef(clientId)){
            clientData = `clientId=${clientId}&`
        }
        let invoiceNrData = ''
        if(!isNullOrUndef(invoiceNumber) && invoiceNumber.length > 0){
            invoiceNrData = `&invoiceNumber=${invoiceNumber}`
        }
        return axios.get(`/api/invoice/for-export-xml?${clientData}dateFrom=${dateFrom}&dateTo=${dateTo}${invoiceNrData}`, {headers: authHeader()})
            .then(res => res.data);
    }

    exportToExcel(type, sortFields, filterParams, columns) {
        return axios.get(`/api/invoice/export/excel?type=${type}&sort=${sortFields}${filterParams}&columns=${columns}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

    getAuditLog = (invoiceId) => {
        return axios.get(`/api/invoice/audit/${invoiceId}/changes`, {headers: authHeader()})
            .then(res => res.data);
    }

    lockInvoices = (invIds) => {
        return axios.post('/api/invoice/lock', invIds, {headers: authHeader()})
            .then(res => res.data);
    }

    changeDateValidate = (invoiceId, validate) => {
        let dateValidation = {
            invoiceId: invoiceId,
            overrideEditPermission: validate
        }
        return axios.post('/api/invoice/override-edit-permission', dateValidation, {headers: authHeader()})
            .then(res => res.data)
    }

    deletePurchase = (purchaseId) => {
        return axios.post(`/api/invoice/${purchaseId}/delete`, null,{headers: authHeader()})
            .then(res => res.data)
    }

}