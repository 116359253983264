import React, {useEffect, useState} from "react";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {useMountEffect} from "primereact/hooks";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import {CSSTransition} from 'react-transition-group'
import {Calendar} from "primereact/calendar";
import {StatusService} from "../../service/StatusService";
import {TradeTypesService} from "../../service/TradeTypesService";
import {ClientService} from "../../service/ClientService";
import {CurrencyService} from "../../service/CurrencyService";
import {MultiSelect} from "primereact/multiselect";
import {UserService} from "../../service/UserService";

export const InvoicesFilters = (props) => {

    const [filterList, setFilterList] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tradeTypes, setTradeTypes] = useState([]);
    const [filteredClients, setFilteredClients] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [managers, setManagers] = useState([]);

    const statusService = new StatusService();
    const tradeTypeService = new TradeTypesService();
    const clientService = new ClientService();
    const currencyService = new CurrencyService();
    const userService = new UserService();

    const filterDateType = ['issueDate', 'dueDate', 'paidDate', 'bookingDate']
    const invoiceTypes = [
        {id: -1, name: '-----'},
        {id: 'DEBIT', name: 'Debit'},
        {id: 'CREDIT', name: 'Credit'},
    ]

    const paidTypes = [
        {id: 'PAID', name: 'Paid'},
        {id: 'NOT_PAID', name: 'Unpaid'},
        {id: 'PARTIALLY_PAID', name: 'Paid/Unpaid'},
    ]

    useMountEffect(() => {
        userService.getManagers().then(data => {
            data.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
            data.unshift({id: -1, name: '-----'})
            setManagers(data);
        });
        statusService.getStatuses().then(data => {
            setStatusOptions(data)
        })
        tradeTypeService.getTradeTypes().then(data => {
            data.unshift({id: -1, name: '-----'})
            setTradeTypes(data)
        })
        currencyService.getCurrencies().then(data => {
            data.unshift({id: -1, code: '-----'})
            setCurrencies(data);
        })
        const filterListStorage = JSON.parse(window.localStorage.getItem(`${props.filterList}-datatable-filters`));
        if (!isNullOrUndef(filterListStorage)) {
            for (const key in filterListStorage) {
                if (filterDateType.includes(key) && !isNullOrUndef(filterListStorage[key])) {
                    let dateFrom = !isNullOrUndef(filterListStorage[key][0])
                        ? moment(filterListStorage[key][0]).toDate() : null
                    let dateTo = !isNullOrUndef(filterListStorage[key][1])
                        ? moment(filterListStorage[key][1]).toDate() : null
                    filterListStorage[key] = [dateFrom, dateTo]
                }
            }
            setFilterList(filterListStorage)
        }
    })

    useEffect(() => {
        if (props.searchInvoices > 0) {
            searchInvoices()
        }
    }, [props.searchInvoices])

    useEffect(() => {
        if (props.clearFilter > 0) {
            localStorage.removeItem(`${props.filterList}-filter-data`);
            localStorage.removeItem(`${props.filterList}-datatable-filters`);
            clearFilter()
        }
    }, [props.clearFilter])

    const searchInvoices = () => {
        let filterParam = ''
        let action = 0;
        window.localStorage.setItem(`${props.filterList}-datatable-filters`, JSON.stringify(filterList))
        Object.keys(filterList).map(key => {
            if (!isNullOrUndef(filterList[key]) && filterList[key] !== '') {
                action = action + 1
                switch (key) {
                    case 'number':
                        return filterParam += ',number:' + filterList[key]
                    case 'bookings':
                        return filterParam += ',bookings.id:' + filterList[key]
                    case 'status':
                        return filterParam += ',bookings.status.id~' + filterList[key].map(s => s).join(';')
                    case 'client':
                        return filterParam += ',client.id:' + filterList[key].id
                    case 'tradeType':
                        return filterParam += ',bookings.tradeType.id:' + filterList[key]
                    case 'invoiceType':
                        return filterParam += ',invoiceType:' + filterList[key]
                    case 'paid':
                        return filterParam += ',paidType~' + filterList[key].map(s => s).join(';')
                    case 'issueDate':
                        return filterParam += makeCalendarParams(filterList[key], key)
                    case 'dueDate':
                        return filterParam += makeCalendarParams(filterList[key], key)
                    case 'paidDate':
                        return filterParam += makeCalendarParams(filterList[key], key)
                    case 'bookingDate':
                        return filterParam += makeCalendarParams(filterList[key], 'bookings.bookingDate')
                    case 'currency':
                        return filterParam += ',currency.id:' + filterList[key]
                    case 'costManager':
                        return filterParam += ',client.costsManager.id:' + filterList[key]
                    case 'createdBy':
                        return filterParam += ',createdBy.id:' + filterList[key]
                    case 'issuedBy':
                        return filterParam += ',issuedBy.id:' + filterList[key]
                    case 'manager':
                        return filterParam += ',client.users.id:' + filterList[key]
                }
            } else if (filterList.hasOwnProperty(key)) {
                delete filterList[key]
            }
        });
        if (action === 0) {
            filterParam = null
        }
        localStorage.removeItem(`${props.filterList}-filter-data`);
        props.setFilters(filterParam)
    }

    const makeCalendarParams = (dates, key) => {
        if (!isNullOrUndef(dates[0]) && !isNullOrUndef(dates[1])) {
            return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[1]).format('YYYY-MM-DD')}`
        } else if (!isNullOrUndef(dates[0])) {
            return `,${key}:${moment(dates[0]).format('YYYY-MM-DD')}`
        } else {
            return ''
        }

    }

    // const makeCalendarBookingDateParams = (dates, key) => {
    //     if (!isNullOrUndef(dates[0]) && !isNullOrUndef(dates[1])) {
    //         return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[1]).endOf('month').format('YYYY-MM-DD')}`
    //     } else if (!isNullOrUndef(dates[0])) {
    //         return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[0]).endOf('month').format('YYYY-MM-DD')}`
    //     } else {
    //         return ''
    //     }
    //
    // }

    const loadFilters = () => {
        let filters = props.filterColumns.map(c => {
            switch (c.key) {
                case 'number':
                    return inputFilter(c);
                case 'bookings':
                    return inputFilter(c);
                case 'status':
                    return multiSelectFieldFilter(c, statusOptions, 'name');
                case 'client':
                    return autocompleteFieldFilter(c, filteredClients, searchClient, null, 'title');
                case 'tradeType':
                    return dropDownFieldFilter(c, tradeTypes, 'name');
                case 'invoiceType':
                    return dropDownFieldFilter(c, invoiceTypes, 'name');
                case 'paid':
                    return multiSelectFieldFilter(c, paidTypes, 'name');
                case 'currency':
                    return dropDownFieldFilter(c, currencies, 'code');
                case 'costManager':
                case 'createdBy':
                case 'issuedBy':
                case 'manager':
                    return dropDownFieldFilter(c, managers, 'name');
                case 'issueDate':
                case 'dueDate':
                case 'paidDate':
                    return calendarFieldFilter(c);
                case 'bookingDate':
                    return calendarFieldFilter(c);
                default:
                    return ''
            }
        })

        return <div className={'p-fluid p-grid p-col-12 p-p-0'}>
            {filters}
        </div>
    }

    // const searchPort = (event) => {
    //     if (event.query.trim().length > 2) {
    //         portService.searchPorts(event.query.trim().toLowerCase()).then(data => {
    //             setFilteredPorts(data)
    //         });
    //     }
    // }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.clientGroupId).then(data => {
                setFilteredClients(data)
            });
        }
    }

    // const searchPlace = (event) => {
    //     if (event.query.trim().length > 2) {
    //         placesService.searchPlaces(event.query.trim().toLowerCase()).then(data => {
    //             setFilteredPlaces(data);
    //         });
    //     }
    //
    // }

    const clearFilter = () => {
        setFilterList([])
        if (props.filters !== '') {
            props.setFilters('')
        } else {
            props.getInvoices()
        }
    }

    const inputFilter = (column) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <InputText id={`filter-${column.key}`}
                       value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                       className={'block'}
                       onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const portItemTemplate = (item) => {
        return item.name + ', ' + item.country.title
    }

    const onChangeDropDownValue = (e, columnKey) => {
        if (!isNullOrUndef(e.value) && e.value !== -1) {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const onChangeBooleanValue = (value, columnKey) => {
        if (!isNullOrUndef(value) && value) {
            setFilterList({...filterList, [columnKey]: value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const onChangeMultiSelectValue = (e, columnKey) => {
        if (!isNullOrUndef(e.value) && e.value.length > 0) {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const multiSelectFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
        <MultiSelect id={`filter-${column.key}`} maxSelectedLabels={1}
                     value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                     options={options} optionLabel={field} optionValue={'id'} dropdownIcon={null}
                     onChange={(e) => onChangeMultiSelectValue(e, column.key)}/>
                <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const dropDownFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Dropdown id={`filter-${column.key}`} options={options}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                      onChange={(e) => onChangeDropDownValue(e, column.key)}
                      optionLabel={field} optionValue={'id'} dropdownIcon={null}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }
    const autocompleteFieldFilter = (column, options, searchObj, template, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <AutoComplete id={`filter-${column.key}`}
                          value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                          suggestions={options} completeMethod={searchObj} minLength={3} showEmptyMessage
                          tooltip={'Min 3 characters'} itemTemplate={template} field={field}
                          onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }
    const booleanFieldFilter = (column, options) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <Checkbox inputId={`filter-${column.key}`}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : false}
                      checked={filterList[column.key]} style={{marginBottom: '7px'}}
                      onChange={(e) => onChangeBooleanValue(e.checked, column.key)}/>
            <label style={{marginLeft: '5px', marginTop: '10px'}}
                   htmlFor={`filter-${column.key}`}>{column.header}</label>
        </div>
    }

    const calendarFieldFilter = (column, options) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Calendar id={`filter-${column.key}`} selectionMode="range"
                      numberOfMonths={3} dateFormat={'yy-mm-dd'} locale={'lt'} showButtonBar
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : null}
                      onChange={(e) => setFilterList({...filterList, [column.key]: e.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    // const calendarBookingIdFilter = (column, options) => {
    //     return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
    //         <span className="p-float-label">
    //         <Calendar id={`filter-${column.key}`} selectionMode="range"  view="month" yearNavigator yearRange="2010:2030"
    //                   dateFormat={'ymm'} locale={'lt'} showButtonBar todayButtonClassName={'hidden'}
    //                   value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : null}
    //                   onChange={(e) => setFilterList({...filterList, [column.key]: e.value})}/>
    //         <label htmlFor={`filter-${column.key}`}>{column.header}</label>
    //         </span>
    //     </div>
    // }

    return <CSSTransition in={props.filter} timeout={{enter: 400, exit: 400}}
                          classNames="filters" unmountOnExit appear>
        <div id={'invoices-filter'} className={'mt-2'}>
            {props.filterColumns.length > 0 ? loadFilters() : ''}
        </div>
    </CSSTransition>
}