import React from "react";
import {isNullOrUndef} from "chart.js/helpers";
import {Badge} from "primereact/badge";


export const LazySortMultiFields = (props) => {

    const lazySorting = () => {
        let _lazyParams = {...props.lazyParams}
        if (_lazyParams.multiSortMeta.length > 0) {
            if (_lazyParams.multiSortMeta.some(s => s.key === props.fieldKey)) {
                _lazyParams.multiSortMeta.forEach((s, idx, obj) => {
                    if (s.key === props.fieldKey) {
                        if (s.order === 1) {
                            s.order = -1
                        } else {
                            obj.splice(idx, 1);
                        }
                    }
                })
            } else {
                _lazyParams.multiSortMeta.push({key: props.fieldKey, field: props.field, order: 1})
            }
        } else {
            _lazyParams.multiSortMeta.push({key: props.fieldKey, field: props.field, order: 1})
        }
        if(_lazyParams.multiSortMeta.length === 0){
            localStorage.removeItem(props.crud+'-sort-data');
        }
        props.setLazyParams(_lazyParams)
    }

    const headerWithSort = () => {
        let icon = 'pi-sort-alt'
        let sortFiled = props.lazyParams.multiSortMeta.find(s => s.key === props.fieldKey);
        let badge = ''
        if (!isNullOrUndef(sortFiled)) {
            icon = sortFiled.order === 1 ? 'pi-sort-amount-up' : 'pi-sort-amount-down'
            badge = <Badge style={{background: 'none', color: ' #3F51B5'}}
                           value={props.lazyParams.multiSortMeta.map(o => o.key).indexOf(props.fieldKey) + 1}/>
        }
        return <div className={'lazySort'} onClick={() => lazySorting()}>
            {props.header} <i className={'pi ' + icon + ' p-overlay-badge'}>{badge}</i>
        </div>
    }

    return headerWithSort()
}