import React, {useEffect, useRef, useState} from "react";
import {isNullOrUndef} from "chart.js/helpers";
import {useMountEffect} from "primereact/hooks";
import moment from "moment/moment";
import {BookingsService} from "../../service/BookingsService";
import {MultiSelect} from "primereact/multiselect";
import {ToggleButton} from "primereact/togglebutton";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {BookingFilters} from "../bookings/modules/BookingFilters";
import {IncotermsService} from "../../service/IncotermsService";
import {useHistory} from "react-router-dom";

export const BookingsOperationsReport = () => {

    const [bookingsOperationsList, setBookingsOperationsList] = useState([])
    const [loading, setLoading] = useState(true);
    const [incoterms, setIncoterms] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchBooking, setSearchBooking] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [operationsCount, setOperationsCount] = useState(0);
    const [bookingsCount, setBookingsCount] = useState(0);
    const bookingsService = new BookingsService();
    const incotermsService = new IncotermsService();
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;

    const columns = [
        {key: 'booking.id', header: 'Ref.ID', field: 'booking.id', width: '70px', order: 1, disabling: false, filter: true},
        {key: 'startBookingStatus', header: 'Booking status on start', field: 'startBookingStatus', width: '170px', order: 2, disabling: false, filter: true},
        {key: 'endBookingStatus', header: 'Booking status on end', field: 'endBookingStatus', width: '170px', order: 3, disabling: false, filter: true},
        {key: 'incotermId', header: 'Incoterms', field: 'incotermId', width: '170px', order: 4, disabling: false, filter: true},
        {key: 'operator', header: 'Operator', field: 'operator', width: '160px', order: 5, disabling: false, filter: true},
        {key: 'nextOperator', header: 'Transferring to', field: 'nextOperator', width: '160px', order: 6, disabling: false, filter: true},
        {key: 'transferType', header: 'Transfer type', field: 'transferType', width: '160px', order: 7, disabling: false, filter: true},
        {key: 'booking.operationalStatus', header: 'Operational status', field: 'booking.operationalStatus', width: '160px', order: 8, disabling: false, filter: true},
        {key: 'operationStartedAt', header: 'Operation started', field: 'operationStartedAt', width: '160px', order: 9, disabling: false, filter: true},
        {key: 'operationEndedAt', header: 'Operation ended', field: 'operationEndedAt', width: '160px', order: 10, disabling: false, filter: true},
        {key: 'comment', header: 'Comment', field: 'comment', width: '160px', order: 11, disabling: false, filter: false},
    ];

    useMountEffect(() => {
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('bookings-operations-report-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        incotermsService.getIncoterms().then(data => setIncoterms(data));
        setLoading(true);
        getBookingsOperations()
        setFilterColumns(columns.filter(c => c.filter))
    })

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getBookingsOperations()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getBookingsOperations = (exportToExcel) => {
        let filterParams = ''
        const columnsStorage = JSON.parse(window.localStorage.getItem('bookings-operations-report-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage.sort((a, b) => a.order - b.order))
        } else {
            setSelectedColumns(columns.sort((a, b) => a.order - b.order))
            window.localStorage.setItem('bookings-operations-report-datatable-columns', JSON.stringify(columns))
        }
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('bookings-operations-report-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('bookings-operations-report-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('bookings-operations-report-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('bookings-operations-report-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('bookings-operations-report-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        if (exportToExcel) {
            exportBookingsOperationsToExcel(filterParams, sortFields)
        } else {
            getBookingsOperationsWithParams(filterParams, sortFields)
        }
    }

    const exportBookingsOperationsToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        bookingsService.exportOperationsToExcel('BookingOperations', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'BookingsOperations' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getBookingsOperationsWithParams = (filterParams, sortFields) => {
        bookingsService.getBookingsOperationsReport(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.data.totalElements);
                setBookingsOperationsList(data.data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
        bookingsService.getBookingsOperationsReportSummary(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(data => {
                setOperationsCount(data.data.operationsCount);
                setBookingsCount(data.data.bookingsCount);
            });
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('bookings-operations-report-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns.sort((a, b) => a.order - b.order));
        window.localStorage.setItem('bookings-operations-report-datatable-columns', JSON.stringify(selectedColumns))
    }

    const header = <>
        <div className="table-header">
            <h5 className="p-mb-2">Bookings operations</h5>
        </div>
        <div className="flex justify-content-between flex-wrap card-container">
            <div className="flex align-items-center justify-content-center">
                <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                             optionDisabled={option => option.disabling}
                             placeholder="Select Item" id={'bookings-operations-report-selectColumns'} filter
                             optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                              onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                              offIcon="pi pi-filter" aria-label="Filters"/>
                <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchBooking(searchBooking + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
            </div>
            <div>
                <div>Operations: {operationsCount}</div>
                <div>Bookings: {bookingsCount}</div>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                        onClick={() => getBookingsOperations(true)}/>
            </div>
        </div>
        <div>
            <BookingFilters loading={loading} selectedColumns={filterColumns} filter={filter}
                            searchBooking={searchBooking} clearFilter={clearFilter} typeLoad={'operations'}
                            getBookings={getBookingsOperations} filters={filters} setFilters={setFilters}/>
        </div>
    </>;

    const statusBodyTemplate = (status) => {
        return status.name
    }

    const userBodyTemplate = (user) => {
        return user.firstName + ' ' + user.lastName
    }

    const dateBodyTemplate = (date) => {
        return moment(date).format("YYYY-MM-DD hh:mm")
    }

    const incotermsTemplate = (incotermId) => {
      return incoterms.find(i => i.id === incotermId).title
    }

    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const refIdTemplate = (bookingId) => {
        return <Button label={bookingId} key={'booking' + bookingId} className="p-button-link p-0 p-m-0"
                       style={{display: 'flex'}}
                       onClick={() => goToBooking(bookingId)}/>
    }

    const getBodyTemplate = (key, rowData, field) => {
        switch (key) {
            case 'booking.id':
                return !isNullOrUndef(rowData.booking) ? refIdTemplate(rowData.booking.id) : ''
            case 'incotermId':
                return !isNullOrUndef(rowData.booking.incotermId) ? incotermsTemplate(rowData.booking.incotermId) : ''
            case 'startBookingStatus':
            case 'endBookingStatus':
                return !isNullOrUndef(rowData[field]) ? statusBodyTemplate(rowData[field]) : ''
            case 'booking.operationalStatus':
                return !isNullOrUndef(rowData.booking.operationalStatus) ? rowData.booking.operationalStatus : ''
            case 'operator':
            case 'nextOperator':
                return !isNullOrUndef(rowData[field]) ? userBodyTemplate(rowData[field]) : ''
            case 'operationStartedAt':
            case 'operationEndedAt':
                return !isNullOrUndef(rowData[field]) ? dateBodyTemplate(rowData[field]) : ''
            default:
                return !isNullOrUndef(rowData[field]) ? rowData[field] : ''
        }
    }

    const columnComponents = selectedColumns.map(col => {
        return <Column style={{minWidth: col.width, width: col.width}} key={col.key} field={col.field} sortable={false}
                       header={<LazySortMultiFields header={col.header} fieldKey={col.key}
                                                    field={col.field} lazyParams={lazyParams}
                                                    crud={'bookings-operations-report'}
                                                    setLazyParams={setLazyParams}/>}
                       body={rowData => getBodyTemplate(col.key, rowData, col.field)}/>;
    });

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                    <DataTable ref={dt} value={bookingsOperationsList}
                               size="small"
                               dataKey="id" paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                               onPage={(e) => setLazyParams(e)}
                               totalRecords={totalRecords} lazy
                               stateStorage="local" scrollable
                               responsiveLayout="scroll"
                               scrollHeight="flex" id={'bookings-operations-report-list'}
                               stateKey="bookings-operations-report-dt-state-local"
                               className="datatable-responsive" loading={loading} first={lazyParams.first} rowHover
                               rows={lazyParams.rows}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} bookings"
                               emptyMessage="No bookings found." header={header}>
                        {columnComponents}
                    </DataTable>
                </div>
            </div>
        </div>
    );

}