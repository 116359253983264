import React, {useEffect, useState} from "react";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {useMountEffect} from "primereact/hooks";
import {ClientService} from "../service/ClientService";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import {CSSTransition} from 'react-transition-group'
import {Calendar} from "primereact/calendar";

export const ClientsFilters = (props) => {

    const [filterList, setFilterList] = useState([]);
    const [clientTypesOptions, setClientTypesOptions] = useState([]);
    const [managersOptions, setManagersOptions] = useState([]);
    const [filteredClients, setFilteredClients] = useState(null);
    const [clientGroupsOptions, setClientGroupsOptions] = useState([]);
    const [booleanOptions, setBooleanOptions] = useState([]);

    const clientService = new ClientService();

    useMountEffect(() => {
        clientService.getClientType().then(data => {
            data.unshift({id: -1, name: '-----'})
            setClientTypesOptions(data)
        })
        setBooleanOptions([
            {value: true, title: 'Yes'},
            {value: false, title: 'No'}
        ])

        const filterListStorage = JSON.parse(window.localStorage.getItem('clients-datatable-filters'));
        if(!isNullOrUndef(filterListStorage)){
            setFilterList(filterListStorage)
        }
    })

    useEffect(() => {
        if(!isNullOrUndef(props.managers) && props.managers.length > 0) {
            let managers = [...props.managers]
            managers.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
            managers.unshift({id: -1, name: '-----'})
            setManagersOptions(managers)
        }
    }, [props.managers])

    useEffect(() => {
        if(!isNullOrUndef(props.clientGroups) && props.clientGroups.length > 0) {
            let clientsGroups = [...props.clientGroups]
            clientsGroups.unshift({id: -1, title: '-----'})
            setClientGroupsOptions(clientsGroups)
        }
    }, [props.clientGroups])

    useEffect(() => {
        if(props.searchClients > 0){
            searchClients()
        }
    }, [props.searchClients])

    useEffect(() => {
        if(props.clearFilter > 0){
            localStorage.removeItem('clients-filter-data');
            localStorage.removeItem('clients-datatable-filters');
            clearFilter()
        }
    }, [props.clearFilter])

    const searchClients = () => {
        let filterParam = ''
        let action = 0;
        window.localStorage.setItem('clients-datatable-filters', JSON.stringify(filterList))
        Object.keys(filterList).map(key => {
            if (!isNullOrUndef(filterList[key]) && filterList[key] !== '') {
                action = action + 1
                switch (key) {
                    case 'title':
                        return filterParam += typeof filterList[key] === 'object' ? ',id:' + filterList[key].id : ',title:' + filterList[key]
                    case 'clientGroups':
                        return filterParam += ',clientGroups.id:' + filterList[key]
                    case 'registrationCode':
                        return filterParam += ',registrationCode:' + filterList[key]
                    case 'vatCode':
                        return filterParam += ',vatCode:' + filterList[key]
                    case 'country.title':
                        return filterParam += ',country.title:' + filterList[key]
                    case 'city':
                        return filterParam += ',city:' + filterList[key]
                    case 'address':
                        return filterParam += ',address:' + filterList[key]
                    case 'postalCode':
                        return filterParam += ',postalCode:' + filterList[key]
                    case 'email':
                        return filterParam += ',email:' + filterList[key]
                    case 'phone':
                        return filterParam += ',phone:' + filterList[key]
                    case 'user':
                        return filterParam += ',users.id:' + filterList[key]
                    case 'costsManager':
                        return filterParam += ',costsManager.id:' + filterList[key]
                    case 'secondManager':
                        return filterParam += ',secondManager.id:' + filterList[key]
                    case 'physicalPerson':
                        return filterParam += ',physicalPerson:' + filterList[key]
                    case 'clientType.title':
                        return filterParam += ',clientType.id:' + filterList[key]
                    case 'comment':
                        return filterParam += ',comment:' + filterList[key]
                }
            } else if(filterList.hasOwnProperty(key)){
                delete filterList[key]
            }
        });
        if(action === 0){
            filterParam = null
        }
        localStorage.removeItem('clients-filter-data');
        props.setFilters(filterParam)
    }

    const makeCalendarParams = (dates, key) => {
        if (!isNullOrUndef(dates[0]) && !isNullOrUndef(dates[1])) {
            return `,${key}>${moment(dates[0]).format('YYYY-MM-DD')},${key}<${moment(dates[1]).format('YYYY-MM-DD')}`
        } else if (!isNullOrUndef(dates[0])) {
            return `,${key}:${moment(dates[0]).format('YYYY-MM-DD')}`
        } else {
            return ''
        }

    }

    const loadFilters = () => {
        let filters = props.selectedColumns.map(c => {
            switch (c.key) {
                case 'registrationCode':
                case 'vatCode':
                case 'country.title':
                case 'city':
                case 'address':
                case 'postalCode':
                case 'email':
                case 'phone':
                case 'comment':
                    return inputFilter(c);
                case 'clientType.title':
                    return dropDownFieldFilter(c, clientTypesOptions, 'title');
                case 'user':
                case 'costsManager':
                case 'secondManager':
                    return dropDownFieldFilter(c, managersOptions, 'name');
                case 'clientGroups':
                    return dropDownFieldFilter(c, clientGroupsOptions, 'title');
                case 'physicalPerson':
                    return booleanFieldFilter(c, 'title');
                case 'title':
                    return autocompleteFieldFilter(c, filteredClients, filterClient, null, 'title');
                default:
                    return ''
            }
        })

        return <div className={'p-fluid p-grid p-col-12 p-p-0'}>
            {filters}
        </div>
    }

    const filterClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClients(event.query.trim().toLowerCase()).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const clearFilter = () => {
        setFilterList([])
        if(props.filters !== '') {
            props.setFilters('')
        } else {
            props.getClients()
        }
    }

    const inputFilter = (column) => {
        return <div key={column.key} className="p-field p-col-12 p-md-1 p-mb-0">
            <span className="p-float-label">
            <InputText id={`filter-${column.key}`}
                       value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                       className={'block'}
                       onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const onChangeDropDownValue = (e, columnKey) => {
        if ((!isNullOrUndef(e.value) && e.value > 0) || typeof e.value === 'boolean') {
            setFilterList({...filterList, [columnKey]: e.value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const onChangeBooleanValue = (value, columnKey) => {
        if (!isNullOrUndef(value) && value) {
            setFilterList({...filterList, [columnKey]: value})
        } else {
            setFilterList({...filterList, [columnKey]: null})
        }
    }

    const booleanFieldFilter = (column, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-1 p-mb-0">
            <span className="p-float-label">
            <Dropdown id={`filter-${column.key}`} options={booleanOptions}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                      onChange={(e) => onChangeDropDownValue(e, column.key)}
                      optionLabel={field} optionValue={'value'} dropdownIcon={null}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    const dropDownFieldFilter = (column, options, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-1 p-mb-0">
            <span className="p-float-label">
            <Dropdown id={`filter-${column.key}`} options={options}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                      onChange={(e) => onChangeDropDownValue(e, column.key)}
                      optionLabel={field} optionValue={'id'} dropdownIcon={null}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }
    const autocompleteFieldFilter = (column, options, searchObj, template, field) => {
        return <div key={column.key} className="p-field p-col-12 p-md-1 p-mb-0">
            <span className="p-float-label">
            <AutoComplete id={`filter-${column.key}`}
                          value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : ''}
                          suggestions={options} completeMethod={searchObj} minLength={3} showEmptyMessage
                          tooltip={'Min 3 characters'} itemTemplate={template} field={field}
                          onChange={(e) => setFilterList({...filterList, [column.key]: e.target.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }
    const booleanCheckBoxFieldFilter = (column, options) => {
        return <div key={column.key} className="p-field p-col-12 p-md-1 p-mb-0">
            <Checkbox inputId={`filter-${column.key}`}
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : false}
                      checked={filterList[column.key]} style={{marginBottom: '7px'}}
                      onChange={(e) => onChangeBooleanValue(e.checked, column.key)}/>
            <label style={{marginLeft: '5px', marginTop: '10px'}}
                   htmlFor={`filter-${column.key}`}>{column.header}</label>
        </div>
    }

    const calendarFieldFilter = (column, options) => {
        return <div key={column.key} className="p-field p-col-12 p-md-2 p-mb-0">
            <span className="p-float-label">
            <Calendar id={`filter-${column.key}`} selectionMode="range"
                      numberOfMonths={3} dateFormat={'yy-mm-dd'} locale={'lt'} showButtonBar
                      value={filterList.hasOwnProperty(column.key) ? filterList[column.key] : null}
                      onChange={(e) => setFilterList({...filterList, [column.key]: e.value})}/>
            <label htmlFor={`filter-${column.key}`}>{column.header}</label>
            </span>
        </div>
    }

    return <CSSTransition in={props.filter} timeout={{enter: 400, exit: 400}}
                          classNames="filters" unmountOnExit appear>
        <div id={'client-filter'} className={'mt-2'}>
            {props.selectedColumns.length > 0 ? loadFilters() : ''}
        </div>
    </CSSTransition>
}