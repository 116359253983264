import React, {useState, useRef, useEffect, Fragment} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InvoicesService} from "../../../service/InvoicesService";
import {isNullOrUndef} from "chart.js/helpers";
import {useHistory} from "react-router-dom";
import {useMountEffect} from "primereact/hooks";
import {ToggleButton} from "primereact/togglebutton";
import {InvoicesFilters} from "../InvoicesFilters";
import {ExportFileService} from "../../../service/ExportFileService";
import moment from "moment";
import {LazySortMultiFields} from "../../../components/LazySortMultiFields";
import {MultiSelect} from "primereact/multiselect";

export const IncomeDebitList = (props) => {

    let emptyIncomeDebit = {
        id: null,
        name: ''
    };

    const [invoicesDebit, setInvoicesDebit] = useState(null);
    const [deleteIncomeDebitDialog, setDeleteIncomeDebitDialog] = useState(false);
    const [invoiceDebit, setIncomeDebit] = useState(emptyIncomeDebit);
    const [selectedIncomeDebit, setSelectedIncomeDebit] = useState(null);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchInvoices, setSearchInvoices] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const invoicesService = new InvoicesService();
    const history = useHistory();
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;
    const columns = [
        {key: 'number', header: 'Invoice number', field: 'number', width: 'auto', filter: true},
        {key: 'bookings', header: 'Ref.ID', field: 'bookings.id', width: 'auto', filter: true},
        {key: 'client', header: 'Customer', field: 'client.title', width: 'auto', filter: true},
        {key: 'currencyCode', header: 'Currency', field: 'currency.code', width: 'auto', filter: false},
        {key: 'issueDate', header: 'Issue date', field: 'issueDate', width: '90px', filter: true},
        {key: 'dueDate', header: 'Due date', field: 'dueDate', width: '90px', filter: true},
        {key: 'issuedBy', header: 'Manager', field: 'issuedBy', width: 'auto', filter: true},
  ]

    useMountEffect(() => {
        const columnsStorage = JSON.parse(window.localStorage.getItem('debit-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage)
        } else {
            setSelectedColumns(columns)
            window.localStorage.setItem('debit-datatable-columns', JSON.stringify(columns))
        }
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('debit-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('debit-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        setLoading(true);
        getInvoices()
        setFilterColumns(columns.filter(c => c.filter))
    });

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getInvoices()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getInvoices = (exportToExcel) => {
        let filterParams = null
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('debit-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('debit-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('debit-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('debit-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        if(exportToExcel){
            exportDebitInvoiceToExcel(filterParams, sortFields)
        } else {
            getInvoicesWithParams(filterParams, sortFields)
        }
    }

    const exportDebitInvoiceToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        invoicesService.exportToExcel('', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'DebitInvoices_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getInvoicesWithParams = (filterParams, sortFields) => {
        invoicesService.getInvoices(lazyParams.page, lazyParams.rows, 'DEBIT', sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.totalElements);
                setInvoicesDebit(data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
    }


    const hideDeleteIncomeDebitDialog = () => {
        setDeleteIncomeDebitDialog(false);
    }

    const editIncomeDebit = (invoiceDebitId) => {
        history.push(`/invoices/debit/view/${invoiceDebitId}`);
    }

    // const confirmDeleteIncomeDebit = (invoiceDebit) => {
    //     setIncomeDebit(invoiceDebit);
    //     setDeleteIncomeDebitDialog(true);
    // }

    const deleteIncomeDebit = () => {
        invoicesService.deleteIncomeDebit(invoiceDebit.id)
            .then(data => {
                let _invoicesDebit = invoicesDebit.filter(val => val.id !== invoiceDebit.id);
                setInvoicesDebit(_invoicesDebit);
                setDeleteIncomeDebitDialog(false);
                setIncomeDebit(emptyIncomeDebit);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'IncomeDebit Deleted',
                    life: 3000
                });
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const downloadInvoice = (invoiceData) => {
        setLoading(true)
        invoicesService.getInvoicePdf(invoiceData.id).then(r => {
            setLoading(false)
            if (r.status === 200) {
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a')
                link.href = url;
                link.setAttribute('download', invoiceData.number + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(error => {
            setLoading(false)
            toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
        });
    }

    const changeDateValidate = (invoiceId, validate) => {
        invoicesService.changeDateValidate(invoiceId, validate)
            .then(r => {
                if(r.success === true){
                    let _invoicesDebit = [...invoicesDebit]
                    let idx = _invoicesDebit.findIndex(val => val.id === invoiceId);
                    _invoicesDebit[idx].overrideEditPermission = validate;
                    setInvoicesDebit(_invoicesDebit);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Dates validation is changed',
                        life: 3000
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Cannot change. Please contact with administrator',
                        life: 3000
                    });
                }
            })
    }

    const actionBodyTemplate = (rowData) => {
        let dateIcon = rowData.overrideEditPermission === true ? 'pi pi-calendar-plus' : 'pi pi-calendar-minus';
        let iconColor = rowData.overrideEditPermission === true ? 'red' : 'green';
        let iconTitle = rowData.overrideEditPermission === true ? 'Dates validation OFF' : 'Dates validation ON';
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'}  onClick={() => editIncomeDebit(rowData.id)} />
                <Button icon="pi pi-download" className="p-button-rounded p-button-text p-button-sm"
                        title={'Download'} onClick={() => downloadInvoice(rowData)}/>
                {props.checkUserRole('ROLE_ADMIN') ?
                <Button icon={dateIcon} className="p-button-rounded p-button-text p-button-sm"
                        style={{color: iconColor}}
                        title={iconTitle} onClick={() => changeDateValidate(rowData.id, !rowData.overrideEditPermission)}/>
                    : ''}
            </div>
        );
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('debit-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns);
        window.localStorage.setItem('debit-datatable-columns', JSON.stringify(selectedColumns))
    }

    const header = () => {
        let items = [
            // {
            //     label: 'Export XLS',
            //     style: {textAlign: 'center'},
            //     icon: 'pi pi-file-excel',
            //     command: (e) => exportFileService.exportExcel(invoicesDebit, 'Invoice', toast)
            // }
        ]
        return <Fragment>
            <div className="table-header">
                <h5 className="p-mb-2">Invoices (Debit)</h5>
            </div>
            <div className="flex justify-content-between flex-wrap card-container">
                <div className="flex align-items-center justify-content-center">
                    <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                                 placeholder="Select Item" id={'debit-selectColumns'} filter
                                 optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                    <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                                  onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                                  offIcon="pi pi-filter" aria-label="Filters"/>
                    <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchInvoices(searchInvoices + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
                </div>
                <div className="flex align-items-center justify-content-center">
                    {/*<Button icon="pi pi-plus" iconPos="right" label={'New'} className="p-button-success mr-2"*/}
                    {/*        onClick={() => editIncomeDebit('new')}/>*/}
                    <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                            onClick={() => getInvoices(true)}/>
                </div>
            </div>
            <div>
                <InvoicesFilters loading={loading} filterColumns={filterColumns} filter={filter}
                                 clientGroupId={props.getClientGroupId('customer')}
                                 searchInvoices={searchInvoices} clearFilter={clearFilter} filterList={'debit'}
                                 getInvoices={getInvoices} filters={filters} setFilters={setFilters}/>
            </div>
        </Fragment>
    }


    const deleteIncomeDebitDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIncomeDebitDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIncomeDebit}/>
        </>
    );

    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const bookingsTemplate = (rowData) => {
        if (rowData.bookings.length > 0) {
            return <div className={'col-12 p-0'} key={'key'}>
                {rowData.bookings.map(b => {
                    return <Button label={b.id} key={`button_bookingId_${b.id}`} className="p-button-link p-0 p-m-0 col-4"
                                   style={{textAlign: "left"}}
                                   onClick={() => goToBooking(rowData.bookings[0].id)}/>
                })}
            </div>

        }
    }

    const dueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.dueDate))
            return

        return moment(rowData.dueDate).format("YYYY-MM-DD")
    }

    const issueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.issueDate))
            return

        return moment(rowData.issueDate).format("YYYY-MM-DD")
    }

    const issuedByTemplate = (rowData) => {
        return rowData.issuedBy.firstName + ' ' + rowData.issuedBy.lastName
    }

    const getBodyTemplate = (key, rowData) => {
        switch (key) {
            case 'number':
                return !isNullOrUndef(rowData.number) ? rowData[key] : ''
            case 'bookings':
                return !isNullOrUndef(rowData.bookings) ? bookingsTemplate(rowData) : ''
            case 'client':
                return !isNullOrUndef(rowData.client) ? rowData.client.title : ''
            case 'currencyCode':
                return !isNullOrUndef(rowData.currency) ? rowData.currency.code : ''
            case 'issueDate':
                return !isNullOrUndef(rowData.issueDate) ? issueDateTemplate(rowData) : ''
            case 'dueDate':
                return !isNullOrUndef(rowData.dueDate) ? dueDateTemplate(rowData) : ''
            case 'issuedBy':
                return !isNullOrUndef(rowData.issuedBy) ? issuedByTemplate(rowData) : ''
            default:
                return !isNullOrUndef(rowData[key]) ? rowData[key] : ''
        }
    }

    const columnComponents = () => {
        return selectedColumns.map(col => {
            return <Column style={{minWidth: col.width}} key={col.key} field={col.field} sortable={false}
                           header={<LazySortMultiFields header={col.header} fieldKey={col.key}
                                                        field={col.field} lazyParams={lazyParams}
                                                        crud={'debit'}
                                                        setLazyParams={setLazyParams}/>}
                           body={rowData => getBodyTemplate(col.key, rowData)}/>;
        });
    }

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                    <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                    <DataTable ref={dt} value={invoicesDebit} selection={selectedIncomeDebit}
                               onSelectionChange={(e) => setSelectedIncomeDebit(e.value)}
                               dataKey="id"
                               paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                               onPage={(e) => setLazyParams(e)}
                               totalRecords={totalRecords} lazy
                               rows={lazyParams.rows} size="small"
                               id={'debit-invoices-list'}
                               scrollable
                               scrollHeight="flex"
                               stateStorage="local" stateKey="debit-dt-state-local"
                               className="datatable-responsive" loading={loading}
                               first={lazyParams.first} rowHover
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                               emptyMessage="No Invoices found." header={header}>
                        {columnComponents()}
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                </div>
                    <Dialog visible={deleteIncomeDebitDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteIncomeDebitDialogFooter} onHide={hideDeleteIncomeDebitDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {invoiceDebit && <span>Are you sure you want to delete <b>{invoiceDebit.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
        </div>
    );
}
