import axios from 'axios'
import authHeader from "./auth-header";

export class TradeService {

    getTrades(tradeId, tradeTypeId) {
        return axios.get(`/api/trade/${tradeId}/?tradeType=${tradeTypeId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    saveTrade(trade) {
        return axios.post('/api/trade', trade, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteTrade(tradeId) {
        return axios.delete(`/api/trade/${tradeId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}