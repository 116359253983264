import axios from 'axios'
import authHeader from "./auth-header";

export class ClientService {

    getClientContactPersons(clientId) {
        return axios.get(`/api/contact-person/client/${clientId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    setClientContactPerson(clientContactPerson){
        return axios.post('/api/contact-person', clientContactPerson, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteClientContactPerson(clientId){
        return axios.delete(`/api/contact-person/${clientId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getClients(page, size, sortFields, filterParams) {
        return axios.get(`/api/client?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res.data);
    }

    searchClients(title) {
        return axios.get(`/api/client/search?title=${title}`, { headers: authHeader() })
            .then(res => res.data);
    }

    searchClientsByGroupId(title, clientGroupIds) {
        return axios.get(`/api/client/search?title=${title}&clientGroupId=${clientGroupIds}`, { headers: authHeader() })
            .then(res => res.data);
    }

    saveClient(client) {
        return axios.post('/api/client', client, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteClient(clientId) {
        return axios.delete(`/api/client/${clientId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getClientTypeRegister(clientId) {
        return axios.get(`/api/client/type-register/${clientId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getClientDTO(clientId) {
        return axios.get(`/api/client/${clientId}?dto=false`, { headers: authHeader() })
            .then(res => res.data);
    }

    getClientType() {
        return axios.get(`/api/client-type/`, { headers: authHeader() })
            .then(res => res.data);
    }

    updateBookings(clientId) {
        return axios.post(`/api/client/${clientId}/update-bookings`, null, { headers: authHeader() })
            .then(res => res.data);
    }
}