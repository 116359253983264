import {InputText} from "primereact/inputtext";
import {isNullOrUndef} from "chart.js/helpers";
import classNames from "classnames";
import React from "react";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";


export const getTitleField = (client, submitted, onInputChange) => {
    return <div className="p-field col-12 md:col-3">
        <label htmlFor="title">Title</label>
        <InputText id="title" value={!isNullOrUndef(client.title) ? client.title : ''}
                   onChange={(e) => onInputChange(e, 'title')}
                   required autoFocus
                   className={classNames({'p-invalid': submitted && (!client.title || client.title.trim().length === 0)})}/>
        {submitted && (!client.title || client.title.trim().length === 0) &&
            <small className="p-invalid">Title is required.</small>}
    </div>
}

export const getClientGroupsField = (client, submitted, clientGroups, onValueChange) => {
    return <div className="p-field col-12 md:col-3">
        <label htmlFor="clientGroups">Account Group</label>
        <MultiSelect id="clientGroups" value={client.clientGroups}
                     onChange={(e) => onValueChange(e, 'clientGroups')}
                     options={clientGroups} required
                     optionLabel="title" placeholder="-- Select --"
                     appendTo={document.body}
                     filter filterBy="title"
                     className={classNames({'p-invalid': submitted && client.clientGroups.length === 0})}/>
        {submitted && client.clientGroups.length === 0 &&
            <small className="p-invalid">Client groups is required.</small>}
    </div>
}

export const getPhysicalPersonField = (client, submitted, clientGroups, onValueChange) => {
    if(client.clientGroups.length > 0 && client.clientGroups.some(group => group.title === 'Customer' || group.title === 'Vendor'))
    return <div className="p-field col-12 md:col-3">
        <label htmlFor="physicalPerson">Physical Person</label>
        <Dropdown id="physicalPerson" value={client.physicalPerson}
                  onChange={(e) => onValueChange(e, 'physicalPerson')}
                  options={[{title: 'Yes', value: true},{title: 'No', value: false}]}
                  optionLabel="title" placeholder="-- Select --"
                  appendTo={document.body} required filter filterBy="title"
                  className={classNames({'p-invalid': submitted && isNullOrUndef(client.physicalPerson)})}/>
        {submitted && isNullOrUndef(client.physicalPerson) &&
            <small className="p-invalid">Physical Person is required.</small>}
    </div>
}

export const getRegistrationCodeField = (client, submitted, required, onInputChange, checkRequiredField) => {
    if(!client.physicalPerson) {
        let notValid = required && submitted && checkRequiredField('text', client.registrationCode);
        return <div key={'registrationCodeField'} className="p-field col-12 md:col-3">
            <label htmlFor="registrationCode">Registration code</label>
            <InputText id="registrationCode"
                       required={!client.physicalPerson ? required : false}
                       value={!isNullOrUndef(client.registrationCode) ? client.registrationCode : ''}
                       onChange={(e) => onInputChange(e, 'registrationCode')}
                       className={classNames({'p-invalid': notValid})}/>
            {notValid && <small className="p-invalid">Registration code is required.</small>}
        </div>
    }
}

export const getVatCodeField = (client, submitted, required, onInputChange, checkRequiredField) => {
    if(!client.physicalPerson) {
        let notValid = required && submitted && checkRequiredField('text', client.vatCode);
        return <div key={'vatCodeField'} className="p-field col-12 md:col-3">
            <label htmlFor="vatCode">VAT code</label>
            <InputText id="vatCode"
                       value={!isNullOrUndef(client.vatCode) ? client.vatCode : ''}
                       required={!client.physicalPerson ? required : false}
                       onChange={(e) => onInputChange(e, 'vatCode')}
                       className={classNames({'p-invalid': notValid})}/>
            {notValid && <small className="p-invalid">VAT code is required.</small>}
        </div>
    }
}

export const getPhoneField = (client, submitted, required, onInputChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.phone);
    return <div key={'phoneField'} className="p-field col-12 md:col-3">
        <label htmlFor="phone">Phone</label>
        <InputText id="phone" value={!isNullOrUndef(client.phone) ? client.phone : ''} required={required}
                   onChange={(e) => onInputChange(e, 'phone')}
                   className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Phone is required.</small>}
    </div>
}

export const getEmailField = (client, submitted, required, onInputChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.email);
    return <div key={'emailField'} className="p-field col-12 md:col-3">
        <label htmlFor="email">Email</label>
        <InputText id="email" value={!isNullOrUndef(client.email) ? client.email : ''} required={required}
                   onChange={(e) => onInputChange(e, 'email')}
                   className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Email is required.</small>}

    </div>
}

export const getCountryField = (client, submitted, required, countries, onValueChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('array', client.country);
    return <div key={'countryField'} className="p-field col-12 md:col-3">
        <label htmlFor="country">Country</label>
        <Dropdown id="country" value={client.country}
                  onChange={(e) => onValueChange(e, 'country')}
                  options={countries}
                  optionLabel="title" placeholder="-- Select --"
                  appendTo={document.body}
                  className={classNames({'p-invalid': notValid})}
                  required={required}
                  filter filterBy="title"/>
        {notValid && <small className="p-invalid">Country is required.</small>}
    </div>
}

export const getCityField = (client, submitted, required, onInputChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.city);
    return <div key={'cityField'} className="p-field col-12 md:col-3">
        <label htmlFor="city">City</label>
        <InputText id="city" value={!isNullOrUndef(client.city) ? client.city : ''} required={required}
                   onChange={(e) => onInputChange(e, 'city')}
                   className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">City is required.</small>}
    </div>
}

export const getAddressField = (client, submitted, required, onInputChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.address);
    return <div key={'addressField'} className="p-field col-12 md:col-3">
        <label htmlFor="address">Address</label>
        <InputText id="address"
                   value={!isNullOrUndef(client.address) ? client.address : ''}
                   onChange={(e) => onInputChange(e, 'address')} required={required}
                   className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Address is required.</small>}
    </div>
}

export const getPostalCodeField = (client, submitted, required, onInputChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.postalCode);
    return <div key={'postalCodeField'} className="p-field col-12 md:col-3">
        <label htmlFor="postalCode">Postal code</label>
        <InputText id="postalCode"
                   value={!isNullOrUndef(client.postalCode) ? client.postalCode : ''} required={required}
                   onChange={(e) => onInputChange(e, 'postalCode')}
                   className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Postal code is required.</small>}
    </div>
}