import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {CargoItemsService} from '../service/CargoItemsService';
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {Dropdown} from "primereact/dropdown";
import {FilterMatchMode} from "primereact/api";

export const CargoItems = () => {

    let emptyCargoItem = {
        id: null,
        title: '',
    };

    const [cargoItems, setCargoItems] = useState(null);
    const [cargoItemDialog, setCargoItemDialog] = useState(false);
    const [deleteCargoItemDialog, setDeleteCargoItemDialog] = useState(false);
    const [cargoItem, setCargoItem] = useState(emptyCargoItem);
    const [selectedCargoItem, setSelectedCargoItem] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const cargoItemsService = new CargoItemsService();
    const filters = {
        'title': { value: null, matchMode: FilterMatchMode.CONTAINS }
    }

    useMountEffect(() => {
        cargoItemsService.getCargoItems()
            .then(data => {
                setCargoItems(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setCargoItem(emptyCargoItem);
        setSubmitted(false);
        setCargoItemDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCargoItemDialog(false);
    }

    const hideDeleteCargoDialog = () => {
        setDeleteCargoItemDialog(false);
    }

    const saveCargo = () => {
        setSubmitted(true);

        if (cargoItem.title.trim()) {
            let _cargoItems = [...cargoItems];
            let _cargoItem = {...cargoItem};
            if(_cargoItem.hasOwnProperty('bookings')){
                delete _cargoItem.bookings
            }
            cargoItemsService.saveCargoItem(_cargoItem)
                .then(data => {
                    if (cargoItem.id) {
                        const index = findIndexById(cargoItem.id);
                        _cargoItems[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Cargo Item Updated',
                            life: 3000
                        });
                    } else {
                        _cargoItems.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Cargo Item Created',
                            life: 3000
                        });
                    }
                    setCargoItems(_cargoItems);
                    setCargoItemDialog(false);
                    setCargoItem(emptyCargoItem);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editCargo = (cargoItem) => {
        setCargoItem({ ...cargoItem });
        setCargoItemDialog(true);
    }

    const confirmDeleteCargo = (cargoItem) => {
        setCargoItem(cargoItem);
        setDeleteCargoItemDialog(true);
    }

    const deleteCargo = () => {
        cargoItemsService.deleteCargoItem(cargoItem.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteCargoItemDialog(false);
                    setCargoItem(emptyCargoItem);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _cargoItems = cargoItems.filter(val => val.id !== cargoItem.id);
                    setCargoItems(_cargoItems);
                    setDeleteCargoItemDialog(false);
                    setCargoItem(emptyCargoItem);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Cargo Item Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < cargoItems.length; i++) {
            if (cargoItems[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cargoItem = { ...cargoItem };
        _cargoItem[`${name}`] = val;

        setCargoItem(_cargoItem);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'}  onClick={() => editCargo(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'}  onClick={() => confirmDeleteCargo(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Cargos</h5>
        </div>
    );

    const cargoDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCargo} />
        </>
    );
    const deleteCargoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCargoDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCargo} />
        </>
    );

    const titleFilterElement = (options) => {
        return <Dropdown value={options.value} options={cargoItems} optionValue={'id'}
                         onChange={(e) => options.filterApplyCallback(e.value)}
                         optionLabel="title" placeholder="-- Select --" className="p-column-filter"/>;
    }


    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={cargoItems} selection={selectedCargoItem} onSelectionChange={(e) => setSelectedCargoItem(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cargos"
                               emptyMessage="No Cargos found." header={header} filterDisplay="row" filters={filters}>
                        {/*<Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>*/}
                        <Column field="title" header="Title" sortable filter showFilterMenu={false} body={titleBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={cargoItemDialog} style={{ width: '450px' }} header="Cargo Item Details" modal className="p-fluid" footer={cargoDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={cargoItem.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !cargoItem.title })} />
                            {submitted && !cargoItem.title && <small className="p-invalid">Title is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCargoItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCargoDialogFooter} onHide={hideDeleteCargoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {cargoItem && <span>Are you sure you want to delete <b>{cargoItem.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
