import React, {Fragment, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {CountryDirectionService} from "../service/CountryDirectionService";
import {IncotermsService} from "../service/IncotermsService";
import {TransportModeService} from "../service/TransportModeService";
import {Dropdown} from "primereact/dropdown";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {TradeService} from "../service/TradeService";
import {FilterMatchMode} from "primereact/api";
import {Toast} from "primereact/toast";
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {confirmDialog} from "primereact/confirmdialog";

export const TradeInformation = (props) => {

    let emptyTrade = {
        id: null,
        client: props.client.id,
        manager: null,
        direction: null,
        incoterm: null,
        tradeType: props.tradeType.id,
        transportMode: null,
        amount: 0,
        amountPlan: 0,
        competitor: '',
        note: '',
    };

    const [trade, setTrade] = useState(emptyTrade);
    const [trades, setTrades] = useState(null);
    const [selectedTrade, setSelectedTrade] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [directions, setDirections] = useState([]);
    const [tradeDialog, setTradeDialog] = useState(false);
    const [incoterms, setIncoterms] = useState([]);
    const [transportModes, setTransportModes] = useState([]);
    const [deleteTradeDialog, setDeleteTradeDialog] = useState(false);
    const dt = useRef(null);
    const countryDirectionService = new CountryDirectionService();
    const incotermsService = new IncotermsService();
    const transportModeService = new TransportModeService();
    const tradeService = new TradeService();
    const [globalFilter, setGlobalFilter] = useState('');
    const [filters, setFilters] = useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const globalFilterFields = ['direction.title', 'incoterm.title',
        'transportMode.name', 'amount', 'amountPlan', 'competitor', 'note']

    useMountEffect(() => {
        countryDirectionService.getCountryDirections().then(data => setDirections(data));
        incotermsService.getIncoterms().then(data => setIncoterms(data));
        transportModeService.getTransportModes().then(data => setTransportModes(data));
        tradeService.getTrades(props.client.id, props.tradeType.id).then(data => {
            setTrades(data)
        });
    });

    const openNew = () => {
        setTrade(emptyTrade);
        setSubmitted(false);
        setTradeDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
                {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedClients || !selectedClients.length} />*/}
            </React.Fragment>
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilter(value);
    }

    const header = (
        <Fragment>
            <h5 className="p-m-0 mb-4">Manage {props.tradeType.name}</h5>
            <div className={'flex flex justify-content-between flex-wrap'}>
                <div className="flex align-items-center justify-content-center">
                    {leftToolbarTemplate()}
                </div>
                <div className="flex align-items-center justify-content-center">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"/>
                        <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange}
                                   placeholder="Search..."/>
                    </span>
                </div>
            </div>
        </Fragment>
    );


    const hideTradeDialog = () => {
        setTradeDialog(false)
        setTrade(emptyTrade)
    }

    const onDropDownChange = (e) => {
        let _trade = {...trade};
        _trade[`${e.target.id}`] = e.value;
        setTrade(_trade);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _trade = {...trade};
        _trade[`${name}`] = val;

        setTrade(_trade);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _trade = {...trade};
        _trade[`${name}`] = val;

        setTrade(_trade);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < trades.length; i++) {
            if (trades[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmUpdate = (e) => {
        e.preventDefault()
        confirmDialog({
            trigger: e.currentTarget,
            message: 'Are you sure you want to proceed? This manager will updated with all related bookings !',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => saveTrade(),
            reject: () => hideTradeDialog()
        });
    }

    const checkRequired = (e) => {
        e.preventDefault()
        setSubmitted(true);
        let _submitted = true;
        if (trade.direction === null || trade.incoterm === null || trade.manager === null
            || trade.transportMode === null || trade.amount === null) {
            _submitted = false;
        }

        if (_submitted) {
            // confirmUpdate(e)
            saveTrade()
        }
    }

    const saveTrade = () => {
        setSubmitted(true);

        let _trades = [...trades];
        let tradeDTO = {
            id: trade.id ? trade.id : null,
            clientId: props.client.id,
            manager: {id: trade.manager.id},
            directionId: trade.direction.id,
            incotermId: trade.incoterm.id,
            tradeTypeId: props.tradeType.id,
            transportModeId: trade.transportMode.id,
            date: new Date(),
            amount: trade.amount,
            amountPlan: trade.amountPlan,
            competitor: trade.competitor,
            note: trade.note,
        }
        tradeService.saveTrade(tradeDTO)
            .then(data => {
                if (trade.id) {
                    const index = findIndexById(trade.id);
                    _trades[index] = data;
                    props.toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Trade Updated',
                        life: 3000
                    });
                } else {
                    _trades.push(data);
                    props.toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Trade Created',
                        life: 3000
                    });
                }
                setTrades(_trades);
                setTradeDialog(false);
                setTrade(emptyTrade);
            })
            .catch(error => {
                props.toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const tradeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideTradeDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={checkRequired}/>
        </>
    );

    const editTrade = (trade) => {
        setTrade({...trade});
        setTradeDialog(true);
    }

    const confirmDeleteTrade = (trade) => {
        setTrade(trade);
        setDeleteTradeDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editTrade(rowData)}/>
                <Button title={'Delete'} icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        onClick={() => confirmDeleteTrade(rowData)}/>
            </div>
        );
    }

    const hideDeleteTradeDialog = () => {
        setDeleteTradeDialog(false);
    }

    const deleteTrade = () => {
        tradeService.deleteTrade(trade.id)
            .then(data => {
                if (!isNullOrUndef(data) && !data.success) {
                    setDeleteTradeDialog(false);
                    setTrade(emptyTrade);
                    props.toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: data.errorMessage,
                        life: 3000
                    });
                } else {
                    let _trades = trades.filter(val => val.id !== trade.id);
                    setTrades(_trades);
                    setDeleteTradeDialog(false);
                    setTrade(emptyTrade);
                    props.toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Trade Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                props.toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const deleteTradeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTradeDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTrade}/>
        </>
    );

    const managerTemplate = (rowData) => {
        if (isNullOrUndef(rowData.manager))
            return

        return rowData.manager.firstName + ' ' + rowData.manager.lastName

    }

    return (
        <div className="p-grid">
            <Toast/>
            <div className="p-col-12">
                <div className="card" style={{height: 'calc(100vh - 500px)'}}>
                    <DataTable ref={dt} value={trades} selection={selectedTrade}
                               onSelectionChange={(e) => setSelectedTrade(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                               className={'p-datatable-sm'}
                               scrollHeight={'fex'}
                               responsiveLayout={'scroll'}
                               scrollable={true}
                               filters={filters}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} ${props.tradeType.name}`}
                               globalFilterFields={globalFilterFields}
                               emptyMessage="No trades found." header={header}>
                        <Column field="direction.title" header="Trade" sortable/>
                        <Column field="manager" header="Manager" body={managerTemplate} sortable/>
                        <Column field="incoterm.title" header="Incoterm" sortable/>
                        <Column field="transportMode.name" header="Transport Mode" sortable/>
                        <Column field="amount" header="Qnt" sortable/>
                        <Column field="amountPlan" header="Plan qnt" sortable/>
                        <Column field="competitor" header="Competitor" sortable/>
                        <Column field="note" header="Notes" sortable/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>
                </div>
                <Dialog id={'tradeDialog'} visible={tradeDialog} style={{width: '50vw'}} header="Trade" modal
                        footer={tradeDialogFooter} onHide={hideTradeDialog}>
                    <div className="card">
                        <div className="p-fluid grid">
                            <div className="field col-4">
                                <label htmlFor="direction">Trade</label>
                                <Dropdown id="direction" value={trade.direction} onChange={onDropDownChange}
                                          options={directions}
                                          optionLabel="title" placeholder="-- Select --"
                                          appendTo={document.body}
                                          className={classNames({'p-invalid': submitted && !trade.direction})}
                                          required filter filterBy="title"/>
                                {submitted && !trade.direction &&
                                    <small className="p-invalid">Direction is required.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="tradeManager">Manager</label>
                                <Dropdown id="manager" value={trade.manager}
                                          onChange={onDropDownChange}
                                          options={!isNullOrUndef(props.managers) ? props.managers : []}
                                          multiple={false} placeholder="-- Select --"
                                          appendTo={document.body} optionLabel="firstName"
                                          filter filterBy="firstName" showClear
                                          valueTemplate={props.selectedManagerTemplate}
                                          itemTemplate={props.managerOptionTemplate}
                                          className={classNames({'p-invalid': submitted && !trade.manager})}
                                          required/>
                                {submitted && !trade.manager &&
                                    <small className="p-invalid">Manager is required.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="incoterm">Incoterm</label>
                                <Dropdown id="incoterm" value={trade.incoterm} onChange={onDropDownChange}
                                          options={incoterms}
                                          optionLabel="title" placeholder="-- Select --"
                                          appendTo={document.body}
                                          className={classNames({'p-invalid': submitted && !trade.incoterm})}
                                          required filter filterBy="title"/>
                                {submitted && !trade.incoterm &&
                                    <small className="p-invalid">Incoterm is required.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="transportMode">Transport Mode</label>
                                <Dropdown id="transportMode" value={trade.transportMode}
                                          onChange={onDropDownChange}
                                          options={transportModes}
                                          optionLabel="name" placeholder="-- Select --"
                                          appendTo={document.body}
                                          className={classNames({'p-invalid': submitted && !trade.transportMode})}
                                          required filter filterBy="title"/>
                                {submitted && !trade.transportMode &&
                                    <small className="p-invalid">Transport Mode is required.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="amount">Qnt</label>
                                <InputNumber id="amount" value={trade.amount}
                                             onChange={(e) => onInputNumberChange(e, 'amount')}
                                             required autoFocus
                                             className={classNames({'p-invalid': submitted && !trade.amount})}/>
                                {submitted && !trade.amount &&
                                    <small className="p-invalid">Qnt is required.</small>}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="amountPlan">Plan qnt</label>
                                <InputNumber id="amountPlan" value={trade.amountPlan}
                                             onChange={(e) => onInputNumberChange(e, 'amountPlan')}/>
                            </div>
                            <div className="field col-4">
                                <label htmlFor="competitor">Competitor</label>
                                <InputText id="competitor" value={trade.competitor}
                                           onChange={(e) => onInputChange(e, 'competitor')}/>
                            </div>
                            <div className="field col-4">
                                <label htmlFor="note">Note</label>
                                <InputTextarea id="note" value={trade.note}
                                               onChange={(e) => onInputChange(e, 'note')}/>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={deleteTradeDialog} style={{width: '450px'}} header="Confirm" modal
                        footer={deleteTradeDialogFooter} onHide={hideDeleteTradeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {trade && <span>Are you sure you want to delete <b>{trade.id}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        </div>)
}