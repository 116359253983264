import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {CountryService} from '../service/CountryService';
import {Dropdown} from "primereact/dropdown";
import {CountryDirectionService} from "../service/CountryDirectionService";
import {isNullOrUndef} from "chart.js/helpers";
import {FilterMatchMode} from "primereact/api";

export const Countries = () => {

    let emptyCountry = {
        id: null,
        title: '',
        iso2Code: '',
        iso3Code: '',
        direction: null
    };

    const [countries, setCountries] = useState(null);
    const [countryDialog, setCountryDialog] = useState(false);
    const [deleteCountryDialog, setDeleteCountryDialog] = useState(false);
    const [deleteCountriesDialog, setDeleteCountriesDialog] = useState(false);
    const [country, setCountry] = useState(emptyCountry);
    const [selectedCountries, setSelectedCountries] = useState(null);
    const [countryDirections, setCountryDirections] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const filters = {
        'title': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'iso2Code': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'iso3Code': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'direction': { value: null, matchMode: FilterMatchMode.CONTAINS },
    }

    useEffect(() => {
        const countryService = new CountryService();
        countryService.getCountries()
            .then(data => {
                setCountries(data);
                setLoading(false);
            });
        const countryDirectionsService = new CountryDirectionService();
        countryDirectionsService.getCountryDirections().then(data => setCountryDirections(data));
    }, []);

    const openNew = () => {
        setCountry(emptyCountry);
        setSubmitted(false);
        setCountryDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCountryDialog(false);
    }

    const hideDeleteCountryDialog = () => {
        setDeleteCountryDialog(false);
    }

    const hideDeleteCountriesDialog = () => {
        setDeleteCountriesDialog(false);
    }

    const saveCountry = () => {
        setSubmitted(true);

        if (country.title.trim()) {
            let _countries = [...countries];
            let _country = {...country};

            new CountryService().saveCountry(_country)
                .then(data => {
                    if (country.id) {
                        const index = findIndexById(country.id);
                        _countries[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Updated',
                            life: 3000
                        });
                    } else {
                        _countries.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Created',
                            life: 3000
                        });
                    }
                    setCountries(_countries);
                    setCountryDialog(false);
                    setCountry(emptyCountry);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editCountry = (country) => {
        setCountry({...country});
        setCountryDialog(true);
    }

    const confirmDeleteCountry = (country) => {
        setCountry(country);
        setDeleteCountryDialog(true);
    }

    const deleteCountry = () => {
        new CountryService().deleteCountry(country.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteCountryDialog(false);
                    setCountry(emptyCountry);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _countries = countries.filter(val => val.id !== country.id);
                    setCountries(_countries);
                    setDeleteCountryDialog(false);
                    setCountry(emptyCountry);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Country Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    // const createId = () => {
    //     let id = '';
    //     let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }

    // const confirmDeleteSelected = () => {
    //     setDeleteCountriesDialog(true);
    // }

    const deleteSelectedCountries = () => {
        let _countries = countries.filter(val => !selectedCountries.includes(val));
        setCountries(_countries);
        setDeleteCountriesDialog(false);
        setSelectedCountries(null);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'Countries Deleted', life: 3000});
    }

    const onDirectionChange = (e) => {
        let _country = {...country};
        _country['direction'] = e.value;
        setCountry(_country);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _country = {...country};
        _country[`${name}`] = val;

        setCountry(_country);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const iso2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ISO2</span>
                {rowData.iso2Code}
            </>
        );
    }

    const iso3BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ISO3</span>
                {rowData.iso3Code}
            </>
        );
    }

    const directionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Direction</span>
                {rowData.direction && rowData.direction.title}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editCountry(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteCountry(rowData)}/>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Countries</h5>
        </div>
    );

    const countryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCountry}/>
        </>
    );
    const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCountryDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCountry}/>
        </>
    );
    const deleteCountriesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCountriesDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCountries}/>
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={countries} selection={selectedCountries}
                               onSelectionChange={(e) => setSelectedCountries(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive" loading={loading} filterDisplay="row" filters={filters}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} countries"
                               emptyMessage="No countries found." header={header}>
                        <Column field="title" header="Title" sortable filter body={titleBodyTemplate}/>
                        <Column field="iso2Code" header="ISO2" sortable filter body={iso2BodyTemplate}/>
                        <Column field="iso3Code" header="ISO3" sortable filter body={iso3BodyTemplate}/>
                        <Column field="direction" header="Direction" sortable filter body={directionBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={countryDialog} style={{width: '450px'}} header="Country Details" modal
                            className="p-fluid" footer={countryDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="direction">Direction</label>
                            <Dropdown id="direction" value={country.direction} onChange={onDirectionChange} filter
                                      options={countryDirections} optionLabel="title" placeholder="-- Select --"/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={country.title} onChange={(e) => onInputChange(e, 'title')}
                                       required autoFocus
                                       className={classNames({'p-invalid': submitted && !country.title})}/>
                            {submitted && !country.title && <small className="p-invalid">Title is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="iso2Code">ISO2</label>
                            <InputText id="iso2Code" value={country.iso2Code}
                                       onChange={(e) => onInputChange(e, 'iso2Code')} required autoFocus
                                       className={classNames({'p-invalid': submitted && !country.iso2Code})}/>
                            {submitted && !country.iso2Code && <small className="p-invalid">ISO2 is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="iso3Code">ISO3</label>
                            <InputText id="iso3Code" value={country.iso3Code}
                                       onChange={(e) => onInputChange(e, 'iso3Code')} required autoFocus
                                       className={classNames({'p-invalid': submitted && !country.iso3Code})}/>
                            {submitted && !country.iso3Code && <small className="p-invalid">ISO3 is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCountryDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteCountryDialogFooter} onHide={hideDeleteCountryDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {country && <span>Are you sure you want to delete <b>{country.title}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCountriesDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteCountriesDialogFooter} onHide={hideDeleteCountriesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {country && <span>Are you sure you want to delete the selected countries?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
