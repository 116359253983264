import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {useMountEffect} from "primereact/hooks";
import {ToggleButton} from "primereact/togglebutton";
import {MultiSelect} from "primereact/multiselect";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";
import {BookingsService} from "../../service/BookingsService";
import {BookingFilters} from "../bookings/modules/BookingFilters";

export const PlanRealReport = (props) => {

    const [bookingsList, setBookingsList] = useState([])
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchBooking, setSearchBooking] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const [totalContainers, setTotalContainers] = useState(0);
    const [totalTeus, setTotalTeus] = useState(0);
    const [totalPlan, setTotalPlan] = useState(0);
    const [initialTotalPlan, setInitialTotalPlan] = useState(0);
    const [totalReal, setTotalReal] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const bookingsService = new BookingsService();
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;

    const columns = [
        {key: 'refId', header: 'Ref.ID', field: 'id', width: '60px', order: 1, disabling: true},
        {key: 'bookingDate', header: 'Booking ID', field: 'bookingDate', width: '100px', order: 2, disabling: true},
        {key: 'status', header: 'Status', field: 'status', width: '60px', order: 3, disabling: true},
        {key: 'tradeType', header: 'Imp/Exp', field: 'tradeType', width: '60px', order: 4, disabling: true},
        {key: 'customer', header: 'Customer', field: 'client.title', width: '200px', order: 5, disabling: true},
        {key: 'forwarder', header: 'Forwarder', field: 'forwarder.title', width: '200px', order: 6, disabling: true},
        {key: 'containerType', header: 'Cont. Type', field: 'cargos.containerType.value', width: '90px', order: 7, disabling: true},
        {key: 'cargos', header: 'Containers', field: 'cargos', width: '60px', order: 8, disabling: false},
        {key: 'teus', header: 'Teus', field: 'teus', width: '60px', order: 9, disabling: false},
        {key: 'initialPlan', header: 'Initial Plan', field: 'initialPlan', width: '80px', order: 10, disabling: false},
        {key: 'plan', header: 'Plan', field: 'plan', width: '80px', order: 11, disabling: false},
        {key: 'real', header: 'Real', field: 'real', width: '90px', order: 12, disabling: false},
        {key: 'manager', header: 'Manager', field: 'manager', width: '200px', order: 13, disabling: true},
    ];

    useMountEffect(() => {
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('plan-real-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        setLoading(true);
        getBookings()
        setFilterColumns([
            {key: 'refId', header: 'Ref.ID', field: 'number'},
            {key: 'bookingDate', header: 'Booking ID', field: 'bookingDate'},
            {key: 'status', header: 'Status', field: 'status.name'},
            {key: 'tradeType', header: 'Imp/Exp', field: 'bookings.tradeType'},
            {key: 'customer', header: 'Customer', field: 'client.title'},
            {key: 'forwarder', header: 'Forwarder', field: 'forwarder.title'},
            {key: 'containerType', header: 'Cont. Type', field: 'bookings.containers.type'},
            {key: 'manager', header: 'Manager', field: 'manager.id'},
        ])
    })

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getBookings()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getBookings = (exportToExcel) => {
        let filterParams = ''
        const columnsStorage = JSON.parse(window.localStorage.getItem('plan-real-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage.sort((a, b) => a.order - b.order))
        } else {
            setSelectedColumns(columns.sort((a, b) => a.order - b.order))
            window.localStorage.setItem('plan-real-datatable-columns', JSON.stringify(columns))
        }
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('plan-real-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('plan-real-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('plan-real-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('plan-real-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
             sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('plan-real-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        if (exportToExcel) {
            exportPlanRealToExcel(filterParams, sortFields)
        } else {
            getBookingsWithParams(filterParams, sortFields)
        }
    }

    const exportPlanRealToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        bookingsService.exportToExcel('PlanReal', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'PlanReal_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getBookingsWithParams = (filterParams, sortFields) => {
        bookingsService.getBookings(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.data.totalElements);
                setBookingsList(data.data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
        bookingsService.getPlanRealSummary(filterParams, '')
            .then(data => {
                setTotalContainers(data["containers"])
                setTotalTeus(data["teus"])
                setTotalPlan(data["plan"])
                setTotalReal(data["real"])
                setInitialTotalPlan(data["initialPlan"])
        })
    }

    const bookingDateTemplate = (rowData) => {
        if(isNullOrUndef(rowData.bookingDate))
            return

        return <div
            key={'bookingDate' + rowData.id}>{moment(rowData.bookingDate).format('YYYY-MM-DD')}</div>
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('plan-real-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns.sort((a, b) => a.order - b.order));
        window.localStorage.setItem('plan-real-datatable-columns', JSON.stringify(selectedColumns))
    }

    const header = <>
        <div className="table-header">
            <h5 className="p-mb-2">Plan/Real</h5>
        </div>
        <div className="flex justify-content-between flex-wrap card-container">
            <div className="flex align-items-center justify-content-center">
                <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                             optionDisabled={option => !option.disabling}
                             placeholder="Select Item" id={'payments-selectColumns'} filter
                             optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                              onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                              offIcon="pi pi-filter" aria-label="Filters"/>
                <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchBooking(searchBooking + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                        onClick={() => getBookings(true)}/>
            </div>
        </div>
        <div>
            <BookingFilters loading={loading} selectedColumns={filterColumns} filter={filter}
                            clientGroups={props.getClientGroupId} getClientGroupId={props.getClientGroupId}
                            searchBooking={searchBooking} clearFilter={clearFilter} typeLoad={'planReal'}
                            getBookings={getBookings} filters={filters} setFilters={setFilters}/>
        </div>
    </>;

    const bookingCustomerTemplate = (rowData) => {
        if(isNullOrUndef(rowData.client))
            return

        return rowData.client.title
    }

    const containerTypeTemplate = (rowData) => {
        if(isNullOrUndef(rowData.cargos) || rowData.cargos.length === 0)
            return

        return rowData.cargos.map(c => {
            if(!isNullOrUndef(c.containerType)) {
                return <div key={c.id}>{c.containerType.value}</div>
            }
        })
    }

    const containerTeusTemplate = (rowData) => {
        if(isNullOrUndef(rowData.cargos) || rowData.cargos.length === 0)
            return

        let calcTeus = 0;
        rowData.cargos.map(c => {
            if(!isNullOrUndef(c.containerType)){
                calcTeus += c.containerType.teu
            }
        })

        return calcTeus;
    }

    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const refIdTemplate = (rowData) => {
        return <Button label={rowData.id} key={'booking' + rowData.id} className="p-button-link p-0 p-m-0"
                       style={{display: 'flex'}}
                       onClick={() => goToBooking(rowData.id)}/>
    }
    const managerTemplate = (rowData) => {
        if(isNullOrUndef(rowData.manager))
            return

        return rowData.manager.firstName+' '+rowData.manager.lastName
    }

    const getBodyTemplate = (key, rowData, field) => {
        switch (key) {
            case 'status':
                return !isNullOrUndef(rowData.status) ? rowData.status.name : ''
            case 'tradeType':
                return !isNullOrUndef(rowData[field]) ? rowData[field].name : ''
            case 'forwarder':
                return !isNullOrUndef(rowData.forwarder) ? rowData.forwarder.title : ''
            case 'cargos':
                return !isNullOrUndef(rowData.cargos) ? rowData.cargos.length : 0
            case 'bookingDate':
                return bookingDateTemplate(rowData)
            case 'customer':
                return bookingCustomerTemplate(rowData)
            case 'containerType':
                return containerTypeTemplate(rowData)
            case 'teus':
                return containerTeusTemplate(rowData)
            case 'refId':
                return !isNullOrUndef(rowData[field]) ? refIdTemplate(rowData) : ''
            case 'manager':
                return managerTemplate(rowData)
            default:
                return !isNullOrUndef(rowData[field]) ? rowData[field] : ''
        }
    }

    const getFooterWidthSpace = () => {
        let widthColumns = 0
        selectedColumns.forEach(s => {
            if((s.order > 0 && s.order < 8)){
                widthColumns += Number(s.width.replace("px", ""));
            }
        })
        return widthColumns
    }

    const getColWidth = (col) => {
        let column = columns.find(c => c.field === col)
        return column.width
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="" footerStyle={{textAlign: 'right', padding: 0, width:getFooterWidthSpace()}}/>
            <Column footer={totalContainers} footerStyle={{textAlign: 'center', width:getColWidth('cargos')}}/>
            <Column footer={totalTeus} footerStyle={{textAlign: 'center', width:getColWidth('teus')}}/>
            <Column footer={initialTotalPlan} footerStyle={{textAlign: 'center', width:getColWidth('initialPlan')}}/>
            <Column footer={totalPlan} footerStyle={{textAlign: 'center', width:getColWidth('plan')}}/>
            <Column footer={totalReal} footerStyle={{textAlign: 'center', width:getColWidth('real')}}/>
            <Column />
            <Column footerStyle={{textAlign: 'center', width:getColWidth('manager')}}/>
        </Row>
    </ColumnGroup>;

    const columnComponents = selectedColumns.map(col => {
        return <Column style={{minWidth: col.width, width: col.width, flexDirection: 'column'}} key={col.key} field={col.field} sortable={false}
                       header={<LazySortMultiFields header={col.header} fieldKey={col.key}
                                                    field={col.field} lazyParams={lazyParams}
                                                    crud={'plan-real'}
                                                    setLazyParams={setLazyParams}/>}
                       body={rowData => getBodyTemplate(col.key, rowData, col.field)}/>;
    });

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                <DataTable ref={dt} value={bookingsList}
                           footerColumnGroup={footerGroup} size="small"
                           dataKey="id" paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                           onPage={(e) => setLazyParams(e)} totalRecords={totalRecords} lazy
                           stateStorage="local" scrollable
                           responsiveLayout="scroll"
                           scrollHeight="flex" id={'plan-real-list'}
                           stateKey="plan-real-dt-state-local"
                           className="datatable-responsive" loading={loading} first={lazyParams.first} rowHover
                           rows={lazyParams.rows}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} bookings"
                           emptyMessage="No bookings found." header={header}>
                    {columnComponents}
                </DataTable>
                </div>
            </div>
        </div>
    );

}