import {Dropdown} from "primereact/dropdown";
import classNames from "classnames";
import React, {useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {UserService} from "../../../service/UserService";
import {isNullOrUndef} from "chart.js/helpers";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

export const BookingTransferModule = (props) => {

    const [managers, setManagers] = useState([]);
    const [manager, setManager] = useState(null);
    const [comment, setComment] = useState("");

    const userService = new UserService();

    useMountEffect(() => {
        userService.getManagers().then(data => {
            data.forEach(m => m['name'] = m.firstName + ' ' + m.lastName)
            setManagers(data);
        });

    })

    const loadTransferBody = () => {
        return <div className="p-fluid p-grid">
            <div className="p-field p-col-12">
                <label htmlFor="transferBookingManagers">Operators</label>
                <Dropdown id={'transferBookingManagers'} options={managers} value={manager}
                          onChange={(e) => setManager(e.value)}
                          placeholder="-- Select --"
                          optionLabel={'name'} optionValue={'id'} dropdownIcon={null}
                          className={classNames({'p-invalid': isNullOrUndef(manager)})}/>
                {isNullOrUndef(manager) && <small className="p-invalid">Required</small>}
            </div>
            <div className="p-field p-col-12">
                <label htmlFor="transferBookingComment"><b>Comment</b></label>
                <InputTextarea id="transferBookingComment" value={comment} rows={5} cols={30} autoResize
                               onChange={(e) => setComment(e.target.value)}/>
            </div>
            <div className="p-field p-col-12 p-d-flex">
                <Button label="Transfer" icon={'pi pi-save'} disabled={isNullOrUndef(manager)}
                        onClick={() => props.transferBooking(manager, comment)}
                        className="p-button-success mr-2 mb-2"/>
                <Button label="Cancel" icon={'pi pi-ban'}
                        onClick={() => props.cancel()} className={'mr-2 mb-2'}/>
            </div>
        </div>
    }

    const loadRejectBody = () => {
        return <div className="p-fluid p-grid">
            <div className="p-field p-col-12">
                <label htmlFor="transferBookingComment"><b>Comment</b></label>
                <InputTextarea id="transferBookingComment" value={comment} rows={5} cols={30} autoResize
                               onChange={(e) => setComment(e.target.value)}/>
            </div>
            <div className="p-field p-col-12 p-d-flex">
                <Button label="Reject" icon={'pi pi-save'}
                        onClick={() => props.rejecttransferBooking(comment)}
                        className="p-button-success mr-2 mb-2"/>
                <Button label="Cancel" icon={'pi pi-ban'}
                        onClick={() => props.cancel()} className={'mr-2 mb-2'}/>
            </div>
        </div>
    }

    return props.transfer ? loadTransferBody() : loadRejectBody()
}