import React, {useState, useRef, Fragment} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {MailTemplateService} from "../service/MailTemplateService";
import {useMountEffect} from "primereact";

export const MailTemplates = () => {

    let emptyMailTemplate = {
        id: null,
        name: '',
        subject: '',
        body: '',
    };

    const [deleteMailTemplateDialog, setDeleteMailTemplateDialog] = useState(false);
    const [mailTemplates, setMailTemplates] = useState(null);
    const [mailTemplateDialog, setMailTemplateDialog] = useState(false);
    const [mailTemplate, setMailTemplate] = useState(emptyMailTemplate);
    const [selectedMailTemplates, setSelectedMailTemplates] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [preview, setPreview] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const mailTemplateService = new MailTemplateService();

    useMountEffect(() => {
        mailTemplateService.getMailTemplates()
            .then(data => {
                setMailTemplates(data);
                setLoading(false);
            });
    });

    // const openNew = () => {
    //     setMailTemplate(emptyMailTemplate);
    //     setSubmitted(false);
    //     setMailTemplateDialog(true);
    // }

    const hideDialog = () => {
        setSubmitted(false);
        setPreview(false);
        setMailTemplateDialog(false);
    }

    const hideDeleteMailTemplateDialog = () => {
        setDeleteMailTemplateDialog(false);
    }

    const saveMailTemplate = () => {
        setSubmitted(true);

        if (mailTemplate.name.trim()) {
            let _mailTemplates = [...mailTemplates];
            let _mailTemplate = {...mailTemplate};

            mailTemplateService.saveMailTemplate(_mailTemplate)
                .then(data => {
                    if (mailTemplate.id) {
                        const index = findIndexById(mailTemplate.id);
                        _mailTemplates[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Mail Template Updated',
                            life: 3000
                        });
                    } else {
                        _mailTemplates.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Mail Template Created',
                            life: 3000
                        });
                    }
                    setMailTemplates(_mailTemplates);
                    setMailTemplateDialog(false);
                    setMailTemplate(emptyMailTemplate);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const previewMailTemplate = (mailTemplate) => {
        setMailTemplate({...mailTemplate});
        setMailTemplateDialog(true);
        setPreview(true);
    }

    // const editMailTemplate = (mailTemplate) => {
    //     setMailTemplate({...mailTemplate});
    //     setMailTemplateDialog(true);
    // }
    //
    // const confirmDeleteMailTemplate = (mailTemplate) => {
    //     setMailTemplate(mailTemplate);
    //     setDeleteMailTemplateDialog(true);
    // }

    const deleteMailTemplate = () => {
        mailTemplateService.deleteMailTemplate(mailTemplate.id)
            .then(data => {
                let _mailTemplates = mailTemplates.filter(val => val.id !== mailTemplate.id);
                setMailTemplates(_mailTemplates);
                setDeleteMailTemplateDialog(false);
                setMailTemplate(emptyMailTemplate);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Mail Template Deleted',
                    life: 3000
                });
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < mailTemplates.length; i++) {
            if (mailTemplates[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _mailTemplate = {...mailTemplate};
        _mailTemplate[`${name}`] = val;

        setMailTemplate(_mailTemplate);
    }

    // const onEditorChange = (e) => {
    //     let _mailTemplate = {...mailTemplate};
    //     _mailTemplate.body = e.htmlValue;
    //     setMailTemplate(_mailTemplate);
    // }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/*Kol kas nereikalinga funkcija*/}
                {/*<Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>*/}
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const subjectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Subject</span>
                {rowData.subject}
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/*<Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"*/}
                {/*        onClick={() => editMailTemplate(rowData)}/>*/}
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info p-button-text p-button-sm"
                        title={'View'} onClick={() => previewMailTemplate(rowData)}/>

                {/*<Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2 p-mb-2"*/}
                {/*        onClick={() => confirmDeleteMailTemplate(rowData)}/>*/}
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Mail Templates</h5>
        </div>
    );

    const mailTemplateDialogFooter = (
        <>
            <Button label={preview ? 'Close' : 'Cancel'} icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            {!preview ?
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveMailTemplate}/> : ''}
        </>
    );
    const deleteMailTemplateDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMailTemplateDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteMailTemplate}/>
        </>
    );

    // const editorButtons = (<Fragment>
    //     <select className="ql-size" title="Font size">
    //         <option value="small"/>
    //         <option value="large"/>
    //         <option value="huge"/>
    //     </select>
    //     <select className="ql-font" aria-label="Fonts" title="Fonts"/>
    //     <span className="ql-formats">
    //         <button className="ql-bold" aria-label="Bold" title="Bold"/>
    //         <button className="ql-italic" aria-label="Italic" title="Italic"/>
    //         <button className="ql-underline" aria-label="Underline" title="Underline"/>
    //         <button className="ql-strike" aria-label="Strike" title="Strike"/>
    //         <button className="ql-clean" aria-label="Clean" title="Clean"/>
    //     </span>
    // </Fragment>);

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={mailTemplates} selection={selectedMailTemplates}
                               onSelectionChange={(e) => setSelectedMailTemplates(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} country directions"
                               emptyMessage="No mail templates found." header={header}>
                        <Column field="name" header="Name" sortable body={nameBodyTemplate}/>
                        <Column field="subject" header="Subject" sortable body={subjectBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={mailTemplateDialog} style={{width: '450px'}} header="Mail Template Details" modal
                            className="p-fluid" footer={mailTemplateDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={mailTemplate.name} onChange={(e) => onInputChange(e, 'name')}
                                       required autoFocus disabled={preview} style={{opacity: 1}}
                                       className={classNames({'p-invalid': submitted && !mailTemplate.name})}/>
                            {submitted && !mailTemplate.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="subject">Subject</label>
                            <InputText id="subject" value={mailTemplate.subject}  disabled={preview} style={{opacity: 1}}
                                       onChange={(e) => onInputChange(e, 'subject')} required autoFocus
                                       className={classNames({'p-invalid': submitted && !mailTemplate.subject})}/>
                            {submitted && !mailTemplate.subject &&
                            <small className="p-invalid">Subject is required.</small>}
                        </div>
                        <div className="p-field">
                            <div dangerouslySetInnerHTML={{
                                    __html: mailTemplate.body
                                }}/>
                            {/*<Editor id="body" value={mailTemplate.body} onTextChange={onEditorChange}*/}
                            {/*        headerTemplate={editorButtons} required autoFocus style={{height:'320px'}}*/}
                            {/*        className={classNames({'p-invalid': submitted && !mailTemplate.body})}/>*/}
                            {/*{submitted && !mailTemplate.body && <small className="p-invalid">Body is required.</small>}*/}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteMailTemplateDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteMailTemplateDialogFooter} onHide={hideDeleteMailTemplateDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {mailTemplate && <span>Are you sure you want to delete <b>{mailTemplate.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
