import React, {useRef, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useHistory} from 'react-router-dom';
import { validate } from 'email-validator';
import AuthService from "../service/AuthService";
import {Message} from "primereact/message";
import {Dialog} from "primereact/dialog";
import { Toast } from 'primereact/toast';

const Login = () => {

    const [username, setUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const toast = useRef(null);
    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);

    const history = useHistory();

    const handleLogin = (e) => {
        e.preventDefault();

        AuthService.login(username, password).then(
            () => {
                history.push("/dashboard");
            },
            error => {
                setError("Bad credentials!");
            }
        );
    }

    const onResetPassword = () => {
        if(validate(userEmail)){
            AuthService.resetPasswordInit(userEmail).then(data => {
                if(data.status === 200){
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'User password reset! Please check your Email.',
                        life: 3000
                    });
                    setResetPasswordDialog(false);
                    setUserEmail('');
                }
            })
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Email is not correct!',
                life: 3000
            });
        }

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => setResetPasswordDialog(false)} className="p-button-text" />
                <Button label="Reset" icon="pi pi-check" onClick={onResetPassword} autoFocus />
            </div>
        );
    }

    return (
        <div className="pages-body login-page p-d-flex p-flex-column">
            <Toast ref={toast} />
            <div className="p-as-center p-mt-auto p-mb-auto">
                <div className="pages-panel card p-d-flex p-flex-column">
                    <div className="pages-header p-px-3 p-py-1">
                        <h2>LOGIN</h2>
                    </div>

                    <h4>Welcome</h4>

                    <div className="input-panel p-d-flex p-flex-column p-px-3">
                        {error && <Message severity="error" text={error} style={{marginBottom: '1em'}}/>}
                        <div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-user"></i>
							</span>
                            <span className="p-float-label">
								<InputText type="text" id="inputgroup1" value={username}
                                           onChange={(e) => setUsername(e.target && e.target.value)} required
                                           autoFocus/>
								<label htmlFor="inputgroup1">Username</label>
							</span>
                        </div>

                        <div className="p-inputgroup p-mt-3 p-mb-6">
							<span className="p-inputgroup-addon">
								<i className="pi pi-lock"></i>
							</span>
                            <span className="p-float-label">
								<InputText type="password" id="inputgroup2" value={password}
                                           onChange={(e) => setPassword(e.target && e.target.value)} required/>
								<label htmlFor="inputgroup2">Password</label>
							</span>
                        </div>

                    </div>
                    <Button className="login-button p-mb-6 p-px-3" label="LOGIN" onClick={handleLogin}/>
                    <Button label="Reset password" className="p-button-link" onClick={() => setResetPasswordDialog(true)} />
                    <Dialog header="Reset password" visible={resetPasswordDialog} onHide={() => setResetPasswordDialog(false)}
                            style={{width: '25vw'}} footer={renderFooter}>
                        <div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-envelope"></i>
							</span>
                            <span className="p-float-label">
								<InputText type="text" id="inputgroup1" value={userEmail}
                                           onChange={(e) => setUserEmail(e.target && e.target.value)} required
                                           autoFocus/>
								<label htmlFor="inputgroup1">User Email</label>
							</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Login;
