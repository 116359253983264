import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ColumnGroup, Row} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {Toast} from "primereact/toast";
import {InvoicesService} from "../../../service/InvoicesService";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import {ListBox} from "primereact/listbox";
import {confirmDialog} from "primereact/confirmdialog";
import classNames from "classnames";
import {TabPanel, TabView} from "primereact/tabview";
import {PaymentForm} from "../PaymentForm";
import {AuditLog} from "../../../components/AuditLog";
import {usePrevious} from "primereact/hooks";
import nextId from "react-id-generator";

export const IncomeDebitForm = (props) => {

    const [load, setLoad] = useState(true)
    const [preLoad, setPreLoad] = useState(false)
    const [edit, setEdit] = useState(false)
    const [addBookingsDialog, setAddBookingsDialog] = useState(false)
    const [selectedBookings, setSelectedBookings] = useState([])
    const [allowCombineInvList, setAllowCombineInvList] = useState([])
    const [debitInvoice, setDebitInvoice] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [taxTypes, setTaxTypes] = useState([]);
    const [currency, setCurrency] = useState('');
    const [issueDate, setIssueDate] = useState(null);
    const [checkSum, setCheckSum] = useState('');
    const [validate, setValidate] = useState(false);
    const [activatedEdit, setActivatedEdit] = useState(false);
    const [showOtherCurrency, setShowOtherCurrency] = useState(false);
    const [invoiceAuditData, setInvoiceAuditData] = useState(null);
    const [invoiceAuditDialogVisible, setInvoiceAuditDialogVisible] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const invoicesService = new InvoicesService();
    const JSum = require('jsum')


    let {paramInvoiceId, paramBookingId, paramTab} = useParams();
    let prevDebitInvoice = usePrevious(debitInvoice)
    const toast = useRef(null);
    let history = useHistory();
    let emptyDebitInvoice = {
        additionalInfo: "",
        client: null,
        currency: null,
        dueDate: null,
        id: null,
        invoiceItems: null,
        invoiceType: 'DEBIT',
        issueDate: null,
        number: "",
        refInvoiceNumber: "",
        vat: null,
        version: 0,
        locked: false,
        overrideEditPermission: false
    }

    useEffect(() => {
        if (!isNullOrUndef(paramInvoiceId) && !isNaN(parseInt(paramInvoiceId))) {
            setEdit(true)
            getDebitInvoiceData(paramInvoiceId)
        } else {
            let _emptyDebitInvoice = {...emptyDebitInvoice}
            setDebitInvoice(_emptyDebitInvoice)
            setLoad(false)
            setTaxType(null)
        }
    }, [paramInvoiceId])

    useEffect(() => {
        if(!isNullOrUndef(debitInvoice) && !isNullOrUndef(prevDebitInvoice)
            && (prevDebitInvoice.currency !== debitInvoice.currency || prevDebitInvoice.issueDate !== debitInvoice.issueDate)) {
            recalculateSums()
        }
    }, [currency, issueDate])

    const recalculateSums = () => {
        if (!isNullOrUndef(debitInvoice)) {
            setMultiCurrency(debitInvoice)
            if (!validateInvoice() && !isNullOrUndef(debitInvoice.number) && debitInvoice.number !== '') {
                setPreLoad(true)
                let _invoiceDTO = prepareDebitInvoiceToSave()
                invoicesService.recalculate(_invoiceDTO)
                    .then(data => {
                        if (data.success) {
                            if(!isNullOrUndef(data.data.invoicePayments) && data.data.invoicePayments.length > 0) {
                                data.data.invoicePayments.forEach(p => {
                                    if (isNullOrUndef(p.id)) {
                                        p.id = 'Id_' + nextId()
                                    }
                                })
                            }
                            setDebitInvoice(data.data)
                            setPreLoad(false)
                        } else {
                            toast.current.show({
                                severity: 'error',
                                summary: 'Recalculate invoice',
                                detail: data.errorMessage
                            });
                        }
                    })
            }
        }
    }

    const closeAndBackDebitInvoice = () => {
        if (!isNullOrUndef(paramBookingId) && paramBookingId !== '-1') {
            let tabParam = !isNullOrUndef(paramTab) ? paramTab : 0
            history.push(`/bookings/view/${paramBookingId}/${tabParam}`);
        } else if (!isNullOrUndef(paramBookingId) && paramBookingId === '-1') {
            history.push(`/invoices/payments`);
        } else {
            history.push(`/invoices/debit`);
        }
    }

    const prepareDebitInvoiceToSave = () => {
        let _debitInvoice = {...debitInvoice}
        if (isNullOrUndef(_debitInvoice.issuedBy)) {
            _debitInvoice['issuedBy'] = {id: props.userData.id}
        } else {
            _debitInvoice.issuedBy = {id: _debitInvoice.issuedBy.id}
        }
        _debitInvoice.issueDate = moment(_debitInvoice.issueDate).toDate()
        if (!isNullOrUndef(_debitInvoice.createdBy)) {
            _debitInvoice.createdBy = {id: _debitInvoice.createdBy.id}
        }
        _debitInvoice.dueDate = moment(_debitInvoice.dueDate).toDate()
        if (!isNullOrUndef(_debitInvoice.bookings) && _debitInvoice.bookings.length > 0) {
            _debitInvoice.invoiceStatus = 'ISSUED'
        }
        _debitInvoice['clientId'] = null
        if (!isNullOrUndef(_debitInvoice.client)) {
            _debitInvoice['clientId'] = _debitInvoice.client.id
        }
        delete _debitInvoice.client

        _debitInvoice['bookingIds'] = []
        if (!isNullOrUndef(_debitInvoice.bookings) && _debitInvoice.bookings.length > 0) {
            _debitInvoice['bookingIds'] = _debitInvoice.bookings.map(b => b.id)
        }
        delete _debitInvoice.bookings

        _debitInvoice['currencyId'] = null
        if (!isNullOrUndef(_debitInvoice.currency)) {
            _debitInvoice['currencyId'] = _debitInvoice.currency.id
        }
        delete _debitInvoice.currency


        _debitInvoice['taxTypeId'] = null
        _debitInvoice['taxTypeId'] = _debitInvoice.taxType.id
        _debitInvoice['vatId'] = null
        if (!isNullOrUndef(_debitInvoice.taxType) && !isNullOrUndef(_debitInvoice.taxType.vat)) {
            _debitInvoice['vatId'] = _debitInvoice.taxType.vat.id;
        }
        delete _debitInvoice.taxType
        delete _debitInvoice.vat

        if (!isNullOrUndef(_debitInvoice.invoiceItems) && _debitInvoice.invoiceItems.length > 0) {
            _debitInvoice.invoiceItems.forEach(item => {
                if (!Number.isFinite(item.id)) {
                    item.id = null
                }
                item['invoiceItemTypeId'] = null
                delete item.invoiceItemType

                item['initialPlanCurrencyId'] = null
                delete item.initialPlanCurrency

                item['currencyId'] = null
                if (!isNullOrUndef(_debitInvoice.currency)) {
                    item['currencyId'] = item.currency.id
                }
                delete item.currency

                item['planCurrencyId'] = null
                if (!isNullOrUndef(_debitInvoice.planCurrency)) {
                    item['planCurrencyId'] = item.planCurrency.id
                }
                delete item.planCurrency

                item['bookingId'] = null
                if (!isNullOrUndef(_debitInvoice.booking)) {
                    item['bookingId'] = item.booking.id
                }
                delete item.booking
            })
        }

        if (!isNullOrUndef(_debitInvoice.invoicePayments) && _debitInvoice.invoicePayments.length > 0) {
            _debitInvoice.invoicePayments.forEach(payment => {
                if (!Number.isFinite(payment.id)) {
                    payment.id = null
                }
            })
        }

        return _debitInvoice
    }

    const downloadInvoice = () => {
        invoicesService.getInvoicePdf(debitInvoice.id).then(r => {
            if (r.status === 200) {
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a')
                link.href = url;
                link.setAttribute('download', debitInvoice.number + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })
    }

    const validateInvoice = () => {
        return isNullOrUndef(debitInvoice.issueDate) || debitInvoice.issueDate === ''
            || isNullOrUndef(debitInvoice.dueDate) || debitInvoice.dueDate === ''
            || isNullOrUndef(debitInvoice.currency) || debitInvoice.currency === ''
            || isNullOrUndef(debitInvoice.taxType) || debitInvoice.taxType === ''
            || (!isNullOrUndef(debitInvoice.taxType) && debitInvoice.taxType === 4
                && (!isNullOrUndef(debitInvoice.refInvoiceNumber) || debitInvoice.refInvoiceNumber))
    }

    const saveAndBackDebitInvoice = () => {
        if (!validateInvoice()) {
            invoicesService.createInvoice(prepareDebitInvoiceToSave()).then(data => {
                if (data.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Save Invoice',
                        detail: 'Invoice was saved!'
                    });
                    setValidate(false)
                    closeAndBackDebitInvoice()
                } else {
                    toast.current.show({severity: 'error', summary: 'Save invoice', detail: data.errorMessage});
                }
            })
        } else {
            setValidate(true)
            toast.current.show({
                severity: 'error',
                summary: 'Save Invoice',
                detail: 'Please fill all required field!'
            });
        }
    }

    const saveAndEditDebitInvoice = () => {
        if (!validateInvoice()) {
            invoicesService.createInvoice(prepareDebitInvoiceToSave()).then(data => {
                if (data.success) {
                    toast.current.show({severity: 'success', summary: 'Save', detail: 'Invoice was saved!'});
                    setValidate(false)
                    if (!isNullOrUndef(debitInvoice.id)) {
                        getDebitInvoiceData(debitInvoice.id)
                    } else {
                        history.push(`/invoices/debit/view/${data.data.id}`);
                    }
                } else {
                    toast.current.show({severity: 'error', summary: 'Save invoice', detail: data.errorMessage});
                }
            })
        } else {
            setValidate(true)
            toast.current.show({
                severity: 'error',
                summary: 'Save invoice',
                detail: 'Please fill all required field!'
            });
        }
    }

    const getDebitInvoiceData = (invoiceId) => {
        invoicesService.getInvoice(invoiceId, 'DEBIT')
            .then(data => {
                if (isNullOrUndef(data.number) || data.number === '') {
                    if (!isNullOrUndef(data.vat) && !isNullOrUndef(data.vat.id)) {
                        data.vat = true
                    }
                    if (!isNullOrUndef(data.ourCreditTime) && data.ourCreditTime > 0 && isNullOrUndef(data.dueDate)) {
                        data.dueDate = moment(new Date()).add(data.ourCreditTime, 'd').format()
                    }
                    if (isNullOrUndef(data.issueDate)) {
                        data.issueDate = moment(new Date()).format()
                    }
                    setIssueDate(data.issueDate)
                }
                setTaxType(data)
                setCurrency(!isNullOrUndef(data.currency) ? data.currency.code : '')
                if(isNullOrUndef(data.number)){
                    data.number = ""
                }
                if(isNullOrUndef(data.refInvoiceNumber)){
                    data.refInvoiceNumber  = ""
                }
                setDebitInvoice(data)
                setMultiCurrency(data)
                setCheckSum(JSum.digest(data, 'SHA256', 'hex'))
                if (!isNullOrUndef(paramBookingId) && paramBookingId === '-1' && !isNullOrUndef(paramTab)) {
                    setActiveIndex(Number(paramTab))
                }
                let isAdmin = props.checkUserRole('ROLE_ADMIN');
                let bookingStatusIsClosed = data.bookings.length > 0 && !data.bookings.some(b => b.status.id !== 4)
                if(!isAdmin){
                    setIsClosed(bookingStatusIsClosed || data.locked)
                }
                setLoad(false)
            });
    }

    const setMultiCurrency = (invoice) => {
        if (!isNullOrUndef(invoice.invoiceItems) && invoice.invoiceItems.length > 0) {
            let multiCurrency = invoice.invoiceItems.filter(inv => !isNullOrUndef(inv.currency) && inv.currency.code !== currency)
            setShowOtherCurrency(multiCurrency.length > 0)
        }
    }

    const setTaxType = (invoiceData) => {
        invoicesService.getTaxTypes().then(data => {
            if (!isNullOrUndef(data) && data.length > 0) {
                setTaxTypes(data)
                if (!isNullOrUndef(invoiceData) && isNullOrUndef(invoiceData.taxType) && (isNullOrUndef(invoiceData.number) || invoiceData.number === '')
                    && !isNullOrUndef(invoiceData.vat) && invoiceData.vat) {
                    invoiceData.taxType = data.find(tx => tx.id === 2)
                }
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Tax Types',
                    detail: 'Please contact to your administrator!'
                });
            }
        })
    }

    const onChange = (value, type) => {
        if (type === 'refInvoiceNumber') {
            setDebitInvoice({
                ...debitInvoice,
                additionalInfo: "",
                refInvoiceNumber: value
            });
        } else if (type === 'additionalInfo') {
            setDebitInvoice({
                ...debitInvoice,
                additionalInfo: value,
                refInvoiceNumber: ""
            });
        } else {
            if (type === 'currency') {
                setCurrency(value.code)
            }
            setDebitInvoice({
                ...debitInvoice,
                [type]: value
            });
        }
        if (type === 'issueDate') {
            if (!isNullOrUndef(debitInvoice.ourCreditTime) && debitInvoice.ourCreditTime > 0) {
                setDebitInvoice({
                    ...debitInvoice,
                    issueDate: value,
                    dueDate: moment(value).add(debitInvoice.ourCreditTime, 'd').format()
                })
                setIssueDate(value)
            }
        }
    }

    const getTotalToBePaid = () => {
        if (!isNullOrUndef(debitInvoice) && !isNullOrUndef(debitInvoice.totalSumByStandardCurrency)) {
            let total = debitInvoice.totalSumByStandardCurrency
            return total + ' ' + 'EUR'
        } else {
            return 0
        }
    }
    const getTotalToBePaidByCurrency = () => {
        if (!isNullOrUndef(debitInvoice) && !isNullOrUndef(debitInvoice.totalSumWithVat)) {
            let total = debitInvoice.totalSumWithVat
            return total + ' ' + currency
        } else {
            return 0
        }
    }

    const footerGroup = () => {
        return <ColumnGroup>
            <Row>
                <Column footer={'TOTAL TO BE PAID:'} style={{textAlign: 'right'}}
                        colSpan={!showOtherCurrency ? 5 : 6}/>
                <Column footer={getTotalToBePaid()}/>
            </Row>
        </ColumnGroup>
    }

    const footerGroupWithOtherCurrency = () => {
        return <ColumnGroup>
            <Row>
                <Column footer={'TOTAL TO BE PAID:'} style={{textAlign: 'right'}}
                        colSpan={!showOtherCurrency ? 5 : 6}/>
                <Column footer={getTotalToBePaid()}/>
            </Row>
            <Row>
                <Column footer={'TOTAL TO BE PAID BY INVOICE:'} style={{textAlign: 'right'}}
                        colSpan={!showOtherCurrency ? 5 : 6}/>
                <Column footer={getTotalToBePaidByCurrency()}/>
            </Row>
        </ColumnGroup>;
    }

    const invoiceBack = () => {
        if (!isNullOrUndef(debitInvoice.taxType) && (debitInvoice.taxType.id === 4 || debitInvoice.taxType.id === 6)) {
            return <div className="p-col-2">
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="debitInvoiceNr">Enter invoice number</label>
                        <InputText id={'debitInvoiceNr'} value={debitInvoice.refInvoiceNumber} disabled={isClosed}
                                   onChange={(e) => onChange(e.target.value, "refInvoiceNumber")}/>
                    </div>
                </div>
            </div>
        }
        return null
    }

    const reverseCharge = () => {
        if (!isNullOrUndef(debitInvoice.taxType) && debitInvoice.taxType.id === 3) {
            return <div className="p-col-2">
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="debitInvoiceAdditionalInfo">Additional information</label>
                        <InputText id={'debitInvoiceAdditionalInfo'} disabled={isClosed}
                                   value={!isNullOrUndef(debitInvoice.additionalInfo) ? debitInvoice.additionalInfo : ''}
                                   onChange={(e) => onChange(e.target.value, "additionalInfo")}/>
                    </div>
                </div>
            </div>
        }
        return null
    }

    const sumVatBody = (rowData) => {
        let sum = !isNullOrUndef(rowData.sumVatByInvoiceCurrency) ? rowData.sumVatByInvoiceCurrency : 0

        return sum + ' ' + currency
    }

    const sumBody = (rowData) => {
        let sum = !isNullOrUndef(rowData.sumByInvoiceCurrency) ? rowData.sumByInvoiceCurrency : 0

        return sum + ' ' + currency
    }

    const priceByInvoiceCurrencyBody = (rowData) => {
        let sum = !isNullOrUndef(rowData.priceByInvoiceCurrency) ? rowData.priceByInvoiceCurrency : 0

        return sum + ' ' + currency
    }

    const priceBody = (rowData) => {
        if (isNullOrUndef(rowData.currency))
            return

        let sum = !isNullOrUndef(rowData.price) ? rowData.price : 0

        return sum + ' ' + rowData.currency.code
    }

    const totalBody = (rowData) => {
        let sum = !isNullOrUndef(rowData.sumWithVatByInvoiceCurrency) ? rowData.sumWithVatByInvoiceCurrency : 0
        return sum + ' ' + currency
    }

    const openBookingDialog = () => {
        if (!isNullOrUndef(debitInvoice.client)) {
            invoicesService.searchPendingDebitInvoices(debitInvoice.client.id)
                .then(data => {
                    let allowCombineInv = []
                    data.forEach(d => {
                        allowCombineInv.push({label: d.bookingIds[0], value: d.id})
                    })
                    setAllowCombineInvList(allowCombineInv)
                    setAddBookingsDialog(true)
                })
        }
    }

    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.bookingIds[0]}</div>
            </div>
        );
    }

    const combineInvoices = () => {
        if (selectedBookings.length > 0) {
            let combineInvoices = {
                invoiceIds: selectedBookings,
                mainInvoiceId: debitInvoice.id
            }
            if (checkSum === JSum.digest(debitInvoice, 'SHA256', 'hex')) {
                invoicesService.combineInvoices(combineInvoices)
                    .then(data => {
                        if (data.success === false) {
                            toast.current.show({severity: 'error', summary: 'Save', detail: data.errorMessage});
                        } else {
                            getDebitInvoiceData(debitInvoice.id)
                        }
                    })
            } else {
                toast.current.show({severity: 'error', summary: 'Save', detail: 'First save invoice'});
            }
            setAddBookingsDialog(false)
        } else {
            toast.current.show({severity: 'warn', summary: 'Add bookings', detail: 'Nothing selected'});
        }
    }

    const detachInvoice = (bookingId) => {
        if (checkSum === JSum.digest(debitInvoice, 'SHA256', 'hex')) {
            let detachInvoices = {
                bookingId: bookingId,
                mainInvoiceId: debitInvoice.id
            }
            invoicesService.detachInvoices(detachInvoices)
                .then(data => {
                    if (data.success === false) {
                        toast.current.show({severity: 'error', summary: 'Save', detail: data.errorMessage});
                    } else {
                        getDebitInvoiceData(debitInvoice.id)
                    }
                })
        } else {
            toast.current.show({severity: 'error', summary: 'Save', detail: 'First save invoice'});
        }
    }

    const addBookingDialogActions = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text"
                    onClick={() => setAddBookingsDialog(false)}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={combineInvoices}/>
        </>
    );

    const confirmDelete = (bookingId) => {
        confirmDialog({
            message: `Are you sure you want to remove this booking ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => detachInvoice(bookingId),
            reject: () => null
        });
    }

    const combineBookingList = () => {
        if (isNullOrUndef(debitInvoice.bookings) || debitInvoice.bookings.length < 2)
            return

        return debitInvoice.bookings.map(b => {
            return <div style={{display: 'inline-flex'}} key={b.id}>
                <Button title={'Remove'} label={b.id} className="p-button-link"
                        onClick={() => confirmDelete(b.id)} disabled={isClosed} />
            </div>
        })
    }

    const warnAboutActiveEdit = () => {
        return toast.current.show({
            severity: 'warn',
            summary: 'Edit',
            detail: 'Some edit is activated! Close active edit. '
        });
    }

    const checkInvoice = () => {
        return isNullOrUndef(debitInvoice.number) || isNullOrUndef(debitInvoice.client) || isNullOrUndef(debitInvoice.currency)
            || isNullOrUndef(debitInvoice.id) || isNullOrUndef(debitInvoice.invoiceItems) || debitInvoice.invoiceItems.length === 0
    }

    const getMinDate = () => {
        if(debitInvoice.overrideEditPermission)
            return null;

        const today = moment();
        const startOfMonth = moment().startOf('month');
        const fifteenthOfMonth = moment().date(14);
        if (today.isBetween(startOfMonth, fifteenthOfMonth, 'day', '[]')) {
            const previousMonthFirstDay = moment().subtract(1, 'months').startOf('month');
            return previousMonthFirstDay.toDate();
        } else {
            const currentMonthFirstDay = moment().startOf('month');
            return currentMonthFirstDay.toDate();
        }
    }

    const body = () => {
        return <TabView activeIndex={activeIndex}
                        onTabChange={(e) => !activatedEdit ? setActiveIndex(e.index) : warnAboutActiveEdit()}>
            <TabPanel header="Invoice">
                <div className={'p-grid'}>
                    <div className="p-col-2">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="debitInvoiceNr">Invoice number</label>
                                <InputText id={'debitInvoiceNr'} value={debitInvoice.number} disabled/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="debitDateOfIssue">Date of issue</label>
                                <Calendar id="debitDateOfIssue" showIcon panelClassName={'celebrate'}
                                          value={!isNullOrUndef(debitInvoice.issueDate) ? moment(debitInvoice.issueDate).toDate() : null}
                                          dateFormat={'yy-mm-dd'} locale={'lt'} disabledDays={[0, 6]}
                                          disabledDates={props.invalidDates} disabled={isClosed} minDate={getMinDate()}
                                          onChange={(e) => onChange(e.value, 'issueDate')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(debitInvoice.issueDate)})}/>
                                {validate && isNullOrUndef(debitInvoice.issueDate) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="debitDueDate">Due date</label>
                                <Calendar id="debitDueDate" showIcon
                                          value={!isNullOrUndef(debitInvoice.dueDate) ? moment(debitInvoice.dueDate).toDate() : null}
                                          panelClassName={'celebrate'} disabled={isClosed}
                                          dateFormat={'yy-mm-dd'} locale={'lt'}
                                          minDate={!isNullOrUndef(debitInvoice.issueDate) ? moment(debitInvoice.issueDate).toDate() : null}
                                          onChange={(e) => onChange(e.value, 'dueDate')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(debitInvoice.dueDate)})}/>
                                {validate && isNullOrUndef(debitInvoice.dueDate) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="debitInvoiceCurrency">Currency</label>
                                <Dropdown id={'debitInvoiceCurrency'} value={debitInvoice.currency} disabled={isClosed}
                                          options={props.currencies} optionLabel={'code'} placeholder="-- Select --"
                                          onChange={(e) => onChange(e.value, 'currency')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(debitInvoice.currency)})}/>
                                {validate && isNullOrUndef(debitInvoice.currency) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="debitInvoiceTaxType">Invoice type</label><br/>
                                <Dropdown id={'debitInvoiceTaxType'} value={debitInvoice.taxType} options={taxTypes}
                                          optionLabel={'title'} placeholder="-- Select --" disabled={isClosed}
                                          onChange={(e) => onChange(e.value, 'taxType')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(debitInvoice.taxType)})}/>
                                {validate && isNullOrUndef(debitInvoice.taxType) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    {reverseCharge()}
                    {invoiceBack()}
                </div>
                {!isNullOrUndef(debitInvoice.client) ?
                    <div className={'p-grid'}>
                        <div className="p-col-2">
                            <div className="p-fluid">
                                <Button label="Add Bookings" className="p-button-link"
                                        onClick={openBookingDialog} disabled={isClosed}/>
                            </div>
                        </div>
                        <div className="p-col-10">
                            <div className="p-fluid">
                                <b>Bookings: </b>
                                {combineBookingList()}
                            </div>
                        </div>
                    </div> : ''}
                <div className={'p-grid'}>
                    <div className={'p-col-12'}>
                        <DataTable value={debitInvoice.invoiceItems}
                                   footerColumnGroup={!isNullOrUndef(currency) && currency === 'EUR' ? footerGroup() : footerGroupWithOtherCurrency()}
                                   showGridlines responsiveLayout="scroll"
                                   size="small"
                                   header={!isNullOrUndef(debitInvoice.client) ? debitInvoice.client.title : ''}
                                   loading={load} dataKey="id">
                            <Column style={{width: '25%'}} field={'service'} header={'Service'}/>
                            <Column style={{width: '5%'}} field={'quantity'} header={'Qnt'}/>
                            <Column style={{width: '10%'}} field={'price'} header={'Price'} body={priceBody}/>
                            <Column style={{width: '10%'}} field={'priceByInvoiceCurrency'}
                                    header={'Price by Currency'}
                                    hidden={!showOtherCurrency} body={priceByInvoiceCurrencyBody}/>
                            <Column style={{width: '10%'}} field={'sum'} header={'Sum'} body={sumBody}/>
                            <Column style={{width: '10%'}} field={'sumVat'} header={'VAT'} body={sumVatBody}/>
                            <Column style={{width: '15%'}} field={'sumWithVat'} header={'Total'} body={totalBody}/>
                        </DataTable>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditExchangeRate:">Exchange rate</label><br/>
                                <div>{debitInvoice.currencyRate}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            {props.checkUserRole('ROLE_ADMIN') ?
                <TabPanel header="Payments" disabled={checkInvoice()}>
                    <PaymentForm toast={toast} invoice={debitInvoice} currencies={props.currencies}
                                 warnAboutActiveEdit={warnAboutActiveEdit} recalcInvoice={recalculateSums}
                                 activatedEdit={activatedEdit} setActivatedEdit={setActivatedEdit}/>
                </TabPanel>
                : ''}
        </TabView>
    }

    const checkDownload = () => {
        let valid = false;
        if (edit && !isNullOrUndef(debitInvoice) && !isNullOrUndef(debitInvoice.id)
            && !isNullOrUndef(debitInvoice.number) && debitInvoice.invoiceStatus !== 'PENDING') {
            valid = true
        }
        return valid
    }

    const showAuditLog = (invoiceId) => {
        invoicesService.getAuditLog(invoiceId).then(data => {
            setInvoiceAuditData(data);
            setInvoiceAuditDialogVisible(true);
        })
    }

    const hideAuditLogDialog = () => {
        setInvoiceAuditDialogVisible(false);
    }

    return <div className="card">
        <Toast ref={toast}/>
        {preLoad ?
            <div className={'loader-container'}>
                <div className={'loader-clip'}/>
            </div> : ''}
        <div style={{display: "flex"}}>
            <h5>{edit ? 'Edit Invoice (Debit)' : 'Add new Invoice (Debit)'}</h5>
            {checkDownload() ?
                <Button style={{margin: "5px", position: "relative", bottom: "10px"}} icon="pi pi-download"
                        className="p-button" onClick={() => downloadInvoice(debitInvoice)}/>
                : ''}
        </div>
        {load ?
            <div className={'loader-container'}>
                <div className={'loader-clip'}/>
            </div>
            : body()}
        <div style={{textAlign: 'right'}}>
            <Button label="Save & Edit" icon={'pi pi-save'} onClick={saveAndEditDebitInvoice}
                    className="p-button-success mr-2 mb-2" disabled={activatedEdit || isClosed}/>
            <Button label="Save & Back" icon={'pi pi-history'} onClick={saveAndBackDebitInvoice}
                    className="p-button-warning mr-2 mb-2" disabled={activatedEdit || isClosed}/>
            <Button label="Close & Don't Save" icon={'pi pi-ban'} onClick={closeAndBackDebitInvoice}
                    className={'mr-2 mb-2'} disabled={activatedEdit}/>
            <Button label="Log" icon={'pi pi-list'} visible={props.checkUserRole(['ROLE_SUPER_ADMIN'])}
                    onClick={() => showAuditLog(debitInvoice ? debitInvoice.id : null)} className={'p-button-secondary mr-2 mb-2'}/>
        </div>
        <div style={{textAlign: 'right', color: '#999', opacity: '0.3'}}>Version: 1</div>
        <Dialog onHide={() => setAddBookingsDialog(false)} style={{width: '250px'}} header="Add bookings"
                footer={addBookingDialogActions} visible={addBookingsDialog}>
            <ListBox value={selectedBookings} options={allowCombineInvList} multiple filter
                     optionValue={"value"} optionLabel={"label"}
                     onChange={(e) => setSelectedBookings(e.value)}
                     style={{width: '15rem'}} listStyle={{maxHeight: '250px'}}/>
        </Dialog>
        <Dialog visible={invoiceAuditDialogVisible} onHide={hideAuditLogDialog}
                header={`Invoice ${debitInvoice ? debitInvoice.id : null} log`}>
            <AuditLog object={invoiceAuditData}></AuditLog>
        </Dialog>
    </div>;

}