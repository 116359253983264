import axios from 'axios'
import authHeader from "./auth-header";

export class MailTemplateService {

    getMailTemplates() {
        return axios.get('/api/mail-template', { headers: authHeader() })
            .then(res => res.data);
    }

    saveMailTemplate(mailTemplate) {
        return axios.put('/api/mail-template', mailTemplate, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteMailTemplate(mailTemplateId) {
        return axios.delete(`/api/mail-template/${mailTemplateId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}