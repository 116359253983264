import axios from 'axios'
import authHeader from './auth-header';

export class UserService {

    getManagers() {
        return axios.get('/api/user', { headers: authHeader() })
            .then(res => {
                return res.data.content.filter(u => u.login !== 'system' && u.login !== 'anonymoususer' && u.login !== 'user'
                    && u.login !== 'raimis' && u.login !== 'denas' && u.login !== 'admin'
                    && u.activated && u.authorities.some(a => a === 'ROLE_MANAGER'))
            });
    }

    getUserData() {
        return axios.get('/api/account', { headers: authHeader() })
            .then(res => res.data);
    }

    getAuthorities() {
        return axios.get('/api/user/authorities', { headers: authHeader() })
            .then(res => res.data);
    }

    getUsers() {
        return axios.get('/api/user', { headers: authHeader() })
            .then(res => res.data.content);
    }

    saveUser(user) {
        return axios.post('/api/user', user, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteUser(userId) {
        return axios.delete(`/api/user/${userId}`, { headers: authHeader() })
            .then(res => res.data);
    }

}