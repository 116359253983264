import React, {useState, useRef, useEffect, Fragment} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InvoicesService} from "../../../service/InvoicesService";
import {isNullOrUndef} from "chart.js/helpers";
import {useHistory} from "react-router-dom";
import {ToggleButton} from "primereact/togglebutton";
import {ExportFileService} from "../../../service/ExportFileService";
import {useMountEffect} from "primereact/hooks";
import {InvoicesFilters} from "../InvoicesFilters";
import {Checkbox} from "primereact/checkbox";
import moment from "moment/moment";
import {LazySortMultiFields} from "../../../components/LazySortMultiFields";

export const PurchasesList = (props) => {

    let emptyInvoiceCredit = {
        id: null,
        name: ''
    };

    const [invoicesCredit, setInvoicesCredit] = useState(null);
    const [deleteInvoiceCreditDialog, setDeleteInvoiceCreditDialog] = useState(false);
    const [invoiceCredit, setInvoiceCredit] = useState(emptyInvoiceCredit);
    const [selectedInvoiceCredit, setSelectedInvoiceCredit] = useState(null);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchInvoices, setSearchInvoices] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const invoicesService = new InvoicesService();
    const exportFileService = new ExportFileService();
    const history = useHistory();
    const op = useRef(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;

    useMountEffect(() => {
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('purchases-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        setLoading(true);
        getInvoices()
        setFilterColumns([
            {key: 'number', header: 'Invoice number', field: 'number'},
            {key: 'client', header: 'Office', field: 'client.title'},
            {key: 'issueDate', header: 'Issue date', field: 'issueDate'},
            {key: 'dueDate', header: 'Due date', field: 'dueDate'},
        ])
    });

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getInvoices()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getInvoices = (exportToExcel) => {
        let filterParams = null
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('purchases-filter-data'));
        if(!isNullOrUndef(filters) && filters !== ''){
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('purchases-filter-data', JSON.stringify(filters))
        } else if(!isNullOrUndef(filterParamsStorage)){
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('purchases-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('purchases-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('purchases-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        if(exportToExcel){
            exportPurchasesToExcel(filterParams, sortFields)
        } else {
            getInvoicesWithParams(filterParams, sortFields)
        }
    }

    const exportPurchasesToExcel = (filterParams, sortFields) => {
        let columns = 'issueDate,dueDate,client.title,number,totalVatSumByStandardCurrency,totalSumByStandardCurrency,totalSumWithVatByStandardCurrency,paidType'
        invoicesService.exportToExcel('PURCHASE', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'OfficeList_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
    }

    const getInvoicesWithParams = (filterParams, sortFields) => {
        invoicesService.getInvoices(lazyParams.page, lazyParams.rows, 'PURCHASE', sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.totalElements);
                setInvoicesCredit(data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
    }


    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const hideDeleteInvoiceCreditDialog = () => {
        setDeleteInvoiceCreditDialog(false);
    }

    const editInvoiceCredit = (invoiceCreditId) => {
        history.push(`/invoices/purchases/view/${invoiceCreditId}`);
    }

    const confirmDeleteInvoiceCredit = (invoiceCredit) => {
        setInvoiceCredit(invoiceCredit);
        setDeleteInvoiceCreditDialog(true);
    }

    const deleteInvoiceCredit = () => {
        invoicesService.deletePurchase(invoiceCredit)
            .then(data => {
                if(!isNullOrUndef(data) && data.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Purchase is deleted successfully.',
                        life: 3000
                    });
                    let _invoicesCredit = invoicesCredit.filter(val => val.id !== invoiceCredit);
                    setInvoicesCredit(_invoicesCredit);
                    setDeleteInvoiceCreditDialog(false);
                    setInvoiceCredit(emptyInvoiceCredit);
                } else {
                    toast.current.show({severity: 'error', summary: 'Error', detail: 'Cannot delete invoice. Please contact with administrator', life: 3000});
                    setDeleteInvoiceCreditDialog(false);
                    setInvoiceCredit(emptyInvoiceCredit);
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editInvoiceCredit(rowData.id)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteInvoiceCredit(rowData.id)}/>
            </div>
        );
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('purchases-filter-toggle', JSON.stringify(e.value))
    }

    const getMultiClientGroupId = () => {
        let ids = [];
        ids.push(props.getClientGroupId('office'))
        return ids;
    }

    const header = () => {
        return <Fragment>
            <div className="table-header">
                <h5 className="p-mb-2">Office</h5>
            </div>
            <div className="flex justify-content-between flex-wrap card-container">
                <div className="flex align-items-center justify-content-center">
                    <ToggleButton checked={filter} onChange={onFilterToggle}
                                  onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                                  offIcon="pi pi-filter" aria-label="Filters"/>
                    <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchInvoices(searchInvoices + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-plus" iconPos="right" label={'New'} className="p-button-success mr-2"
                            onClick={() => editInvoiceCredit('new')}/>
                    <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                            onClick={() => getInvoices(true)}/>
                </div>
            </div>
            <div>
                <InvoicesFilters loading={loading} filterColumns={filterColumns} filter={filter}
                                 clientGroupId={getMultiClientGroupId()}
                                 searchInvoices={searchInvoices} clearFilter={clearFilter} filterList={'purchases'}
                                 getInvoices={getInvoices} filters={filters} setFilters={setFilters}/>
            </div>
        </Fragment>
    }


    const deleteInvoiceCreditDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteInvoiceCreditDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteInvoiceCredit}/>
        </>
    );

    const vatTemplate = (rowData) => {
        if (isNullOrUndef(rowData.vat))
            return "NO"

        return "YES"
    }

    const dueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.dueDate))
            return

        return moment(rowData.dueDate).format("YYYY-MM-DD")
    }

    const issueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.issueDate))
            return

        return moment(rowData.issueDate).format("YYYY-MM-DD")
    }

    const paidNotPaidTemplate = (rowData) => {
        if (isNullOrUndef(rowData["paidType"]))
            return

        let type = 'Not paid'
        let color = '#E55E5E'
        if (rowData["paidType"] === 'PAID') {
            type = 'Paid'
            color = '#5ee55e'
        } else if (rowData["paidType"] === 'PARTIALLY_PAID') {
            type = 'Paid/Unpaid'
            color = '#e5a25e'
        }
        return <span style={{background: color, padding: '2px', width: '100%', display: 'block', textAlign: 'center'}}>
            {type}
        </span>
    }

    const getHeader = (field, header) => {
        return <LazySortMultiFields header={header} fieldKey={field} crud={'purchases'}
                                    field={field} lazyParams={lazyParams}
                                    setLazyParams={setLazyParams}/>
    }

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                    <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                    <DataTable ref={dt} value={invoicesCredit} selection={selectedInvoiceCredit}
                               onPage={(e) => setLazyParams(e)} totalRecords={totalRecords}
                               rowHover lazy rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]} dataKey="id"
                               first={lazyParams.first} paginator id={'purchases-list'}
                               onSelectionChange={(e) => setSelectedInvoiceCredit(e.value)}
                               className="datatable-responsive" loading={loading}
                               rows={lazyParams.rows} size="small" scrollable
                               scrollHeight="flex" stateStorage={'purchases-dt-state-local'}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                               emptyMessage="No Invoices found." header={header}>

                        <Column field={'issueDate'} header={getHeader('issueDate', 'Issue date')}
                                style={{minWidth: '90px'}} body={issueDateTemplate} />
                        <Column field={'dueDate'} header={getHeader('dueDate', 'Due date')}
                                style={{minWidth: '90px'}} body={dueDateTemplate}/>
                        <Column field="client.title" header={getHeader('client.title', 'Office')} />
                        <Column field="number" header={getHeader('number', 'Invoice number')}/>
                        <Column field="totalVatSum" header={getHeader('totalVatSum', 'VAT')}/>
                        <Column field="totalSum" header={getHeader('totalSum', 'Sum')}/>
                        <Column field="totalSumWithVat" header={getHeader('totalSumWithVat', 'Total sum')}/>
                        <Column field={'paid'} header={getHeader('paid', 'Paid')}
                                style={{minWidth: '80px'}} body={paidNotPaidTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>
                </div>

                    <Dialog visible={deleteInvoiceCreditDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteInvoiceCreditDialogFooter} onHide={hideDeleteInvoiceCreditDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {invoiceCredit && <span>Are you sure you want to delete <b>{invoiceCredit.name}</b>?</span>}
                        </div>
                    </Dialog>
            </div>
        </div>
    );
}
