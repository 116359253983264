import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EventService} from "../service/EventService";
import {useMountEffect} from "primereact";

export const EventTypes = () => {

    let emptyEventType = {
        id: null,
        title: ''
    };

    const [eventTypes, setEventTypes] = useState(null);
    const [eventTypeDialog, setEventTypeDialog] = useState(false);
    const [deleteEventTypeDialog, setDeleteEventTypeDialog] = useState(false);
    const [eventType, setEventType] = useState(emptyEventType);
    const [selectedEventTypes, setSelectedEventTypes] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const eventService = new EventService();

    useMountEffect(() => {
        eventService.getEventTypes()
            .then(data => {
                setEventTypes(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setEventType(emptyEventType);
        setSubmitted(false);
        setEventTypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEventTypeDialog(false);
    }

    const hideDeleteEventTypeDialog = () => {
        setDeleteEventTypeDialog(false);
    }

    const saveEventType = () => {
        setSubmitted(true);

        if (eventType.title.trim()) {
            let _eventTypes = [...eventTypes];
            let _eventType = {...eventType};

            eventService.saveEventType(_eventType)
                .then(data => {
                    if (eventType.id) {
                        const index = findIndexById(eventType.id);
                        _eventTypes[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Event Type Updated',
                            life: 3000
                        });
                    } else {
                        _eventTypes.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Event Type Created',
                            life: 3000
                        });
                    }
                    setEventTypes(_eventTypes);
                    setEventTypeDialog(false);
                    setEventType(emptyEventType);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editEventType = (eventType) => {
        setEventType({ ...eventType });
        setEventTypeDialog(true);
    }

    const confirmDeleteEventType = (eventType) => {
        setEventType(eventType);
        setDeleteEventTypeDialog(true);
    }

    const deleteEventType = () => {
        eventService.deleteEventType(eventType.id)
            .then(data => {
                let _eventTypes = eventTypes.filter(val => val.id !== eventType.id);
                setEventTypes(_eventTypes);
                setDeleteEventTypeDialog(false);
                setEventType(emptyEventType);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Type Deleted',
                    life: 3000
                });
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eventTypes.length; i++) {
            if (eventTypes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _eventType = { ...eventType };
        _eventType[`${name}`] = val;

        setEventType(_eventType);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editEventType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteEventType(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Event Types</h5>
        </div>
    );

    const eventTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEventType} />
        </>
    );
    const deleteEventTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEventTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEventType} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={eventTypes} selection={selectedEventTypes} onSelectionChange={(e) => setSelectedEventTypes(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} country directions"
                        emptyMessage="No event types found." header={header}>
                        <Column field="title" header="Title" sortable body={titleBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={eventTypeDialog} style={{ width: '450px' }} header="Event Type Details" modal className="p-fluid" footer={eventTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={eventType.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !eventType.title })} />
                            {submitted && !eventType.title && <small className="p-invalid">Title is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEventTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventTypeDialogFooter} onHide={hideDeleteEventTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {eventType && <span>Are you sure you want to delete <b>{eventType.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
