import React, {useEffect, useState} from 'react';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

export const AuditLog = (props) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        setData(props.object);
    }, [props.object]);

    const valueTemplate = (valueData, props) => {
        if (valueData[props.field] === null) {
            return '';
        } else if (typeof valueData[props.field] === 'object') {
            return (
                <>
                    <span className="p-column-title">{props.header}</span>
                    {valueData[props.field].cdoId}
                </>
            );
        } else {
            return (
                <>
                    <span className="p-column-title">{props.header}</span>
                    {valueData[props.field]}
                </>
            );
        }
    }

    return (

        <DataTable value={data}
                   paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   className="datatable-responsive"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} countries"
                   emptyMessage="No audit data found.">
            <Column field="commitMetadata.commitDate" header="Time"/>
            <Column field="commitMetadata.author" header="User"/>
            <Column field="property" header="Property"/>
            <Column field="left" header="From value" body={valueTemplate}/>
            <Column field="right" header="To value" body={valueTemplate}/>
        </DataTable>
    )

}