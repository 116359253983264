import axios from 'axios'
import authHeader from "./auth-header";

export class CountryDirectionService {

    getCountryDirections() {
        return axios.get('/api/direction', { headers: authHeader() })
            .then(res => res.data);
    }

    saveCountryDirection(countryDirection) {
        return axios.post('/api/direction', countryDirection, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteCountryDirection(countryDirectionId) {
        return axios.delete(`/api/direction/${countryDirectionId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}