import React, {useState, useRef, useEffect, Fragment} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InvoicesService} from "../../../service/InvoicesService";
import {isNullOrUndef} from "chart.js/helpers";
import {useHistory} from "react-router-dom";
import {ToggleButton} from "primereact/togglebutton";
import {useMountEffect} from "primereact/hooks";
import {InvoicesFilters} from "../InvoicesFilters";
import {Checkbox} from "primereact/checkbox";
import moment from "moment/moment";
import {LazySortMultiFields} from "../../../components/LazySortMultiFields";
import {MultiSelect} from "primereact/multiselect";

export const CostCreditList = (props) => {

    let emptyInvoiceCredit = {
        id: null,
        name: ''
    };

    const [invoicesCredit, setInvoicesCredit] = useState(null);
    const [deleteInvoiceCreditDialog, setDeleteInvoiceCreditDialog] = useState(false);
    const [invoiceCredit, setInvoiceCredit] = useState(emptyInvoiceCredit);
    const [selectedInvoiceCredit, setSelectedInvoiceCredit] = useState(null);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchInvoices, setSearchInvoices] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const invoicesService = new InvoicesService();
    const history = useHistory();
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;
    const columns = [
        {key: 'number', header: 'Invoice number', field: 'number', width: 'auto', filter: true},
        {key: 'bookings', header: 'Ref.ID', field: 'bookings.id', width: 'auto', filter: true},
        {key: 'client', header: 'Vendor', field: 'client.title', width: 'auto', filter: true},
        {key: 'currencyCode', header: 'Currency', field: 'currency.code', width: 'auto'},
        {key: 'invoiceItemsBooking', header: 'Used', field: 'invoiceItems.booking', width: 'auto'},
        {key: 'vat', header: 'VAT', field: 'vat', width: 'auto'},
        {key: 'issueDate', header: 'Issue date', field: 'issueDate', width: 'auto', filter: true},
        {key: 'dueDate', header: 'Due date', field: 'dueDate', width: '90px', filter: true},
        {key: 'costManager', header: 'Vendor Manager', field: 'costManager', width: 'auto', filter: true},
        {key: 'createdBy', header: 'Created', field: 'createdBy', width: 'auto', filter: true},
    ]

    useMountEffect(() => {
        const columnsStorage = JSON.parse(window.localStorage.getItem('credit-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage)
        } else {
            setSelectedColumns(columns)
            window.localStorage.setItem('credit-datatable-columns', JSON.stringify(columns))
        }
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('credit-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('credit-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        setLoading(true);
        getInvoices()
        setFilterColumns(columns.filter(c => c.filter))
    });

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getInvoices()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getInvoices = (exportToExcel) => {
        let filterParams = null
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('credit-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('credit-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('credit-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('credit-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if (!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        if (exportToExcel) {
            exportCreditInvoiceToExcel(filterParams, sortFields)
        } else {
            getInvoicesWithParams(filterParams, sortFields)
        }
    }

    const exportCreditInvoiceToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        invoicesService.exportToExcel('', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'CreditInvoices_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getInvoicesWithParams = (filterParams, sortFields) => {
        invoicesService.getInvoices(lazyParams.page, lazyParams.rows, 'CREDIT', sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.totalElements);
                setInvoicesCredit(data.content);
                setLoading(false);
                setLoadFirstTime(false)
            });
    }


    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const hideDeleteInvoiceCreditDialog = () => {
        setDeleteInvoiceCreditDialog(false);
    }

    const editInvoiceCredit = (invoiceCreditId) => {
        history.push(`/invoices/credit/view/${invoiceCreditId}`);
    }

    const confirmDeleteInvoiceCredit = (invoiceCredit) => {
        setInvoiceCredit(invoiceCredit);
        setDeleteInvoiceCreditDialog(true);
    }

    const deleteInvoiceCredit = () => {
        invoicesService.deleteInvoiceCredit(invoiceCredit.id)
            .then(data => {
                let _invoicesCredit = invoicesCredit.filter(val => val.id !== invoiceCredit.id);
                setInvoicesCredit(_invoicesCredit);
                setDeleteInvoiceCreditDialog(false);
                setInvoiceCredit(emptyInvoiceCredit);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'InvoiceCredit Deleted',
                    life: 3000
                });
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editInvoiceCredit(rowData.id)}/>
                {/*<Button icon="pi pi-trash" className="p-button-rounded p-button-danger"*/}
                {/*        onClick={() => confirmDeleteInvoiceCredit(rowData.id)}/>*/}
            </div>
        );
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('credit-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns);
        window.localStorage.setItem('credit-datatable-columns', JSON.stringify(selectedColumns))
    }

    const header = () => {
        return <Fragment>
            <div className="table-header">
                <h5 className="p-mb-2">Invoices (Credit)</h5>
            </div>
            <div className="flex justify-content-between flex-wrap card-container">
                <div className="flex align-items-center justify-content-center">
                    <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                                 placeholder="Select Item" id={'credit-selectColumns'} filter
                                 optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                    <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                                  onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                                  offIcon="pi pi-filter" aria-label="Filters"/>
                    <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchInvoices(searchInvoices + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-plus" iconPos="right" label={'New'} className="p-button-success mr-2"
                            onClick={() => editInvoiceCredit('new')}/>
                    <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                            onClick={() => getInvoices(true)}/>
                </div>
            </div>
            <div>
                <InvoicesFilters loading={loading} filterColumns={filterColumns} filter={filter}
                                 clientGroupId={props.getClientGroupId('vendor')}
                                 searchInvoices={searchInvoices} clearFilter={clearFilter} filterList={'credit'}
                                 getInvoices={getInvoices} filters={filters} setFilters={setFilters}/>
            </div>
        </Fragment>
    }


    const deleteInvoiceCreditDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteInvoiceCreditDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteInvoiceCredit}/>
        </>
    );

    const usedTemplate = (rowData) => {
        let used = false;
        if (!isNullOrUndef(rowData.invoiceItems) && rowData.invoiceItems.length > 0) {
            let allUsed = rowData.invoiceItems.some(inv => isNullOrUndef(inv.booking))
            if (!allUsed) {
                used = true
            }
        }

        return <Checkbox checked={used}/>
    }

    const bookingsTemplate = (rowData) => {
        if (isNullOrUndef(rowData))
            return

        if (rowData.bookings.length > 0) {
            return <div className={'col-12 p-0'} key={'key'}>
                {rowData.bookings.map(b => {
                    return <Button label={b.id} key={`button_bookingId_${b.id}`}
                                   className="p-button-link p-0 p-m-0 col-4"
                                   style={{textAlign: "left"}}
                                   onClick={() => goToBooking(rowData.bookings[0].id)}/>
                })}
            </div>

        }
    }

    const dueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.dueDate))
            return

        return moment(rowData.dueDate).format("YYYY-MM-DD")
    }

    const issueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.issueDate))
            return

        return moment(rowData.issueDate).format("YYYY-MM-DD")
    }

    const createdByTemplate = (rowData, key) => {
        if (isNullOrUndef(rowData[key]))
            return

        return rowData[key].firstName + ' ' + rowData[key].lastName
    }

    const costManagerTemplate = (rowData, key) => {
        if (isNullOrUndef(rowData.client) || isNullOrUndef(rowData.client.costsManager))
            return

        return rowData.client.costsManager.firstName + ' ' + rowData.client.costsManager.lastName
    }


    const getBodyTemplate = (key, rowData) => {
        switch (key) {
            case 'number':
                return !isNullOrUndef(rowData.number) ? rowData[key] : ''
            case 'bookings':
                return !isNullOrUndef(rowData.bookings) ? bookingsTemplate(rowData) : ''
            case 'client':
                return !isNullOrUndef(rowData.client) ? rowData.client.title : ''
            case 'currencyCode':
                return !isNullOrUndef(rowData.currency) ? rowData.currency.code : ''
            case 'issueDate':
                return !isNullOrUndef(rowData.issueDate) ? issueDateTemplate(rowData) : ''
            case 'dueDate':
                return !isNullOrUndef(rowData.dueDate) ? dueDateTemplate(rowData) : ''
            case 'invoiceItemsBooking':
                return !isNullOrUndef(rowData.invoiceItems) ? usedTemplate(rowData) : ''
            case 'vat':
                return !isNullOrUndef(rowData.vat) ? 'YES' : 'NO'
            case 'costManager':
                return costManagerTemplate(rowData, key)
            case 'createdBy':
                return createdByTemplate(rowData, key)
            default:
                return !isNullOrUndef(rowData[key]) ? rowData[key] : ''
        }
    }

    const columnComponents = () => {
        return selectedColumns.map(col => {
            return <Column style={{minWidth: col.width}} key={col.key} field={col.field} sortable={false}
                           header={<LazySortMultiFields header={col.header} fieldKey={col.key} crud={'credit'}
                                                        field={col.field} lazyParams={lazyParams}
                                                        setLazyParams={setLazyParams}/>}
                           body={rowData => getBodyTemplate(col.key, rowData)}/>;
        });
    }

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                    <DataTable ref={dt} value={invoicesCredit} selection={selectedInvoiceCredit}
                               onPage={(e) => setLazyParams(e)} totalRecords={totalRecords}
                               rowHover lazy rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]} dataKey="id"
                               removableSort first={lazyParams.first} paginator
                               onSelectionChange={(e) => setSelectedInvoiceCredit(e.value)}
                               className="datatable-responsive" loading={loading}
                               rows={lazyParams.rows} size="small" scrollable
                               scrollHeight="flex" id={'credit-invoices-list'}
                               stateStorage="local" stateKey="credit-dt-state-local"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                               emptyMessage="No Invoices found." header={header}>
                        {columnComponents()}
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>
                </div>

                <Dialog visible={deleteInvoiceCreditDialog} style={{width: '450px'}} header="Confirm" modal
                        footer={deleteInvoiceCreditDialogFooter} onHide={hideDeleteInvoiceCreditDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {invoiceCredit && <span>Are you sure you want to delete <b>{invoiceCredit.name}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
