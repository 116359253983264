import React, {Fragment, useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputTextarea} from "primereact/inputtextarea";
import moment from "moment/moment";
import {EventService} from "../service/EventService";
import {UserService} from "../service/UserService";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import classNames from "classnames";
import {MultiSelect} from "primereact/multiselect";
import {InputNumber} from "primereact/inputnumber";
import {ClientService} from "../service/ClientService";
import {Calendar} from "primereact/calendar";
import {useMountEffect} from "primereact";
import {AutoComplete} from "primereact/autocomplete";

export const EventsCalendar = (props) => {

    let emptyEvent = {
        id: null,
        client: null,
        contactPerson: null,
        eventType: null,
        date: null,
        notes: '',
        remainder: null,
        read: false,
        doneDateTime: null,
        reminded: false,
        users: null,
        eventStatus: null,
        resolution: ''
    };

    const [eventDialog, setEventDialog] = useState(false);
    const [newEventDialog, setNewEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({
        extendedProps: null,
        title: '',
        start: null,
        end: null,
        allDay: null
    });
    const [events, setEvents] = useState(null);
    const [event, setEvent] = useState(emptyEvent);
    const [users, setUsers] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [eventsSourceList, setEventsSourceList] = useState(null);
    const [mouseEventId, setMouseEventId] = useState(null);
    const [mouseEventDate, setMouseEventDate] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [clientContactPersons, setClientContactPersons] = useState(null);
    const [eventsTypes, setEventsTypes] = useState(null);
    const [client, setClient] = useState(null);
    const [filteredClients, setFilteredClients] = useState(null);
    const toast = useRef(null);
    const eventService = new EventService();
    const clientService = new ClientService();
    const userService = new UserService();

    useMountEffect(() => {
        getCalendarEvents()
        eventService.getEventTypes().then(data => {
            setEventsTypes(data);
        });
        userService.getManagers().then(data => {
            setUsers(data)
        });
        if (props.userData) {
            let _admin = props.userData.authorities.some(u => u === 'ROLE_ADMIN')
            setAdmin(_admin);
        }
    });

    useEffect(() => {
        if (client !== null) {
            clientService.getClientContactPersons(client.id).then(data => {
                setClientContactPersons(data);
            });
        }
    }, [client])

    const getCalendarEvents = () => {
        userService.getUserData().then(userData => {
            eventService.getAllUserEvents(userData.id).then(eventsData => {
                setEventsSourceList(eventsData)
                makeEvents(eventsData)
            });
        });
    }

    const makeEvents = (eventsData) => {
        let _events = eventsData.map(e => {
            let event = {
                id: e.id,
                title: moment(e.date).format('kk:mm') + ' - ' + e.client.title + ' (' + e.eventType.title + ')',
                start: e.date,
                allDay: true,
                extendedProps: e,
                color: e.eventStatus !== null ? '#'+e.eventStatus.color : '#3788d8',
                classNames: e.eventStatus !== null ? e.eventStatus.name : '',
            }
            return event
        })
        if(_events.length > 0){
            setEvents(_events)
        } else {
            setEvents(null)
        }
    }

    const close = () => {
        setEventDialog(false);
    }

    const footer = (
        <>
            <Button type="button" label="Close" icon="pi pi-check" className="p-button-text" onClick={close}/>
            {/*<Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />*/}
            {/*<Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />*/}
        </>
    );

    const eventClick = (e) => {
        const {extendedProps, title, start, end} = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({extendedProps, title, start, end, allDay: null});
    }

    const eventMouseEnter = (e) => {
        setMouseEventId(Number(e.event._def.publicId))
        setMouseEventDate(moment(e.event._def.extendedProps.date).format('YYYY-MM-DD').toString())
    }

    const eventMouseLeave = (e) => {
        setMouseEventId(null)
        setMouseEventDate(null)
    }

    const showMouseEnterEvent = () => {
        if (mouseEventId === null)
            return null

        let event = eventsSourceList.find(e => e.id === mouseEventId)
        if (mouseEventId && event.contactPerson !== null) {
            return (<Fragment>
                <div>Contact person: {event.contactPerson.name + ' ' + event.contactPerson.surname}</div>
                <div>Position: {event.contactPerson.position}</div>
                <div>Email: {event.contactPerson.email}</div>
                <div>Phone: {event.contactPerson.phone}</div>
            </Fragment>)
        } else if(mouseEventId) {
            return (<Fragment>
                <div>No contact person info</div>
            </Fragment>)
        }
    }

    const onClickDay = (day) => {
        if(moment(day.date).format('YYYY-MM-DD') < moment(Date.now()).format('YYYY-MM-DD'))
            return;

        let _emptyEvent = {...emptyEvent}
        if (!admin) {
            _emptyEvent.user = props.userData.id;
        }
        _emptyEvent.date = day.date
        setEvent(_emptyEvent);
        setSubmitted(false);
        setNewEventDialog(true)
    }

    const checkRequired = (type) => {
        setSubmitted(true);
        let _submitted = true;
        if ((event.resolution === null || event.resolution === '' || event.eventStatus === null) && event.reminded) {
            _submitted = false;
        }

        if ((event.eventType === null || event.contactPerson === null || event.users === null ||
            event.date === null || event.remainder === null) && !event.reminded) {
            _submitted = false;
        }

        if (_submitted) {
            saveEvent(type);
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Requirement',
                detail: 'Please fill all Required fields.',
                life: 3000
            });
        }
    }

    const saveEvent = (type) => {
        setSubmitted(true);

        let _event = {
            id: null,
            clientId: client.id,
            contactPersonId: event.contactPerson !== null ? event.contactPerson.id : null,
            eventTypeId: event.eventType !== null ? event.eventType.id : null,
            date: event.date,
            notes: event.notes,
            remainder: event.remainder,
            read: event.reminded,
            doneDateTime: event.doneDateTime,
            userIDs: event.users.map(u => u.id),
            eventStatusId: event.eventStatus ? event.eventStatus.id : null,
            resolution: event.resolution
        }

        eventService.setEvent(_event).then(data => {
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Event Created',
                life: 3000
            });
        });

        if (type === 'new') {
            let newEvent = {
                id: null,
                client: event.client,
                contactPerson: event.contactPerson,
                eventType: event.eventType,
                date: event.date,
                notes: event.notes,
                remainder: event.remainder,
                read: false,
                doneDateTime: null,
                users: event.users,
                eventStatus: null,
                resolution: null
            }
            setEvent(newEvent)
        } else if (type === 'save') {
            hideDialog()
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _event = {...event};
        _event[`${name}`] = val;
        setEvent(_event);
    }

    const onDateChange = (e) => {
        let _event = {...event};
        _event.date = e.value;
        setEvent(_event);
    }

    const onRemainderChange = (e) => {
        let _event = {...event};
        _event.remainder = e.value;
        setEvent(_event);
    }

    const changeEventType = (e) => {
        let _event = {...event};
        let filteredEventType = eventsTypes.filter(ev => ev.id === e.value.id)
        _event.eventType = filteredEventType[0];
        setEvent(_event);
    }

    const changeEventContactPerson = (e) => {
        let _event = {...event};
        let filteredContactPerson = clientContactPersons.filter(ev => ev.id === e.value.id)
        _event.contactPerson = filteredContactPerson[0];
        setEvent(_event);
    }

    const changeEventManager = (e) => {
        let _event = {...event};
        _event.users = e.value;
        setEvent(_event);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClient(null)
        setNewEventDialog(false);
        setEvent(emptyEvent)
        getCalendarEvents()
    }

    const contactPersonLabel = (e) => {
        return e.name + ' ' + e.surname
    }

    const selectedContactPersonTemplate = (option, props) => {
        if (props.value) {
            return (
                <div className="contact-person-item contact-person-item-value">
                    <div style={{color: 'rgba(0, 0, 0, 0.87)'}}>{props.value.name + ' ' + props.value.surname}</div>
                </div>
            );
        }

        return (
            <span>
            {props.placeholder}
        </span>
        );
    }

    const selectedManagerTemplate = (option, props) => {
        if (option) {
            return (
                <div className="manager-item manager-item-value">
                    {option.firstName + ' ' + option.lastName}
                </div>
            );
        }
    }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClients(event.query.trim().toLowerCase()).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const managerOptionTemplate = (option) => {
        return (
            <div className="manager-item">
                <div>{option.firstName + ' ' + option.lastName}</div>
            </div>
        );
    }

    const eventDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => checkRequired('save')}/>
            {/*<Button label="Save & New" icon="pi pi-check-square" className="p-button-text"*/}
            {/*        onClick={() => checkRequired('new')}/>*/}
        </>
    );

    return (
        <div className="p-grid">
            <Toast ref={toast}/>
            <div className="p-col-12">
                <div className="card" style={{height: 'calc(100vh - 110px)'}}>
                    <FullCalendar id={'fullCalendar'} dateClick={onClickDay}
                                  events={events} eventClick={eventClick}
                                  eventMouseEnter={eventMouseEnter}
                                  eventMouseLeave={eventMouseLeave}
                                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    />
                    <Tooltip style={{display: mouseEventDate === null ? 'none' : ''}}
                             target={`[data-date='${mouseEventDate}'] .fc-daygrid-event`} position={'bottom'}
                             showDelay={300}>
                        {showMouseEnterEvent()}
                    </Tooltip>
                    <Dialog visible={eventDialog && !!clickedEvent} style={{width: '450px'}} header="Event Details"
                            dismissableMask={true}
                            footer={footer} modal closable onHide={() => setEventDialog(false)} closeOnEscape={true}>
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="title">Title</label>
                                <InputTextarea id="title"
                                               value={changedEvent.extendedProps ? changedEvent.extendedProps.client.title : ''}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactPerson">Contact person</label>
                                <InputTextarea id="contactPerson"
                                               value={changedEvent.extendedProps && changedEvent.extendedProps.contactPerson !== null ? changedEvent.extendedProps.contactPerson.name + ' ' + changedEvent.extendedProps.contactPerson.surname : ''}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactPhone">Phone</label>
                                <InputTextarea id="contactPhone"
                                               value={changedEvent.extendedProps ? changedEvent.extendedProps.client.phone : ''}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactMail">eMail</label>
                                <InputTextarea id="contactMail"
                                               value={changedEvent.extendedProps ? changedEvent.extendedProps.client.email : ''}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="type">Type</label>
                                <InputTextarea id="type"
                                               value={changedEvent.extendedProps ? changedEvent.extendedProps.eventType.title : ''}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="date">Date</label>
                                <InputTextarea id="date"
                                               value={moment(changedEvent.extendedProps ? changedEvent.extendedProps.date : '').format('YYYY-MM-DD HH:MM')}
                                               autoFocus autoResize/>
                            </div>
                            <div className="p-field">
                                <label htmlFor="notes">Notes</label>
                                <InputTextarea id="notes"
                                               value={changedEvent.extendedProps ? changedEvent.extendedProps.notes : ''}
                                               autoFocus autoResize/>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={newEventDialog} style={{width: '450px'}} header="Event Details" modal
                            id={'newEventDialog'} className="p-fluid" footer={eventDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="clients">Client</label>
                            <AutoComplete id={'costVendorAutoComplete'} value={client} suggestions={filteredClients}
                                               completeMethod={searchClient} showEmptyMessage field="title"
                                               tooltip={'Min 3 characters'} inputStyle={{width: '100%'}} style={{width: '100%'}}
                                               onChange={(e) => setClient(e.value)}
                                               onSelect={(e) => setClient(e.value)}
                                               minLength={3} placeholder="-- Select --" required
                                          className={classNames({'p-invalid': submitted && !client})}/>
                            {submitted && !client &&
                                <small className="p-invalid">Client is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="type">Type</label>
                            <Dropdown value={event.eventType} options={eventsTypes} optionLabel="title" required
                                      placeholder="-- Select --"
                                      className={classNames({'p-invalid': submitted && !event.eventType})}
                                      disabled={client === null} onChange={changeEventType}/>
                            {submitted && !event.eventType &&
                            <small className="p-invalid">Type is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="contactPerson">Contact person</label>
                            <Dropdown name={'contactPerson'} value={event.contactPerson}
                                      options={clientContactPersons} optionLabel="name"
                                      placeholder="-- Select --" disabled={client === null}
                                      itemTemplate={contactPersonLabel} onChange={changeEventContactPerson}
                                      className={classNames({'p-invalid': submitted && !event.contactPerson})}
                                      valueTemplate={selectedContactPersonTemplate}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="manager">Manager</label>
                            <MultiSelect value={event.users} onChange={changeEventManager}
                                         options={users} panelHeaderTemplate={null}
                                         optionLabel={'firstName'} placeholder="-- Select --"
                                         filter filterBy="firstName" disabled={client === null}
                                         selectedItemTemplate={selectedManagerTemplate}
                                         itemTemplate={managerOptionTemplate}
                                         className={classNames({'p-invalid': submitted && !event.users})}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="date">Date</label>
                            <Calendar id="date" value={event.date ? new Date(event.date) : null} onChange={onDateChange}
                                      placeholder="Select a date" locale={'lt'}
                                      showTime showIcon required appendTo={document.body} dateFormat={'yy-mm-dd'}
                                      disabled={client === null}
                                      className={classNames({'p-invalid': submitted && !event.date})}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="notes">Notes</label>
                            <InputTextarea id="notes" value={event.notes} onChange={(e) => onInputChange(e, 'notes')}
                                           required autoFocus autoResize rows={5} cols={30}
                                           placeholder="Write a notes..."
                                           disabled={client === null}
                                           className={classNames({'p-invalid': submitted && !event.notes})}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="remainder">Remainder</label>
                            <InputNumber id="remainder" value={event.remainder} onChange={onRemainderChange}
                                         disabled={client === null}
                                         required autoFocus prefix="About Event get notification before "
                                         suffix=" minutes"
                                         className={classNames({'p-invalid': submitted && !event.remainder})}/>
                        </div>

                    </Dialog>
                </div>
            </div>
        </div>
    )
}
