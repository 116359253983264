import axios from "axios";

class AuthService {
    login(username, password) {
        return axios
            .post("/api/authenticate", {
                "password": password,
                "rememberMe": true,
                "username": username
            })
            .then(response => {
                if (response.data.id_token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout(history) {
        localStorage.removeItem("user");
        history.push("/login");
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
    }

    isCurrentUserInRole(role) {
        let user = this.getCurrentUser();
        if (!user || !user["id_token"]) {
            return false;
        }
        let tokenData = JSON.parse(this.parseJwt(user["id_token"]));
        return !((typeof tokenData.auth !== 'string') || !tokenData.auth.includes(role) || new Date().getTime() / 1000 > tokenData.exp);
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        return decodeURIComponent(Array.prototype.map.call(atob(base64Url), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    };

    resetPasswordInit(mail) {
        return axios
            .post("/api/account/reset-password/init", mail,
                {
                    headers: {
                        'Content-Type' : 'text/plain'
                    }
                })
            .then(response => {
                return response;
            });
    }
}

export default new AuthService();