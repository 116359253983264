import axios from 'axios'
import authHeader from "./auth-header";

export class ClientGroupsService {

    getClientGroups() {
        return axios.get('/api/client-group', { headers: authHeader() })
            .then(res => res.data);
    }

}