import axios from 'axios';
import authHeader from "./auth-header";

export class DocumentsService {

    download(documentId) {
        return axios.get(`/api/documents/${documentId}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

    deleteDocument(documentId) {
        return axios.delete(`/api/documents/${documentId}`, { headers: authHeader() })
            .then(res => res);
    }

    uploadDocuments(documentType, entityId, formData) {
        return axios.post(`/api/documents/${documentType}/${entityId}`, formData, {
            headers: authHeader(),
            'Content-Type': 'multipart/form-data'
        }).then(res => res);
    }

    getAllDocuments(documentType, entityId) {
        return axios.get(`/api/documents/${documentType}/${entityId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}