import axios from 'axios'
import authHeader from "./auth-header";

export class PortService {

    getPorts() {
        return axios.get('/api/port', { headers: authHeader() })
            .then(res => res.data);
    }

    savePort(port) {
        return axios.post('/api/port', port, { headers: authHeader() })
            .then(res => res.data);
    }

    deletePort(portId) {
        return axios.delete(`/api/port/${portId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    searchPorts(name) {
        return axios.get(`/api/port?name=${name}`, { headers: authHeader() })
            .then(res => res.data);
    }

}