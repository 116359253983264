import axios from 'axios'
import authHeader from "./auth-header";

export class ContainerTypesService {

    getContainerTypes() {
        return axios.get('/api/container-type', { headers: authHeader() })
            .then(res => res.data);
    }

    saveContainerType(containerType) {
        return axios.post('/api/container-type', containerType, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteContainerType(containerTypeId) {
        return axios.delete(`/api/container-type/${containerTypeId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}