import axios from 'axios'
import authHeader from "./auth-header";

export class CurrencyService {

    getCurrencies() {
        return axios.get('/api/currency', { headers: authHeader() })
            .then(res => res.data);
    }

    createCurrency(currency) {
        return axios.post('/api/currency', currency, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteCurrency(currencyId) {
        return axios.delete(`/api/currency/${currencyId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getExchangeRateByDate(currencyId, date) {
        return axios.get(`/api/currency-rates/for-date/${currencyId}?date=${date}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getTodayExchangeRateByCurr(currencyId) {
        return axios.get(`/api/currency-rates/${currencyId}/latest`, { headers: authHeader() })
            .then(res => res.data);
    }


}