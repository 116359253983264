import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {EventService} from "../service/EventService";
import {ColorPicker} from "primereact/colorpicker";
import {Dropdown} from "primereact/dropdown";
import {useMountEffect} from "primereact";

export const EventStatus = () => {

    let emptyEventStatus = {
        id: null,
        name: '',
        color: ''
    };

    const [eventStatuses, setEventStatuses] = useState(null);
    const [eventStatusDialog, setEventStatusDialog] = useState(false);
    const [deleteEventStatusDialog, setDeleteEventStatusDialog] = useState(false);
    const [eventStatus, setEventStatus] = useState(emptyEventStatus);
    const [selectedEventStatuses, setSelectedEventStatuses] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [color, setColor] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const eventService = new EventService();
    const defaultColors = [
        {label: 'Primary', value: '3F51B5'},
        {label: 'Secondary', value: 'ff4081'},
        {label: 'Success', value: '689F38'},
        {label: 'Info', value: '2196F3'},
        {label: 'Warning', value: 'FBC02D'},
        {label: 'Danger', value: 'D32F2F'},
    ]


    useMountEffect(() => {
        eventService.getEventStatuses()
            .then(data => {
                setEventStatuses(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setEventStatus(emptyEventStatus);
        setSubmitted(false);
        setEventStatusDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEventStatusDialog(false);
        setColor(null)
    }

    const hideDeleteEventTypeDialog = () => {
        setDeleteEventStatusDialog(false);
    }

    const saveEventType = () => {
        setSubmitted(true);

        if (eventStatus.name.trim()) {
            let _eventStatuses = [...eventStatuses];
            let _eventType = {...eventStatus};

            eventService.saveEventStatus(_eventType)
                .then(data => {
                    if (eventStatus.id) {
                        const index = findIndexById(eventStatus.id);
                        _eventStatuses[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Event Status Updated',
                            life: 3000
                        });
                    } else {
                        _eventStatuses.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Event Status Created',
                            life: 3000
                        });
                    }
                    setEventStatuses(_eventStatuses);
                    setEventStatusDialog(false);
                    setEventStatus(emptyEventStatus);
                    setColor(null)
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editEventType = (eventStatus) => {
        setEventStatus({...eventStatus});
        setEventStatusDialog(true);
    }

    const confirmDeleteEventType = (eventStatus) => {
        setEventStatus(eventStatus);
        setDeleteEventStatusDialog(true);
    }

    const deleteEventType = () => {
        eventService.deleteEventStatus(eventStatus.id)
            .then(data => {
                let _eventStatuses = eventStatuses.filter(val => val.id !== eventStatus.id);
                setEventStatuses(_eventStatuses);
                setDeleteEventStatusDialog(false);
                setEventStatus(emptyEventStatus);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Status Deleted',
                    life: 3000
                });
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eventStatuses.length; i++) {
            if (eventStatuses[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name === 'defaultColor') {
            name = 'color'
            setColor(val)
        } else if(name === 'color'){
            setColor(null)
        }
        let _eventStatus = {...eventStatus};
        _eventStatus[`${name}`] = val;

        setEventStatus(_eventStatus);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editEventType(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteEventType(rowData)}/>
            </div>
        );
    }

    const colorBodyTemplate = (rowData) => {
        let color = '#' + rowData.color;
        return <div style={{background: color, padding: '1.5rem', textAlign: 'center'}}>
            #{rowData.color}
        </div>
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Event Status</h5>
        </div>
    );

    const eventTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEventType}/>
        </>
    );
    const deleteEventTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEventTypeDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEventType}/>
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={eventStatuses} selection={selectedEventStatuses}
                               onSelectionChange={(e) => setSelectedEventStatuses(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} country directions"
                               emptyMessage="No event status found." header={header}>
                        <Column field="name" header="Title" sortable/>
                        <Column field="color" header="Color" body={colorBodyTemplate} sortable/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={eventStatusDialog} style={{width: '450px'}} header="Event Status Details" modal
                            className="p-fluid" footer={eventTypeDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="name">Title</label>
                                <InputText id="name" value={eventStatus.name}
                                           onChange={(e) => onInputChange(e, 'name')}
                                           required autoFocus
                                           className={classNames({'p-invalid': submitted && !eventStatus.name})}/>
                                {submitted && !eventStatus.name &&
                                <small className="p-invalid">Title is required.</small>}
                            </div>
                        <div className="grid">
                            <div className="p-field p-col">
                                <label htmlFor="name">Status color:</label>
                                <ColorPicker format="hex" value={eventStatus.color} defaultColor={'ffffff'}
                                             onChange={(e) => onInputChange(e, 'color')}/>
                            </div>
                            <div className="p-field p-col defaultColors">
                                <label htmlFor="name">Default style colors:</label><br/>
                                <Dropdown value={color} options={defaultColors} showClear={true} style={{height: '28px'}}
                                          onChange={(e) => onInputChange(e, 'defaultColor')}/>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEventStatusDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteEventTypeDialogFooter} onHide={hideDeleteEventTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {eventStatus && <span>Are you sure you want to delete <b>{eventStatus.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
