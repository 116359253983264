import axios from 'axios'
import authHeader from "./auth-header";

export class TradeTypesService {

    getTradeTypes() {
        return axios.get('/api/trade-type', { headers: authHeader() })
            .then(res => res.data);
    }

    // saveTradeType(tradeType) {
    //     return axios.post('/api/trade-type', tradeType, { headers: authHeader() })
    //         .then(res => res.data);
    // }
    //
    // deleteTradeType(tradeTypeId) {
    //     return axios.delete(`/api/trade-type/${tradeTypeId}`, { headers: authHeader() })
    //         .then(res => res.data);
    // }
}