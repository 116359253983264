import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ColumnGroup, Row} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {Toast} from "primereact/toast";
import {InvoicesService} from "../../../service/InvoicesService";
import {AutoComplete} from "primereact/autocomplete";
import {ClientService} from "../../../service/ClientService";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import nextId from "react-id-generator";
import moment from "moment";
import classNames from "classnames";
import {confirmDialog} from "primereact/confirmdialog";
import {DocumentsService} from "../../../service/DocumentsService";
import {FileUpload} from "primereact/fileupload";
import {TabPanel, TabView} from "primereact/tabview";
import {PaymentForm} from "../PaymentForm";

export const PurchaseForm = (props) => {

    const [load, setLoad] = useState(true)
    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [edit, setEdit] = useState(false)
    const [creditInvoice, setCreditInvoice] = useState(null)
    const [filteredClients, setFilteredClients] = useState(null);
    const [activatedEdit, setActivatedEdit] = useState(false);
    const [validate, setValidate] = useState(false);
    const [invoiceUpload, setInvoiceUpload] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [taxTypes, setTaxTypes] = useState([]);
    const [preLoad, setPreLoad] = useState(false)

    const invoicesService = new InvoicesService();
    const clientService = new ClientService();
    const documentsService = new DocumentsService();

    let {paramClientId, paramInvoiceId, paramBookingId, paramTab} = useParams();
    const toast = useRef(null);
    let history = useHistory();
    let emptyCreditInvoice = {
        client: null,
        currency: null,
        currencyRate: 0,
        dueDate: null,
        id: null,
        invoiceItems: null,
        invoiceStatus: 'ISSUED',
        invoiceType: 'PURCHASE',
        issueDate: null,
        number: '',
        vat: null,
        version: 0,
        locked: false,
        overrideEditPermission: false
    }
    let emptyCreditInvoiceService = {
        currency: null,
        id: 'Id_' + nextId(),
        price: "",
        quantity: "",
        service: "",
    }

    useEffect(() => {
        invoicesService.getTaxTypes().then(data => {
            if (!isNullOrUndef(data) && data.length > 0) {
                setTaxTypes(data)
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Tax Types',
                    detail: 'Please contact to your administrator!'
                });
            }
        })
        if (!isNullOrUndef(paramInvoiceId) && !isNaN(parseInt(paramInvoiceId))) {
            setEdit(true)
            getCreditInvoiceData(paramInvoiceId)
        } else {
            if (!isNullOrUndef(paramClientId) && !isNullOrUndef(paramInvoiceId) && paramInvoiceId === 'new') {
                clientService.getClientDTO(paramClientId).then(data => {
                    let _emptyCreditInvoice = emptyCreditInvoice
                    _emptyCreditInvoice.client = data
                    setCreditInvoice(_emptyCreditInvoice)
                    setLoad(false)
                })
            } else {
                setCreditInvoice(emptyCreditInvoice)
                setLoad(false)
            }

        }
    }, [paramInvoiceId])

    const closeAndBackCreditInvoice = () => {
        if (!isNullOrUndef(paramBookingId) && paramBookingId === '-1') {
            history.push(`/invoices/payments`);
        } else {
            history.push(`/invoices/purchases`);
        }
    }

    const prepareCreditInvoiceToSave = () => {
        let _creditInvoice = {...creditInvoice}
        _creditInvoice.issueDate = moment(_creditInvoice.issueDate).toDate()
        if (!isNullOrUndef(_creditInvoice.issuedBy)) {
            _creditInvoice.issuedBy = {id: _creditInvoice.issuedBy.id}
        }
        if (!isNullOrUndef(_creditInvoice.createdBy)) {
            _creditInvoice.createdBy = {id: _creditInvoice.createdBy.id}
        }
        _creditInvoice['clientId'] = null
        if (!isNullOrUndef(_creditInvoice.client)) {
            _creditInvoice['clientId'] = _creditInvoice.client.id
        }
        delete _creditInvoice.client

        _creditInvoice['bookingIds'] = []
        if (!isNullOrUndef(_creditInvoice.bookings) && _creditInvoice.bookings.length > 0) {
            _creditInvoice['bookingIds'] = _creditInvoice.bookings.map(b => b.id)
        }
        delete _creditInvoice.bookings

        _creditInvoice['currencyId'] = null
        if (!isNullOrUndef(_creditInvoice.currency)) {
            _creditInvoice['currencyId'] = _creditInvoice.currency.id
        }
        delete _creditInvoice.currency

        _creditInvoice['taxTypeId'] = null
        _creditInvoice['vatId'] = null
        if (!isNullOrUndef(_creditInvoice.taxType) && !isNullOrUndef(_creditInvoice.taxType.vat)) {
            _creditInvoice['taxTypeId'] = _creditInvoice.taxType.id
            _creditInvoice['vatId'] = _creditInvoice.taxType.vat.id;
        }
        delete _creditInvoice.taxType
        delete _creditInvoice.vat

        if (!isNullOrUndef(_creditInvoice.invoiceItems) && _creditInvoice.invoiceItems.length > 0) {
            _creditInvoice.invoiceItems.forEach(item => {
                if (!Number.isFinite(item.id)) {
                    item.id = null
                }
                item['invoiceItemTypeId'] = null
                delete item.invoiceItemType

                item['initialPlanCurrencyId'] = null
                delete item.initialPlanCurrency

                item['currencyId'] = null
                if (!isNullOrUndef(_creditInvoice.currency)) {
                    item['currencyId'] = item.currency.id
                }
                delete item.currency

                item['planCurrencyId'] = null
                if (!isNullOrUndef(_creditInvoice.planCurrency)) {
                    item['planCurrencyId'] = item.planCurrency.id
                }
                delete item.planCurrency

                item['bookingId'] = null
                if (!isNullOrUndef(_creditInvoice.booking)) {
                    item['bookingId'] = item.booking.id
                }
                delete item.booking
            })
        }

        if (!isNullOrUndef(_creditInvoice.invoicePayments) && _creditInvoice.invoicePayments.length > 0) {
            _creditInvoice.invoicePayments.forEach(payment => {
                if (!Number.isFinite(payment.id)) {
                    payment.id = null
                }
            })
        }

        return _creditInvoice
    }

    const validateInvoice = () => {
        return isNullOrUndef(creditInvoice.client) || creditInvoice.client === ''
            || isNullOrUndef(creditInvoice.number) || creditInvoice.number === ''
            || isNullOrUndef(creditInvoice.issueDate) || creditInvoice.issueDate === ''
            || isNullOrUndef(creditInvoice.currency) || creditInvoice.currency === ''
    }

    const saveAndBackCreditInvoice = () => {
        if (!validateInvoice()) {
            invoicesService.createInvoice(prepareCreditInvoiceToSave()).then((data) => {
                if(data.success) {
                    toast.current.show({severity: 'success', summary: 'Save invoice', detail: 'Invoice was saved'});
                    closeAndBackCreditInvoice()
                } else {
                    toast.current.show({severity: 'error', summary: 'Save invoice', detail: data.errorMessage});
                }
            })
        } else {
            setValidate(true)
            toast.current.show({severity: 'error', summary: 'Save invoice', detail: 'Please fill all required field!'});
        }
    }

    const saveAndEditCreditInvoice = () => {
        if (!validateInvoice()) {
            invoicesService.createInvoice(prepareCreditInvoiceToSave()).then(data => {
                if(data.success) {
                    if (!isNullOrUndef(paramInvoiceId) && paramInvoiceId === 'new') {
                        history.push(`/invoices/purchases/view/${data.data.id}`)
                    } else {
                        getCreditInvoiceData(data.data.id)
                    }
                    toast.current.show({severity: 'success', summary: 'Save invoice', detail: 'Invoice was saved!'});
                } else {
                    toast.current.show({severity: 'error', summary: 'Save invoice', detail: data.errorMessage});
                }
            })
        } else {
            setValidate(true)
            toast.current.show({severity: 'error', summary: 'Save invoice', detail: 'Please fill all required field!'});
        }
    }

    const getCreditInvoiceData = (invoiceId) => {
        invoicesService.getInvoice(invoiceId, 'PURCHASE')
            .then(data => {
                if (isNullOrUndef(data.issueDate)) {
                    data.issueDate = new Date()
                }
                setCreditInvoice(data)
                if (!isNullOrUndef(data.id)) {
                    documentsService.getAllDocuments(1, data.id).then(data => {
                        if (data.length > 0) {
                            setInvoiceUpload(data[0])
                        }
                    })
                }
                if(!isNullOrUndef(paramBookingId) && paramBookingId === '-1' && !isNullOrUndef(paramTab)){
                    setActiveIndex(Number(paramTab))
                }
                setLoad(false)
            });

    }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.getClientGroupId('office'))
                .then(data => {
                    setFilteredClients(data)
                });
        }
    }

    const onChange = (value, type) => {
        setCreditInvoice({
            ...creditInvoice,
            [type]: value
        });
    }

    const onRowEditChange = () => {

    }

    const onRowEditComplete = () => {

    }

    const addNewService = () => {
        if (isNullOrUndef(editingRowId)) {
            let _invoiceItems = !isNullOrUndef(creditInvoice.invoiceItems) ? [...creditInvoice.invoiceItems] : [];
            _invoiceItems.push(emptyCreditInvoiceService)
            setCreditInvoice({
                ...creditInvoice,
                ['invoiceItems']: _invoiceItems
            });
            setActiveRowIndex(emptyCreditInvoiceService.id)
        } else {
            warnAboutActiveEdit()
        }
    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       title={'Add New'} onClick={() => addNewService()}/>
    }

    const deleteService = (creditItemID) => {
        let _invoiceItems = [...creditInvoice.invoiceItems]
        _invoiceItems = _invoiceItems.filter(c => c.id !== creditItemID)
        setCreditInvoice({
            ...creditInvoice,
            ['invoiceItems']: _invoiceItems
        });
        setValidate(false)
        setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
    }

    const saveServiceRow = (data) => {
        if (data.price === '' || data.service === '' || data.quantity === '') {
            toast.current.show({
                severity: 'error',
                summary: 'Service',
                detail: 'Cannot be saved, please fill the required fields! '
            });
            setValidate(true)
        } else {
            setEditingRows({})
            setEditingRowId(null)
            setActivatedEdit(false)
        }
    }
    const setActiveRowIndex = (itemId) => {
        let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
        setActivatedEdit(true)
        setEditingRows(_editingRows);
        setEditingRowId(itemId)
    }

    const warnAboutActiveEdit = () => {
        return toast.current.show({
            severity: 'warn',
            summary: 'Edit',
            detail: 'Some edit is activated! Close active edit. '
        });
    }

    const confirmDelete = (data) => {
        let title = !isNullOrUndef(data.service) ? data.service : ''
        confirmDialog({
            message: `Are you sure you want to remove this [${title}] service ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteService(data.id),
            reject: () => null
        });
    }

    const actionTemplate = (data) => {
        return <div key={data.id} className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.id ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => saveServiceRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        title={'Edit'}
                        onClick={() => isNullOrUndef(editingRowId) && !activatedEdit ? setActiveRowIndex(data.id) : warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    title={'Delete'} onClick={() => confirmDelete(data)}/>
        </div>
    }

    const bookingIdBody = (rowData) => {
        let check = !isNullOrUndef(rowData.booking);
        return <Checkbox checked={check} style={{margin: '10px 5px'}}/>
    }

    const recalculateSums = () => {
        setPreLoad(true)
        let _invoiceDTO = prepareCreditInvoiceToSave()
        invoicesService.recalculate(_invoiceDTO)
            .then(data => {
                if (data.success) {
                    if (!isNullOrUndef(data.data.invoiceItems) && data.data.invoiceItems.length > 0) {
                        data.data.invoiceItems.forEach(p => {
                            if (isNullOrUndef(p.id)) {
                                p.id = 'Id_' + nextId()
                            }
                        })
                    }
                    if (!isNullOrUndef(data.data.invoicePayments) && data.data.invoicePayments.length > 0) {
                        data.data.invoicePayments.forEach(p => {
                            if (isNullOrUndef(p.id)) {
                                p.id = 'Id_' + nextId()
                            }
                        })
                    }
                    setCreditInvoice(data.data)
                    setPreLoad(false)
                } else {
                    toast.current.show({severity: 'error', summary: 'Recalculate invoice', detail: data.errorMessage});
                }
            })
    }

    const onEditorValueChange = (props, value) => {
        let {column, rowIndex, rowData} = props;
        let _invoiceItems = [...creditInvoice.invoiceItems]
        rowData[column.props.columnKey] = value;
        _invoiceItems[rowIndex] = rowData;

        setCreditInvoice({
            ...creditInvoice,
            ['invoiceItems']: _invoiceItems
        });
    }

    const textEditor = (options) => {
        return <div><InputText type="text" value={options.value} style={{width: '100%'}} required
                               onChange={(e) => onEditorValueChange(options, e.target.value)}
                               className={classNames({'p-invalid': validate && (options.value === '')})}/>
            {validate && options.value === '' &&
                <small className="p-invalid">Required</small>}
        </div>
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{textAlign: 'right'}} colSpan={3}/>
            <Column footer={!isNullOrUndef(creditInvoice) ? creditInvoice.totalSum : 0}/>
            <Column footer={!isNullOrUndef(creditInvoice) ? creditInvoice.totalVatSum : 0}/>
            <Column footer={!isNullOrUndef(creditInvoice) ? creditInvoice.totalSumWithVat : 0}/>
            <Column footer={null}/>
        </Row>
    </ColumnGroup>;

    const myUploader = (e) => {
        const formData = new FormData()
        if (e.files.length > 0) {
            formData.append('file', e.files[0])
        }
        documentsService.uploadDocuments(1, creditInvoice.id, formData).then(r => {
            if (r.status === 200) {
                setInvoiceUpload(r.data)
                toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
            }
        })
    }

    const deleteDocument = () => {
        documentsService.deleteDocument(invoiceUpload.id).then(r => {
            if (r.status === 200) {
                setInvoiceUpload(false)
                toast.current.show({severity: 'success', summary: 'Document was deleted!', life: 3000});
            }
        })
    }

    const confirmDeleteDocument = () => {
        confirmDialog({
            message: `Are you sure you want to delete this [${invoiceUpload.fileName}] document ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteDocument(),
            reject: () => null
        });
    }

    const downloadInvoice = () => {
        documentsService.download(invoiceUpload.id).then(r => {
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', invoiceUpload.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    const uploadInvoice = () => {
        if(isNullOrUndef(creditInvoice.id)){
            return;
        }
        if (invoiceUpload === false) {
            return <>
                <label htmlFor="uploadedAt:">Upload Invoice</label><br/>
                <FileUpload customUpload uploadHandler={myUploader} mode="advanced"
                            emptyTemplate={<p style={{textAlign: 'center'}}>Drag & Drop</p>}
                            accept="image/*,application/pdf" maxFileSize={15000000}/>
            </>
        } else {
            return <>
                <label htmlFor="uploadedAt:">Uploaded Invoice</label><br/>
                <span className="p-buttonset">
                        <Button label={invoiceUpload.fileName} icon="pi pi-download" onClick={downloadInvoice}/>
                        <Button style={{flex: 0.1}} className={'p-button-danger'} icon="pi pi-trash"
                                onClick={confirmDeleteDocument}/>
                    </span>
            </>
        }
    }

    const checkInvoice = () => {
        return isNullOrUndef(creditInvoice.number) || isNullOrUndef(creditInvoice.client) || isNullOrUndef(creditInvoice.currency)
            || isNullOrUndef(creditInvoice.id) || isNullOrUndef(creditInvoice.invoiceItems) || creditInvoice.invoiceItems.length === 0
    }

    return load ? '' : <div className="card">
        <Toast ref={toast}/>
        {preLoad ?
            <div className={'loader-container'}>
                <div className={'loader-clip'}/>
            </div> : ''}
        <h5>{edit ? 'Edit Office' : 'Add new Office'}</h5>
        <TabView activeIndex={activeIndex}
                 onTabChange={(e) => !activatedEdit ? setActiveIndex(e.index) : warnAboutActiveEdit()}>
            <TabPanel header="Invoice">
                <div className="p-grid">
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditClient">Client</label>
                                <AutoComplete id={'creditClient'} value={creditInvoice.client}
                                              suggestions={filteredClients}
                                              completeMethod={searchClient} showEmptyMessage field="title" delay={500}
                                              tooltip={'Min 3 characters'} minLength={3} placeholder="-- Select --"
                                              onChange={(e) => onChange(e.value, 'client')}
                                              className={classNames({'p-invalid': validate && isNullOrUndef(creditInvoice.client)})}/>
                                {validate && isNullOrUndef(creditInvoice.client) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditInvoiceNr">Invoice number</label>
                                <InputText id={'creditInvoiceNr'} value={creditInvoice.number}
                                           onChange={(e) => onChange(e.target.value, "number")}
                                           className={classNames({'p-invalid': validate && (isNullOrUndef(creditInvoice.number) || creditInvoice.number === '')})}/>
                                {validate && (isNullOrUndef(creditInvoice.number) || creditInvoice.number === '') &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditDateOfIssue">Date of issue</label>
                                <Calendar id="creditDateOfIssue" showIcon locale={'lt'}
                                          value={!isNullOrUndef(creditInvoice.issueDate) ? moment(creditInvoice.issueDate).toDate() : null}
                                          dateFormat={'yy-mm-dd'} onChange={(e) => onChange(e.value, 'issueDate')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(creditInvoice.issueDate)})}/>
                                {validate && isNullOrUndef(creditInvoice.issueDate) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditDueDate">Due Date</label>
                                <Calendar id="creditDueDate" showIcon
                                          value={!isNullOrUndef(creditInvoice.dueDate) ? moment(creditInvoice.dueDate).toDate() : null}
                                          dateFormat={'yy-mm-dd'} locale={'lt'}
                                          onChange={(e) => onChange(e.value, 'dueDate')}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="creditCurrency">Currency</label>
                                <Dropdown value={creditInvoice.currency} options={props.currencies}
                                          optionLabel={'code'} placeholder="-- Select --"
                                          onChange={(e) => onChange(e.value, 'currency')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(creditInvoice.currency)})}/>
                                {validate && isNullOrUndef(creditInvoice.currency) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="purchaseInvoiceTaxType">Invoice type</label><br/>
                                <Dropdown id={'purchaseInvoiceTaxType'} value={creditInvoice.taxType} options={taxTypes}
                                          optionLabel={'title'} placeholder="-- Select --"
                                          onChange={(e) => onChange(e.value, 'taxType')}
                                          className={classNames({'p-invalid': validate && isNullOrUndef(creditInvoice.taxType)})}/>
                                {validate && isNullOrUndef(creditInvoice.taxType) &&
                                    <small className="p-invalid">Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">

                            </div>
                        </div>
                    </div>
                    <div className="p-col-3">
                        <div className="p-fluid">
                            <div className="p-field">
                                {uploadInvoice()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'p-grid'}>
                    <div className={'p-col-12'}>
                        <DataTable value={creditInvoice.invoiceItems}
                                   footerColumnGroup={footerGroup}
                                   showGridlines responsiveLayout="scroll"
                                   size="small" editMode="row"
                                   dataKey="id" editingRows={editingRows}
                                   onRowEditChange={onRowEditChange}
                                   onRowEditComplete={onRowEditComplete}>

                            <Column columnKey={'service'} style={{width: '25%'}} field={'service'} header={'Service'}
                                    editor={(options) => textEditor(options)}/>
                            <Column columnKey={'quantity'} style={{width: '10%'}} field={'quantity'} header={'Qnt'}
                                    editor={(options) => textEditor(options)}/>
                            <Column columnKey={'price'} style={{width: '15%'}} field={'price'} header={'Price'}
                                    editor={(options) => textEditor(options)}/>
                            <Column columnKey={'sum'} style={{width: '15%'}} field={'sum'} header={'Total'}/>
                            <Column columnKey={'sumVat'} style={{width: '10%'}} field={'sumVat'} header={'VAT'}/>
                            <Column columnKey={'sumWithVat'} style={{width: '10%'}} field={'sumWithVat'} header={'VAT'}/>

                            <Column columnKey={'action'} headerClassName={'center'} header={headerTemplate}
                                    style={{width: '90px'}} body={actionTemplate}/>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Payments" disabled={checkInvoice()}>
                <PaymentForm toast={toast} invoice={creditInvoice} currencies={props.currencies}
                             warnAboutActiveEdit={warnAboutActiveEdit} recalcInvoice={recalculateSums}
                             activatedEdit={activatedEdit} setActivatedEdit={setActivatedEdit}/>
            </TabPanel>
        </TabView>
        <div style={{textAlign: 'right'}}>
            <Button label="Save & Edit" icon={'pi pi-save'} onClick={saveAndEditCreditInvoice}
                    disabled={activatedEdit} className="p-button-success mr-2 mb-2"/>
            <Button label="Save & Back" icon={'pi pi-history'} onClick={saveAndBackCreditInvoice}
                    disabled={activatedEdit} className="p-button-warning mr-2 mb-2"/>
            <Button label="Close & Don't Save" icon={'pi pi-ban'} onClick={closeAndBackCreditInvoice}
                    disabled={activatedEdit} className={'mr-2 mb-2'}/>
        </div>
        <div style={{textAlign: 'right', color: '#999', opacity: '0.3'}}>Version: {creditInvoice.version}</div>
    </div>;

}