import React from "react";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {MultiSelect} from "primereact/multiselect";
import classNames from "classnames";
import {isNullOrUndef} from "chart.js/helpers";

export const getWageRateField = (client, checkUserRole, onInputNumberChange) => {
    return checkUserRole(['ROLE_ADMIN']) ?
        <div className="p-field col-12 md:col-3">
            <label htmlFor="wageRate">Wage Rate (%)</label>
            <InputNumber id="wageRate" value={client.wageRate} useGrouping={false}
                         minFractionDigits={0} maxFractionDigits={2} mode={'decimal'}
                         onValueChange={(e) => onInputNumberChange(e, 'wageRate')}/>
        </div>
        : ''
}

export const getCreditTimeField = (client, submitted, required, onInputNumberChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.creditTime);
    return <div key={'creditTimeField'} className="p-field col-12 md:col-3">
        <label htmlFor="creditTime">Credit Time</label>
        <InputNumber id="creditTime" value={client.creditTime} required={required}
                     onValueChange={(e) => onInputNumberChange(e, 'creditTime')}
                     className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Credit time is required.</small>}
    </div>
}


export const getOurCreditTimeField = (client, submitted, required, onInputNumberChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('text', client.ourCreditTime);
    return <div key={'ourCreditTimeField'} className="p-field col-12 md:col-3">
        <label htmlFor="ourCreditTime">Our Credit Time</label>
        <InputNumber id="ourCreditTime" value={client.ourCreditTime} required={required}
                     onValueChange={(e) => onInputNumberChange(e, 'ourCreditTime')}
                     className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Our Credit time is required.</small>}
    </div>
}

export const getClientTypeField = (client, submitted, required, clientsTypes, onValueChange) => {
    return <div key={'clientTypeField'} className="p-field col-12 md:col-3 hidden">
        <label htmlFor="clientType">Client Type</label>
        <Dropdown id="clientType" value={client.clientTypeId}
                  onChange={(e) => onValueChange(e, 'clientTypeId')} required={required}
                  options={clientsTypes} optionValue={'id'} optionLabel={'title'} appendTo={document.body}
                  className={classNames({'p-invalid': required && submitted && isNullOrUndef(client.clientTypeId)})}/>
        {required && submitted && isNullOrUndef(client.clientTypeId) &&
            <small className="p-invalid">Client Type is required.</small>}
    </div>
}



export const getUsersField = (client, submitted, required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField) => {

    const managerValue = (user) => {
        if (user === null)
            return null

        let valueManager = []
        user.forEach(u => {
            if(!isNullOrUndef(u)) {
                let value = managers.find(m => m.id === u.id)
                valueManager.push(value)
            }
        })
        return valueManager;
    }
    let notValid = required && submitted && checkRequiredField('array', client.users);
    return <div key={'usersField'} className="p-field col-12 md:col-3">
        <label htmlFor="users">Manager</label>
        <MultiSelect id="users" value={managerValue(client.users)}
                     onChange={(e) => onValueChange(e, 'users')}
                     options={managers} required={required}
                     optionLabel="firstName" placeholder="-- Select --"
                     appendTo={document.body}
                     filter filterBy="firstName"
                     selectedItemTemplate={selectedManagerTemplate}
                     itemTemplate={managerOptionTemplate}
                     className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Manager is required.</small>}
    </div>
}

export const getSecondManagerField = (client, submitted, required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField) => {
        let notValid = required && submitted && checkRequiredField('array', client.secondManager);
        return <div key={'getSecondManagerField'} className="p-field col-12 md:col-3">
            <label htmlFor="secondManager">Second manager</label>
            <Dropdown id="secondManager" value={client.secondManager}
                      onChange={(e) => onValueChange(e, 'secondManager')}
                      options={managers} multiple={false} placeholder="-- Select --"
                      appendTo={document.body} optionLabel="firstName"
                      filter filterBy="firstName" showClear
                      valueTemplate={selectedManagerTemplate}
                      itemTemplate={managerOptionTemplate} required={required}
                      className={classNames({'p-invalid': notValid})}/>
            {notValid && <small className="p-invalid">Second Manager is required.</small>}
        </div>

}

export const getVendorManagerField = (client, submitted, required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('array', client.costsManager);
    return <div key={'costsManagerField'} className="p-field col-12 md:col-3">
        <label htmlFor="costsManager">Vendor manager</label>
        <Dropdown id="costsManager" value={client.costsManager}
                  onChange={(e) => onValueChange(e, 'costsManager')}
                  options={managers} multiple={false} placeholder="-- Select --"
                  appendTo={document.body} optionLabel="firstName"
                  filter filterBy="firstName" showClear
                  valueTemplate={selectedManagerTemplate}
                  itemTemplate={managerOptionTemplate} required={required}
                  className={classNames({'p-invalid': notValid})}/>
        {notValid && <small className="p-invalid">Cost Manager is required.</small>}
    </div>
}

export const getTradeTypesField = (client, submitted, required, tradeTypes, onValueChange, checkRequiredField) => {
    let notValid = required && submitted && checkRequiredField('array', client.tradeTypes);
    return client.id !== null ?
        <div key={'tradeTypesField'} className="p-field col-12 md:col-3">
            <label htmlFor="tradeTypes">Trade Types</label>
            <MultiSelect id="tradeTypes" value={client.tradeTypes}
                         onChange={(e) => onValueChange(e, 'tradeTypes')}
                         options={tradeTypes} showSelectAll={false}
                         optionLabel="name" placeholder="-- Select --"
                         appendTo={document.body} required={required}
                         className={classNames({'p-invalid': notValid})}/>
            {notValid && <small className="p-invalid">Trade Types is required.</small>}
        </div> : ''
}

