import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {ClientService} from "../service/ClientService";
import {Dialog} from "primereact/dialog";
import classNames from "classnames";

export const ClientContactPersons = (props) => {

    let emptyClientContactPerson = {
        id: null,
        name: '',
        surname: '',
        position: '',
        email: '',
        phone: '',
        fax: '',
        client: props.client,
        main: false
    };

    const [clientContactPersons, setClientContactPersons] = useState(null);
    const [selectedClientContactPerson, setSelectedClientContactPerson] = useState(null);
    const [clientContactPersonDialog, setClientContactPersonDialog] = useState(false);
    const [deleteClientContactPersonDialog, setDeleteClientContactPersonDialog] = useState(false);
    const [clientContactPerson, setClientContactPerson] = useState(emptyClientContactPerson);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const clientService = new ClientService();

    useEffect(() => {
        clientService.getClientContactPersons(props.client.id).then(data => {
            setClientContactPersons(data);
            setLoading(false)
        });
    }, [props.client]);

    const confirmDeleteClientContactPerson = (clientContactPerson) => {
        setClientContactPerson(clientContactPerson);
        setDeleteClientContactPersonDialog(true);
    }

    const openNew = () => {
        setClientContactPerson(emptyClientContactPerson);
        setSubmitted(false);
        setClientContactPersonDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClientContactPersonDialog(false);
    }

    const hideDeleteClientContactPersonDialog = () => {
        setDeleteClientContactPersonDialog(false);
    }

    const editClientContactPerson = (clientContactPerson) => {
        setClientContactPerson({...clientContactPerson});
        setClientContactPersonDialog(true);
    }

    const saveClientContactPerson = () => {
        setSubmitted(true);

        if (clientContactPerson.name.trim()) {
            let _clientContactPersons = [...clientContactPersons];

            if (clientContactPerson.id) {
                const index = findIndexById(clientContactPerson.id);
                clientService.setClientContactPerson(clientContactPerson).then(data => {
                    _clientContactPersons[index] = data;
                    setClientContactPersons(_clientContactPersons);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Client Contact Person Updated',
                        life: 3000
                    });
                });
            } else {
                clientService.setClientContactPerson(clientContactPerson).then(data => {
                    _clientContactPersons.push(data);
                    setClientContactPersons(_clientContactPersons);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Client Contact Person Created',
                        life: 3000
                    });
                });
            }

            setClientContactPersonDialog(false);
            setClientContactPerson(emptyClientContactPerson);
        }
    }

    const deleteClientContactPerson = () => {
        clientService.deleteClientContactPerson(clientContactPerson.id).then(data => {
            let _clientContactPersons = clientContactPersons.filter(val => val.id !== clientContactPerson.id);
            setClientContactPersons(_clientContactPersons);
            setDeleteClientContactPersonDialog(false);
            setClientContactPerson(emptyClientContactPerson);
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Client Contact Person Deleted',
                life: 3000
            });
        });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _clientContactPerson = {...clientContactPerson};
        _clientContactPerson[`${name}`] = val;

        setClientContactPerson(_clientContactPerson);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < clientContactPersons.length; i++) {
            if (clientContactPersons[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const tableHeader = (
        <div className="table-header">
            <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };


    const actionTemplate = (rowData) => (<>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2 p-mb-2"
                onClick={() => editClientContactPerson(rowData)}/>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2 p-mb-2"
                onClick={() => confirmDeleteClientContactPerson(rowData)}/>
    </>);

    const clientContactPersonDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveClientContactPerson}/>
        </>
    );

    const deleteClientContactPersonDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text"
                    onClick={hideDeleteClientContactPersonDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteClientContactPerson}/>
        </>
    );

    return (
        <div className="p-grid table-demo">
            <Toast ref={toast}/>
            <div className="p-col-12">
                <DataTable value={clientContactPersons} paginator className="p-datatable-customers" rows={10}
                           dataKey="id" rowHover selection={selectedClientContactPerson}
                           onSelectionChange={(e) => setSelectedClientContactPerson(e.value)}
                           emptyMessage="No contact persons found." loading={loading}
                           header={tableHeader}>
                    <Column field="name" header="Name" sortable body={bodyTemplate}/>
                    <Column field="surname" header="Surname" sortable body={bodyTemplate}/>
                    <Column field="position" header="Position" sortable body={bodyTemplate}/>
                    <Column field="email" header="Email" sortable body={bodyTemplate}/>
                    <Column field="phone" header="Phone" sortable body={bodyTemplate}/>
                    <Column field="fax" header="Fax" sortable body={bodyTemplate}/>
                    <Column s body={actionTemplate} bodyStyle={{textAlign: 'right'}}/>
                </DataTable>
            </div>
            <Dialog visible={clientContactPersonDialog} style={{width: '450px'}} header="Account Details" modal
                    className="p-fluid" footer={clientContactPersonDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={clientContactPerson.name} onChange={(e) => onInputChange(e, 'name')}
                               required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.name})}/>
                    {submitted && !clientContactPerson.name && <small className="p-invalid">Name is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="surname">Surname</label>
                    <InputText id="surname" value={clientContactPerson.surname}
                               onChange={(e) => onInputChange(e, 'surname')} required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.surname})}/>
                    {submitted && !clientContactPerson.surname &&
                    <small className="p-invalid">Surname is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="position">Position</label>
                    <InputText id="position" value={clientContactPerson.position}
                               onChange={(e) => onInputChange(e, 'position')} required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.position})}/>
                    {/*{submitted && !client.vatCode && <small className="p-invalid">VAT code is required.</small>}*/}
                </div>
                <div className="p-field">
                    <label htmlFor="email">Email</label>
                    <InputText id="email" value={clientContactPerson.email} onChange={(e) => onInputChange(e, 'email')}
                               required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.email})}/>
                    {/*{submitted && !client.city && <small className="p-invalid">City is required.</small>}*/}
                </div>
                <div className="p-field">
                    <label htmlFor="phone">Phone</label>
                    <InputText id="phone" value={clientContactPerson.phone} onChange={(e) => onInputChange(e, 'phone')}
                               required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.phone})}/>
                    {/*{submitted && !client.address && <small className="p-invalid">Address is required.</small>}*/}
                </div>
                <div className="p-field">
                    <label htmlFor="fax">Fax</label>
                    <InputText id="fax" value={clientContactPerson.fax} onChange={(e) => onInputChange(e, 'fax')}
                               required autoFocus
                               className={classNames({'p-invalid': submitted && !clientContactPerson.fax})}/>
                    {/*{submitted && !client.postalCode && <small className="p-invalid">Postal code is required.</small>}*/}
                </div>
            </Dialog>
            <Dialog visible={deleteClientContactPersonDialog} style={{width: '450px'}} header="Confirm" modal
                    footer={deleteClientContactPersonDialogFooter} onHide={hideDeleteClientContactPersonDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {clientContactPerson &&
                    <span>Are you sure you want to delete <b>{clientContactPerson.name + ' ' + clientContactPerson.surname}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}
