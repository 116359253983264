import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {UserService} from "../service/UserService";
import {MultiSelect} from "primereact/multiselect";
import {isNullOrUndef} from "chart.js/helpers";

export const Users = () => {

    let emptyUser = {
        id: null,
        login: '',
        firstName: '',
        lastName: '',
        email: '',
        imageUrl: '',
        activated: false,
        langKey: 'en',
        authorities: []
    };

    const [users, setUsers] = useState(null);
    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [authorities, setAuthorities] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const userService = new UserService();
        userService.getUsers().then(data => setUsers(data));
        userService.getAuthorities().then(data => setAuthorities(data));
    }, []);

    const openNew = () => {
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }

    const hideDeleteUsersDialog = () => {
        setDeleteUsersDialog(false);
    }

    const saveUser = () => {
        setSubmitted(true);

        if (user.login.trim()) {
            let _users = [...users];
            let _user = {...user};

            new UserService().saveUser(_user)
                .then(data => {
                    if (user.id) {
                        const index = findIndexById(user.id);
                        _users[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'User Updated',
                            life: 3000
                        });
                    } else {
                        _users.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'User Created',
                            life: 3000
                        });
                    }
                    setUsers(_users);
                    setUserDialog(false);
                    setUser(emptyUser);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editUser = (user) => {
        setUser({...user});
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUser(user);
        setDeleteUserDialog(true);
    }

    const deleteUser = () => {
        new UserService().deleteUser(user.id)
            .then(data => {
                if (!isNullOrUndef(data) && !data.success) {
                    setDeleteUserDialog(false);
                    setUser(emptyUser);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _users = users.filter(val => val.id !== user.id);
                    setUsers(_users);
                    setDeleteUserDialog(false);
                    setUser(emptyUser);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'User Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    // const confirmDeleteSelected = () => {
    //     setDeleteUsersDialog(true);
    // }

    const deleteSelectedUsers = () => {
        let _users = users.filter(val => !selectedUsers.includes(val));
        setUsers(_users);
        setDeleteUsersDialog(false);
        setSelectedUsers(null);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'Users Deleted', life: 3000});
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...user};
        _user[`${name}`] = val;

        setUser(_user);
    }

    // const onBooleanChange = (e, name) => {
    //     const val = e.checked;
    //     let _user = { ...user };
    //     _user[`${name}`] = val;
    //
    //     setUser(_user);
    // }

    const onAuthoritiesChange = (e) => {
        let _user = {...user};
        _user['authorities'] = e.value;
        setUser(_user);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
                {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} />*/}
            </React.Fragment>
        )
    }

    const loginBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Username</span>
                {rowData.login}
            </>
        );
    }

    const firstNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">First Name</span>
                {rowData.firstName}
            </>
        );
    }

    const lastNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Last Name</span>
                {rowData.lastName}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    // const activatedBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Activated</span>
    //             {rowData.activated}
    //         </>
    //     );
    // }
    //
    // const authoritiesBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Roles</span>
    //             {rowData.authorities}
    //         </>
    //     );
    // }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editUser(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteUser(rowData)}/>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Users</h5>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUser}/>
        </>
    );
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser}/>
        </>
    );
    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsersDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers}/>
        </>
    );
    const authoritiesItemTemplate = (option) => {
        return (
            <span>{option}</span>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={users} selection={selectedUsers}
                               onSelectionChange={(e) => setSelectedUsers(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                               emptyMessage="No users found." header={header} autoLayout={true}>
                        <Column field="login" header="Username" sortable body={loginBodyTemplate}/>
                        <Column field="firstName" header="First Name" sortable body={firstNameBodyTemplate}/>
                        <Column field="lastName" header="Last Name" sortable body={lastNameBodyTemplate}/>
                        <Column field="email" header="Email" sortable body={emailBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={userDialog} style={{width: '450px'}} header="User Details" modal
                            className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="authorities">Roles</label>
                            <MultiSelect id="authorities" value={user.authorities} options={authorities}
                                         itemTemplate={authoritiesItemTemplate} onChange={onAuthoritiesChange}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="login">Username</label>
                            <InputText id="login" value={user.login} onChange={(e) => onInputChange(e, 'login')}
                                       required autoFocus
                                       className={classNames({'p-invalid': submitted && !user.login})}/>
                            {submitted && !user.login && <small className="p-invalid">Username is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="firstName">First name</label>
                            <InputText id="firstName" value={user.firstName}
                                       onChange={(e) => onInputChange(e, 'firstName')} required autoFocus
                                       className={classNames({'p-invalid': submitted && !user.firstName})}/>
                            {submitted && !user.firstName &&
                            <small className="p-invalid">First name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="lastName">Last name</label>
                            <InputText id="lastName" value={user.lastName}
                                       onChange={(e) => onInputChange(e, 'lastName')} required autoFocus
                                       className={classNames({'p-invalid': submitted && !user.lastName})}/>
                            {submitted && !user.lastName && <small className="p-invalid">Last name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')}
                                       required autoFocus
                                       className={classNames({'p-invalid': submitted && !user.email})}/>
                            {submitted && !user.email && <small className="p-invalid">Email is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUserDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {user && <span>Are you sure you want to delete <b>{user.login}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsersDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteUsersDialogFooter} onHide={hideDeleteUsersDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                            {user && <span>Are you sure you want to delete the selected users?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
