import React, {Fragment, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import nextId from "react-id-generator";
import {isNullOrUndef} from "chart.js/helpers";
import {InputNumber} from "primereact/inputnumber";
import {AutoComplete} from "primereact/autocomplete";
import {ClientService} from "../../../service/ClientService";
import {confirmDialog} from "primereact";
import {Checkbox} from "primereact/checkbox";
import {InvoicesService} from "../../../service/InvoicesService";
import {useMountEffect} from "primereact/hooks";
import classNames from "classnames";

export const IncomeItemsModule = (props) => {

    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [invoiceItemsKey, setInvoiceItemsKey] = useState(1);
    const [filteredClients, setFilteredClients] = useState(null);
    const [standardVatId, setStandardVatId] = useState(null);
    const [valid, setValid] = useState(false);
    const clientService = new ClientService();
    const invoicesService = new InvoicesService();

    useMountEffect(() => {
        invoicesService.getVatList().then(r => {
            let vat = r.find(d => d.standard === true)
            setStandardVatId(vat.id)
        })
    })

    const addNewService = () => {
        if (isNullOrUndef(editingRowId)) {
            let _incomeItems = [...props.income.incomeItems]
            let newTempId = nextId()
            _incomeItems.push({
                currency: null,
                id: newTempId,
                invoiceItemType: null,
                planCurrency: null,
                initialPlanPrice: null,
                planPrice: null,
                price: null,
                quantity: null,
                service: '',
            })
            props.income.incomeItems = _incomeItems
            setActiveRowIndex(newTempId)
            setInvoiceItemsKey(invoiceItemsKey + 1)
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const confirmDelete = (invoiceItemId) => {
        confirmDialog({
            message: `Are you sure you want to remove this service ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteService(invoiceItemId),
            reject: () => null
        });
    }

    const deleteService = (invoiceItemId) => {
        let _incomeItems = [...props.income.incomeItems]
        _incomeItems = _incomeItems.filter(c => c.id !== invoiceItemId)
        props.income.incomeItems = _incomeItems
        setActiveRowIndex(null)
        props.setInvoiceTabKey(props.invoiceTabKey + 1)
        setInvoiceItemsKey(invoiceItemsKey + 1)

    }

    const setActiveRowIndex = (itemId) => {
        if (!isNullOrUndef(itemId)) {
            let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
            setEditingRows(_editingRows);
            setEditingRowId(itemId)
            props.setActivatedEdit(true)
        } else {
            setEditingRows({});
            setEditingRowId(null)
            props.setActivatedEdit(false)
        }
    }

    const onCellChange = (value, options) => {
        let {column, rowIndex, rowData} = options;
        let _incomeItems = [...props.income.incomeItems]
        if (column.props.columnKey === 'containerType') {
            rowData[column.props.columnKey] = props.containerTypes.find(c => c.id === value);
        } else {
            rowData[column.props.columnKey] = value;
        }
        _incomeItems[rowIndex] = rowData;
        props.income.incomeItems = _incomeItems
        props.setInvoiceTabKey(props.invoiceTabKey + 1)
        setInvoiceItemsKey(invoiceItemsKey + 1)
    }

    const onChangeInvoiceData = (e, element) => {
        if (typeof e !== "object") {
            props.income[element] = e
        } else {
            props.income[element] = !isNullOrUndef(e.value) ? e.value : e.target.value
        }

        props.setInvoiceTabKey(props.invoiceTabKey + 1)
        setInvoiceItemsKey(invoiceItemsKey + 1)
    }

    const onSelectInvoiceData = (e, element) => {
        props.changeInvoiceData(props.income.id, e.value, element)
        if (!isNullOrUndef(e.value) && !isNullOrUndef(e.value.creditTime) && e.value.creditTime > 0) {
            onChangeInvoiceData(e.value.creditTime, 'creditTime')
        } else {
            onChangeInvoiceData(30, 'creditTime')
        }
        setInvoiceItemsKey(invoiceItemsKey + 1)
        props.setInvoiceTabKey(props.invoiceTabKey + 1)
    }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.getClientGroupId('customer')).then(data => {
                setFilteredClients(data)
            });
        }
    }


    const textEditor = (options, required) => {
        return <Fragment>
            <div>
                <InputText type="text" value={!isNullOrUndef(options.value) ? options.value : ''}
                            style={{width: '100%'}} disabled={props.lockedInvoice}
                            onChange={(e) => onCellChange(e.target.value, options)} required={required}
                            className={required ? classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')}) : ''}/>
            </div>
            <div>
                {required && valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const numberEditor = (options, required) => {
        return <Fragment>
            <div>
                <InputNumber value={options.value} mode="decimal" min={1} max={100} inputStyle={{width: '40px'}}
                             disabled={props.lockedInvoice}
                             onValueChange={(e) => onCellChange(e.value, options)} required={required}
                             className={required ? classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')}) : ''}/>
            </div>
            <div>
                {required && valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const moneyEditor = (options, required, type) => {
        return <Fragment>
            <div>
                <InputNumber value={options.value} minFractionDigits={0} maxFractionDigits={4}
                             disabled={type === 'real' && props.lockedInvoice}
                             useGrouping={false} mode="decimal" inputStyle={{width: '115px'}}
                             onValueChange={(e) => onCellChange(e.value, options)} required={required}
                             className={required ? classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')}) : ''}/>
            </div>
            <div>
                {required && valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const currencyEditor = (options, required, type) => {
        return <Fragment>
            <div>
                <Dropdown value={options.value} options={props.currencies} disabled={type === 'real' && props.lockedInvoice}
                          optionLabel={'code'} placeholder="-- Select --"
                          onChange={(e) => onCellChange(e.value, options)} required={required}
                          className={required ? classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')}) : ''}/>
            </div>
            <div>
                {required && valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const saveServiceRow = (data) => {
        if (!Number.isFinite(data.id) && (isNullOrUndef(data.service) || data.service === '' || isNullOrUndef(data.quantity) || data.quantity === '' ||
            isNullOrUndef(data.planPrice) || data.planPrice === '' || isNullOrUndef(data.planCurrency) || data.planCurrency === '')) {
            setValid(true)
        } else {
            setValid(false)
            setEditingRows({})
            setEditingRowId(null)
            props.setActivatedEdit(false)
        }
    }

    const canDeleteService = (data) => {
        if (!isNullOrUndef(data.initialPlanPrice) && data.initialPlanPrice > 0 && !props.checkUserRole('ROLE_ADMIN')) {
            return false
        } else {
            return true
        }
    }

    const actionTemplate = (data) => {
        return <div key={data.id} className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.id ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => saveServiceRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        title={'Edit'} disabled={props.isClosed}
                        onClick={() => isNullOrUndef(editingRowId) && !props.activatedEdit ? setActiveRowIndex(data.id) : props.warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    title={'Delete'} disabled={!canDeleteService(data)} onClick={() => confirmDelete(data.id)}/>
        </div>
    }

    const onRowEditChange = (e) => {

    }

    const onRowEditComplete = (e) => {

    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       disabled={props.isClosed}
                       title={'Add New'} onClick={() => addNewService()}/>
    }

    const onChangeVat = (value) => {
        if (value) {
            props.income['vatId'] = standardVatId
            props.setInvoiceTabKey(props.invoiceTabKey + 1)
            setInvoiceItemsKey(invoiceItemsKey + 1)
        } else {
            props.income['vatId'] = null
            props.setInvoiceTabKey(props.invoiceTabKey + 1)
            setInvoiceItemsKey(invoiceItemsKey + 1)
        }

    }

    const incomeInvoiceHeader = () => {
        return <div>
            <label htmlFor={'incomeClient-' + props.income.id}>Customer: </label>
            <AutoComplete id={'incomeClient-' + props.income.id} value={props.income.client}
                          suggestions={filteredClients} disabled={props.isClosed || props.lockedInvoice}
                          completeMethod={searchClient} showEmptyMessage field="title"
                          tooltip={'Min 3 characters'} minLength={3} placeholder="-- Select --"
                          onChange={(e) => onChangeInvoiceData(e, 'client')}
                          onSelect={(e) => onSelectInvoiceData(e, 'client')}/>

            <label htmlFor={'incomeCreditTime-' + props.income.id} style={{marginLeft: "10px"}}>Credit Time: </label>
            <InputNumber id={'incomeCreditTime-' + props.income.id} mode="decimal" useGrouping={false}
                         value={props.income.creditTime} disabled={props.isClosed || props.lockedInvoice}
                         onChange={(e) => onChangeInvoiceData(e, 'creditTime')}/>

            <label htmlFor={'incomeInvoiceNr-' + props.income.id} style={{marginLeft: "10px"}}>Invoice Number: </label>
            <InputText id={'incomeInvoiceNr-' + props.income.id}
                       value={!isNullOrUndef(props.income.number) ? props.income.number : ''} disabled/>

            <label htmlFor={'incomeVat-' + props.income.id} style={{marginLeft: "10px"}}>VAT: </label>

            <Checkbox id={'incomeVat-' + props.income.id} style={{marginBottom: "10px", marginLeft: "10px"}}
                      checked={!isNullOrUndef(props.income.vatId) && props.income.vatId === standardVatId}
                      disabled={props.isClosed || props.lockedInvoice}
                      onChange={e => onChangeVat(e.checked, 'vatId')}/>
        </div>
    }

    const currencyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.currency))
            return

        return rowData.currency.code
    }

    const planCurrencyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.planCurrency))
            return

        return rowData.planCurrency.code
    }

    const initialPlanPriceTemplate = (rowData) => {
        if (isNullOrUndef(rowData.initialPlanPrice))
            return

        let currency = !isNullOrUndef(rowData.initialPlanCurrency) ? rowData.initialPlanCurrency.code : ''
        return rowData.initialPlanPrice + ' ' + currency
    }

    const rowClassNameTemplate = (data) => {
        if(data.id === editingRowId) {
            return 'boldClassRowEdit'
        }
    }

    return <DataTable value={props.income.incomeItems}
                      showGridlines responsiveLayout="scroll"
                      size="small" editMode="row"
                      rowClassName={rowClassNameTemplate}
                      header={incomeInvoiceHeader}
                      dataKey="id" editingRows={editingRows}
                      onRowEditChange={onRowEditChange}
                      onRowEditComplete={onRowEditComplete}>
        <Column columnKey={'service'} style={{width: '50%'}} field={'service'} header={'Service'}
                editor={(options) => textEditor(options, true)}/>
        <Column columnKey={'quantity'} field={'quantity'} style={{width: '40px'}} header={'Qnt'}
                editor={(options) => numberEditor(options, true)}/>
        <Column columnKey={'initialPlanPrice'} field={'initialPlanPrice'} header={'Plan'}
                body={initialPlanPriceTemplate} style={{width: '115px'}}/>
        <Column columnKey={'planPrice'} field={'planPrice'} header={'Plan'} style={{width: '115px', textDecoration: 'underline'}}
                editor={(options) => moneyEditor(options, true, 'plan')}/>
        <Column columnKey={'planCurrency'} field={'planCurrency'} style={{width: '135px'}} header={'Currency'}
                editor={(options) => currencyEditor(options, true, 'plan')} body={planCurrencyTemplate}/>
        <Column columnKey={'price'} field={'price'} header={'Real'} style={{width: '115px'}}
                editor={(options) => moneyEditor(options, false, 'real')}/>
        <Column columnKey={'currency'} field={'currency'} style={{width: '135px'}} header={'Currency'}
                editor={(options) => currencyEditor(options, false, 'real')} body={currencyTemplate}/>
        <Column columnKey={'action'} headerClassName={'center'} header={headerTemplate}
                style={{width: '100px'}} body={actionTemplate}/>
    </DataTable>
}
