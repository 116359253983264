import React, {useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import nextId from "react-id-generator";
import {AutoComplete} from "primereact/autocomplete";
import {CargoItemsService} from "../../../service/CargoItemsService";
import {isNullOrUndef} from "chart.js/helpers";
import {confirmDialog} from "primereact";
import classNames from "classnames";

export const CargoItemsModule = (props) => {

    const [cargosItemKey, setCargosItemKey] = useState(1);
    const [cargoItems, setCargoItems] = useState(null)
    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [valid, setValid] = useState(false);
    const cargoItemsService = new CargoItemsService();

    const addNewCargo = () => {
        if (isNullOrUndef(editingRowId) && !props.activatedEdit) {
            let _cargos = [...props.booking.cargoItems]
            let newTempId = nextId()
            _cargos.push({
                id: newTempId,
                title: '',
                bookings: []
            })
            setActiveRowIndex(newTempId)
            props.booking.cargoItems = _cargos
            setCargosItemKey(cargosItemKey + 1)
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const deleteCargo = (cargoId) => {
        let _cargos = [...props.booking.cargoItems]
        _cargos = _cargos.filter(c => c.id !== cargoId)
        props.booking.cargoItems = _cargos
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        setCargosItemKey(cargosItemKey + 1)
    }

    const onCellChange = (e, options) => {
        let {column, rowIndex, rowData} = options;
        let _cargoItems = [...props.booking.cargoItems]
        if (typeof e.value !== "object") {
            rowData[column.props.columnKey] = e.value;
            _cargoItems[rowIndex] = rowData;
        } else {
            setValid(false)
            props.setActivatedEdit(false)
            setEditingRows({})
            setEditingRowId(null)
        }
        props.booking.cargoItems = _cargoItems
        setCargosItemKey(cargosItemKey + 1)
    }

    const onCellSelect = (value, options) => {
        let _cargoItems = [...props.booking.cargoItems]
        _cargoItems[options.rowIndex] = value;
        props.booking.cargoItems = _cargoItems
        setValid(false)
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        setCargosItemKey(cargosItemKey + 1)
    }

    const searchCargoItems = (event) => {
        if (event.query.trim().length > 2) {
            cargoItemsService.searchCargoItems(event.query.toLowerCase()).then(data => setCargoItems(data));
        }
    }

    const autoCompleteEditor = (options) => {
        return <div><AutoComplete id={'cargoItemAutoComplete'} value={options.value} suggestions={cargoItems}
                                  completeMethod={searchCargoItems} showEmptyMessage field="title"
                                  tooltip={'Min 3 characters'}
                                  onChange={(e) => onCellChange(e, options)}
                                  onSelect={(e) => onCellSelect(e.value, options)}
                                  minLength={3} placeholder="-- Select --" required
                                  className={classNames({'p-invalid': valid && !Number.isFinite(options.id)})}/>
            {valid && !Number.isFinite(options.id) && <small className="p-invalid">Incorrect enter data.</small>}
        </div>
    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm p-ml-auto"
                       disabled={props.isClosed}
                       title={'Add New'} onClick={() => addNewCargo()}/>

    }

    const saveCargoItemsRow = (data) => {
        if (isNullOrUndef(data.title) || data.title === '' || !Number.isFinite(data.id)) {
            setValid(true)
        } else {
            setValid(false)
            props.setActivatedEdit(false)
            setEditingRows({})
            setEditingRowId(null)
        }
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: `Are you sure you want to remove this [${data.title}] cargo ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCargo(data.id),
            reject: () => null
        });
    }

    const actionTemplate = (data) => {
        return <div className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.id ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => saveCargoItemsRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        disabled={props.isClosed}
                        title={'Edit'}
                        onClick={() => isNullOrUndef(editingRowId) && !props.activatedEdit ? setActiveRowIndex(data.id) : props.warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    disabled={props.isClosed}
                    title={'Delete'} onClick={() => confirmDelete(data)}/>
        </div>
    }

    const cargosItemHeader = () => {
        return <div style={{padding: '0.8rem 0'}}>Cargos</div>
    }

    const onRowEditChange = (e) => {
    }

    const onRowEditComplete = (e) => {
    }

    const setActiveRowIndex = (itemId) => {
        let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
        props.setActivatedEdit(true)
        setEditingRows(_editingRows);
        setEditingRowId(itemId)
    }

    const titleBodyTemplate = (rowData) => {
        return rowData.title
    }

    const rowClassNameTemplate = (data) => {
        if (data.id === editingRowId) {
            return 'boldClassRowEdit'
        }
    }

    return <DataTable value={props.booking.cargoItems} header={cargosItemHeader()}
                      showGridlines responsiveLayout="scroll"
                      size="small" editMode="row" dataKey="id"
                      editingRows={editingRows} onRowEditChange={onRowEditChange}
                      onRowEditComplete={onRowEditComplete} rowClassName={rowClassNameTemplate}>
        <Column field={'title'} columnKey={'title'} header={'Title'} body={titleBodyTemplate}
                editor={(options) => autoCompleteEditor(options)}/>
        <Column header={headerTemplate} style={{width: '90px'}} body={actionTemplate}/>
    </DataTable>
}
