import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {ContainerTypesService} from '../service/ContainerTypesService';
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";

export const ContainerTypes = () => {

    let emptyContainerType = {
        id: null,
        value: '',
        teu: 0
    };

    const [containerTypes, setContainerTypes] = useState(null);
    const [containerTypeDialog, setContainerTypesDialog] = useState(false);
    const [deleteContainerTypesDialog, setDeleteContainerTypesDialog] = useState(false);
    const [containerType, setContainerType] = useState(emptyContainerType);
    const [selectedContainerType, setSelectedContainerType] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const containerTypesService = new ContainerTypesService();


    useMountEffect(() => {
        containerTypesService.getContainerTypes()
            .then(data => {
                setContainerTypes(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setContainerType(emptyContainerType);
        setSubmitted(false);
        setContainerTypesDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setContainerTypesDialog(false);
    }

    const hideDeleteContainerTypeDialog = () => {
        setDeleteContainerTypesDialog(false);
    }

    const saveContainerType = () => {
        setSubmitted(true);

        if (containerType.value.trim()) {
            let _containerTypes = [...containerTypes];
            let _containerType = {...containerType};

            containerTypesService.saveContainerType(_containerType)
                .then(data => {
                    if (containerType.id) {
                        const index = findIndexById(containerType.id);
                        _containerTypes[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'ContainerType Updated',
                            life: 3000
                        });
                    } else {
                        _containerTypes.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'ContainerType Created',
                            life: 3000
                        });
                    }
                    setContainerTypes(_containerTypes);
                    setContainerTypesDialog(false);
                    setContainerType(emptyContainerType);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editContainerType = (containerType) => {
        setContainerType({ ...containerType });
        setContainerTypesDialog(true);
    }

    const confirmDeleteContainerType = (containerType) => {
        setContainerType(containerType);
        setDeleteContainerTypesDialog(true);
    }

    const deleteContainerType = () => {
        containerTypesService.deleteContainerType(containerType.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteContainerTypesDialog(false);
                    setContainerType(emptyContainerType);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _containerTypes = containerTypes.filter(val => val.id !== containerType.id);
                    setContainerTypes(_containerTypes);
                    setDeleteContainerTypesDialog(false);
                    setContainerType(emptyContainerType);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'ContainerType Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < containerTypes.length; i++) {
            if (containerTypes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _containerType = { ...containerType };
        _containerType[`${name}`] = val;

        setContainerType(_containerType);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.value}
            </>
        );
    }

    const teuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">TEU</span>
                {rowData.teu}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editContainerType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteContainerType(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage ContainerTypes</h5>
        </div>
    );

    const containerTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveContainerType} />
        </>
    );
    const deleteContainerTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteContainerTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteContainerType} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={containerTypes} selection={selectedContainerType} onSelectionChange={(e) => setSelectedContainerType(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} container types"
                               emptyMessage="No Container Types found." header={header}>
                        {/*<Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>*/}
                        <Column field="value" header="Type" sortable body={titleBodyTemplate}/>
                        <Column field="teu" header="TEU" sortable body={teuBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={containerTypeDialog} style={{ width: '450px' }} header="ContainerType Details" modal className="p-fluid" footer={containerTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={containerType.value} onChange={(e) => onInputChange(e, 'value')} required autoFocus className={classNames({ 'p-invalid': submitted && !containerType.value })} />
                            {submitted && !containerType.value && <small className="p-invalid">Title is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="teu">TEU</label>
                            <InputText id="teu" value={containerType.teu} onChange={(e) => onInputChange(e, 'teu')} required autoFocus className={classNames({ 'p-invalid': submitted && !containerType.teu })} />
                            {submitted && !containerType.teu && <small className="p-invalid">Teu is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteContainerTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteContainerTypeDialogFooter} onHide={hideDeleteContainerTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {containerType && <span>Are you sure you want to delete <b>{containerType.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
