import axios from 'axios'
import authHeader from "./auth-header";

export class StatusService {

     getStatuses() {
        return axios.get('/api/status', { headers: authHeader() })
            .then(res => res.data);
    }

    saveStatus(status) {
        return axios.post('/api/status', status, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteStatus(statusId) {
        return axios.delete(`/api/status/${statusId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}