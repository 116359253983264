import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {CountryDirectionService} from '../service/CountryDirectionService';
import {isNullOrUndef} from "chart.js/helpers";

export const CountryDirections = () => {

    let emptyCountryDirection = {
        id: null,
        title: ''
    };

    const [countryDirections, setCountryDirections] = useState(null);
    const [countryDirectionDialog, setCountryDirectionDialog] = useState(false);
    const [deleteCountryDirectionDialog, setDeleteCountryDirectionDialog] = useState(false);
    const [countryDirection, setCountryDirection] = useState(emptyCountryDirection);
    const [selectedCountryDirections, setSelectedCountryDirections] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const countryDirectionService = new CountryDirectionService();
        countryDirectionService.getCountryDirections()
            .then(data => {
                setCountryDirections(data);
                setLoading(false);
            });
    }, []);

    const openNew = () => {
        setCountryDirection(emptyCountryDirection);
        setSubmitted(false);
        setCountryDirectionDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCountryDirectionDialog(false);
    }

    const hideDeleteCountryDirectionDialog = () => {
        setDeleteCountryDirectionDialog(false);
    }

    const saveCountryDirection = () => {
        setSubmitted(true);

        if (countryDirection.title.trim()) {
            let _countryDirections = [...countryDirections];
            let _countryDirection = {...countryDirection};

            new CountryDirectionService().saveCountryDirection(_countryDirection)
                .then(data => {
                    if (countryDirection.id) {
                        const index = findIndexById(countryDirection.id);
                        _countryDirections[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Direction Updated',
                            life: 3000
                        });
                    } else {
                        _countryDirections.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Direction Created',
                            life: 3000
                        });
                    }
                    setCountryDirections(_countryDirections);
                    setCountryDirectionDialog(false);
                    setCountryDirection(emptyCountryDirection);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editCountryDirection = (countryDirection) => {
        setCountryDirection({ ...countryDirection });
        setCountryDirectionDialog(true);
    }

    const confirmDeleteCountryDirection = (countryDirection) => {
        setCountryDirection(countryDirection);
        setDeleteCountryDirectionDialog(true);
    }

    const deleteCountryDirection = () => {
        new CountryDirectionService().deleteCountryDirection(countryDirection.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteCountryDirectionDialog(false);
                    setCountryDirection(emptyCountryDirection);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _countryDirections = countryDirections.filter(val => val.id !== countryDirection.id);
                    setCountryDirections(_countryDirections);
                    setDeleteCountryDirectionDialog(false);
                    setCountryDirection(emptyCountryDirection);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Country Direction Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < countryDirections.length; i++) {
            if (countryDirections[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _countryDirection = { ...countryDirection };
        _countryDirection[`${name}`] = val;

        setCountryDirection(_countryDirection);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editCountryDirection(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'}  onClick={() => confirmDeleteCountryDirection(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Country Directions</h5>
        </div>
    );

    const countryDirectionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCountryDirection} />
        </>
    );
    const deleteCountryDirectionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCountryDirectionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCountryDirection} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={countryDirections} selection={selectedCountryDirections} onSelectionChange={(e) => setSelectedCountryDirections(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} country directions"
                        emptyMessage="No country directions found." header={header}>
                        <Column field="title" header="Title" sortable body={titleBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={countryDirectionDialog} style={{ width: '450px' }} header="Country Direction Details" modal className="p-fluid" footer={countryDirectionDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={countryDirection.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !countryDirection.title })} />
                            {submitted && !countryDirection.title && <small className="p-invalid">Title is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCountryDirectionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDirectionDialogFooter} onHide={hideDeleteCountryDirectionDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {countryDirection && <span>Are you sure you want to delete <b>{countryDirection.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
