import axios from 'axios'
import authHeader from "./auth-header";

export class TransportModeService {

    getTransportModes() {
        return axios.get('/api/transport-mode', { headers: authHeader() })
            .then(res => res.data);
    }

    saveTransportMode(transportMode) {
        return axios.post('/api/transport-mode', transportMode, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteTransportMode(transportModeId) {
        return axios.delete(`/api/transport-mode/${transportModeId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}