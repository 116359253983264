import axios from 'axios'
import authHeader from "./auth-header";

export class CargoItemsService {

    getCargoItems() {
        return axios.get('/api/cargo-item', { headers: authHeader() })
            .then(res => res.data);
    }

    saveCargoItem(cargoItem) {
        return axios.post('/api/cargo-item', cargoItem, { headers: authHeader() })
            .then(res => res.data);
    }

    searchCargoItems(title) {
        return axios.get(`/api/cargo-item?title=${title}`, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteCargoItem(cargoItemId) {
        return axios.delete(`/api/cargo-item/${cargoItemId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}