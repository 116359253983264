import axios from 'axios';
import authHeader from "./auth-header";

export class EventService {

    getEvents(clientId) {
        return axios.get(`/api/event/client/${clientId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getAllUserEvents(userId) {
        return axios.get(`/api/event/user/${userId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getUnreadUserEvents(userId) {
        return axios.get(`/api/event/user/${userId}?unread=true`, { headers: authHeader() })
            .then(res => res.data);
    }

    setEvent(event) {
        return axios.post('/api/event/', event, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteEvent(eventId) {
        return axios.delete(`/api/event/${eventId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getEventTypes() {
        return axios.get('/api/event-type', { headers: authHeader() })
            .then(res => res.data);
    }

    saveEventType(eventType) {
        return axios.post('/api/event-type', eventType, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteEventType(eventTypeId) {
        return axios.delete(`/api/event-type/${eventTypeId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getEventStatuses() {
        return axios.get('/api/event-status', { headers: authHeader() })
            .then(res => res.data);
    }

    saveEventStatus(eventStatus) {
        return axios.post('/api/event-status', eventStatus, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteEventStatus(eventStatusId) {
        return axios.delete(`/api/event-status/${eventStatusId}`, { headers: authHeader() })
            .then(res => res.data);
    }

    getManagerReportsByEvents(userId, dateFrom, dateTo) {
        if(userId !== null && userId !== undefined){
            return axios.get(`/api/event/list?userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}`, { headers: authHeader() })
                .then(res => res.data);
        } else {
            return axios.get(`/api/event/list?dateFrom=${dateFrom}&dateTo=${dateTo}`, { headers: authHeader() })
                .then(res => res.data);
        }

    }
}