import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {InvoicesService} from "../../service/InvoicesService";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {useMountEffect} from "primereact/hooks";
import {ToggleButton} from "primereact/togglebutton";
import {InvoicesFilters} from "./InvoicesFilters";
import {MultiSelect} from "primereact/multiselect";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";

export const Payments = (props) => {

    const [paymentsList, setPaymentsList] = useState([])
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filter, setFilter] = useState(false);
    const [searchInvoices, setSearchInvoices] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [filterColumns, setFilterColumns] = useState([]);
    const [filters, setFilters] = useState('');
    const [totalSumWithVatByStandardCurrency, setTotalSumWithVatByStandardCurrency] = useState(0);
    const [totalSumByStandardCurrency, setTotalSumByStandardCurrency] = useState(0);
    const [totalSumWithVat, setTotalSumWithVat] = useState(0);
    const [leftToPayByStandardCurrency, setLeftToPayByStandardCurrency] = useState(0);
    const [leftToPay, setLeftToPay] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const invoicesService = new InvoicesService();
    const toast = useRef(null);
    const dt = useRef(null);
    const op = useRef(null);
    const history = useHistory();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    let loadLazyTimeout = null;

    const columns = [
        {key: 'number', header: 'Invoice number', field: 'number', width: '125px', order: 1, disabling: true, filter: true},
        {key: 'bookings', header: 'Ref.ID', field: 'bookings.id', width: '80px', order: 2, disabling: true, filter: true},
        {key: 'bookingDate', header: 'Booking ID', field: 'bookingDate', width: '115px', order: 3, disabling: true, filter: true},
        {key: 'status', header: 'Status', field: 'bookings.status', width: '80px', order: 4, disabling: true, filter: true},
        {key: 'client', header: 'Customer/Vendor', field: 'client.title', width: '200px', order: 5, disabling: true, filter: true},
        {key: 'manager', header: 'Manager', field: 'client.users', width: '200px', order: 5, disabling: true, filter: true},
        {key: 'tradeType', header: 'Imp/Exp', field: 'bookings.tradeType', width: '85px', order: 6, disabling: true, filter: true},
        {key: 'currency', header: 'Currency', field: 'currency.code', width: '85px', order: 7, disabling: true, filter: true},
        {key: 'totalSumWithVatByStandardCurrency', header: 'Total EUR', field: 'totalSumWithVatByStandardCurrency', width: '90px', order: 8, disabling: false, filter: false},
        {key: 'totalSumByStandardCurrency', header: 'Total EUR (No VAT)', field: 'totalSumByStandardCurrency', width: '150px', order: 9, disabling: false, filter: false},
        {key: 'totalSumWithVat', header: 'Total by invoice', field: 'totalSumWithVat', width: '125px', order: 10, disabling: false, filter: false},
        {key: 'leftToPayByStandardCurrency', header: 'Left EUR', field: 'leftToPayByStandardCurrency', width: '85px', order: 11, disabling: false, filter: false},
        {key: 'leftToPay', header: 'Left by invoice', field: 'leftToPay', width: '120px', order: 12, disabling: false, filter: false},
        {key: 'invoiceType', header: 'Type', field: 'invoiceType', width: '80px', order: 13, disabling: true, filter: true},
        {key: 'paid', header: 'Paid', field: 'paidType', width: '105px', order: 14, disabling: true, filter: true},
        {key: 'paidDate', header: 'Payment date', field: 'paidDate', width: '115px', order: 15, disabling: true, filter: true},
        {key: 'dueDate', header: 'Due date', field: 'dueDate', width: '115px', order: 16, disabling: true, filter: true},
        {key: 'issueDate', header: 'Issue date', field: 'issueDate', width: '115px', order: 17, disabling: true, filter: true},
    ];

    useMountEffect(() => {
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('payments-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('payments-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        setLoading(true);
        getInvoices()
        setFilterColumns(columns.filter(c => c.filter))
    })

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getInvoices()
            }, 350);
        }
    }, [lazyParams, filters]);

    const getInvoices = (exportToExcel) => {
        let filterParams = ''
        const columnsStorage = JSON.parse(window.localStorage.getItem('payments-datatable-columns'));
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage.sort((a, b) => a.order - b.order))
        } else {
            setSelectedColumns(columns.sort((a, b) => a.order - b.order))
            window.localStorage.setItem('payments-datatable-columns', JSON.stringify(columns))
        }
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('payments-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('payments-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('payments-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                let field = s.field === 'bookingDate' ? 'bookings.bookingDate' : s.field
                sortFields += field + ':' + s.order + ','
            })
            window.localStorage.setItem('payments-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                let field = s.field === 'bookingDate' ? 'bookings.bookingDate' : s.field
                sortFields += field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        if(exportToExcel){
            exportPaymentsToExcel(filterParams, sortFields)
        } else {
            getInvoicesWithParams(filterParams, sortFields)
        }
    }

    const exportPaymentsToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        setLoading(true)
        invoicesService.exportToExcel('', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'Payments_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getInvoicesWithParams = (filterParams, sortFields) => {
        invoicesService.getInvoices(lazyParams.page, lazyParams.rows, '', sortFields, filterParams)
            .then(data => {
                setTotalRecords(data.totalElements);
                setPaymentsList(data.content);
                setLoading(false);
                setLoadFirstTime(false)
                setSelectedCurrency(data.content.length > 0 && !isNullOrUndef(data.content[0].currency) ? data.content[0].currency.code : '')
            });
        invoicesService.getPaymentsSummary(filterParams, '')
            .then(data => {
                setTotalSumWithVatByStandardCurrency(data["totalSumWithVatByStandardCurrency"])
                setTotalSumByStandardCurrency(data["totalSumByStandardCurrency"])
                setTotalSumWithVat(data["totalSumWithVat"])
                setLeftToPayByStandardCurrency(data["leftToPayByStandardCurrency"])
                setLeftToPay(data["leftToPay"])
            })
    }

    const editInvoice = (invoice) => {
        if (invoice.invoiceType === 'CREDIT') {
            history.push(`/invoices/credit/view/${invoice.id}/${invoice.client.id}/-1/1`);
        } else if (invoice.invoiceType === 'DEBIT') {
            history.push(`/invoices/debit/view/${invoice.id}/-1/1`);
        }
    }


    const actionBodyTemplate = (rowData) => {
        let checkInvoice =  isNullOrUndef(rowData.number) || isNullOrUndef(rowData.client) || isNullOrUndef(rowData.currency)
                || isNullOrUndef(rowData.id) || isNullOrUndef(rowData.invoiceItems) || rowData.invoiceItems.length === 0
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" disabled={checkInvoice} onClick={() => editInvoice(rowData)} title={'Edit'}
                        className="p-button-rounded p-button-success p-button-text p-button-sm"/>
            </div>
        );
    }

    const goToBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const bookingsTemplate = (rowData) => {
        if (rowData.bookings.length > 0) {
            return rowData.bookings.map(b => {
                return <Button label={b.id} key={'booking' + b.id} className="p-button-link p-0 p-m-0"
                               style={{display: 'flex'}}
                               onClick={() => goToBooking(rowData.bookings[0].id)}/>
            })
        }
    }

    const bookingDateTemplate = (rowData) => {
        if (rowData.bookings.length > 0) {
            return rowData.bookings.map(b => {
                if(!isNullOrUndef(b.bookingDate)){
                    return <div
                        key={'bookingDate' + b.id}>{moment(b.bookingDate).format('YYYY-MM-DD')}</div>
                }
            })
        }
    }

    const statusesTemplate = (rowData) => {
        if (rowData.bookings.length > 0) {
            return rowData.bookings.map(b => {
                return <div key={b.id + '_status'}>{!isNullOrUndef(b.status) ? b.status.name : ''}</div>
            })
        }
    }

    const managerTemplate = (rowData) => {
        if (!isNullOrUndef(rowData.client.users) && rowData.client.users.length > 0) {
            let manager = rowData.client.users[0]
            return manager.firstName + ' ' + manager.lastName;
        }
    }

    const tradeTypeTemplate = (rowData) => {
        if (rowData.bookings.length > 0) {
            return rowData.bookings.map(b => {
                return <div key={b.id + '_trade'}>{b.tradeType.name}</div>
            })
        }
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('payments-filter-toggle', JSON.stringify(e.value))
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.key === col.key));
        setSelectedColumns(orderedSelectedColumns.sort((a, b) => a.order - b.order));
        window.localStorage.setItem('payments-datatable-columns', JSON.stringify(selectedColumns))
    }


    const getMultiClientGroupId = () => {
        let ids = [];
        ids.push(props.getClientGroupId('customer'))
        ids.push(props.getClientGroupId('vendor'))
        return ids;
    }

    const header = <>
        <div className="table-header">
            <h5 className="p-mb-2">Payments</h5>
        </div>
        <div className="flex justify-content-between flex-wrap card-container">
            <div className="flex align-items-center justify-content-center">
                <MultiSelect value={selectedColumns} optionDisabled={option => !option.disabling} options={columns} maxSelectedLabels={0}
                             placeholder="Select Item" id={'payments-selectColumns'} filter
                             optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                <ToggleButton checked={filter} onChange={onFilterToggle} className={'p-ml-3'}
                              onLabel="" offLabel="" onIcon="pi pi-filter-slash"
                              offIcon="pi pi-filter" aria-label="Filters"/>
                <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchInvoices(searchInvoices + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                        onClick={() => getInvoices(true)}/>
            </div>
        </div>
        <div>
            <InvoicesFilters loading={loading} filterColumns={filterColumns} filter={filter}
                             clientGroupId={getMultiClientGroupId()}
                             searchInvoices={searchInvoices} clearFilter={clearFilter} filterList={'payments'}
                             getInvoices={getInvoices} filters={filters} setFilters={setFilters}/>
        </div>
    </>;

    const dueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.dueDate))
            return

        return moment(rowData.dueDate).format("YYYY-MM-DD")
    }

    const paidNotPaidTemplate = (rowData) => {
        let type = 'Not paid'

        let color = '#E55E5E'
        if (rowData["paidType"] === 'PAID') {
            type = 'Paid'
            color = '#5ee55e'
        } else if (rowData["paidType"] === 'PARTIALLY_PAID') {
            type = 'Paid/Unpaid'
            color = '#e5a25e'
        }
        return <span style={{background: color, padding: '2px', width: '100%', display: 'block', textAlign: 'center'}}>
            {type}
        </span>
    }

    const issueDateTemplate = (rowData) => {
        if (isNullOrUndef(rowData.issueDate))
            return

        return moment(rowData.issueDate).format("YYYY-MM-DD")
    }

    const paidDateTemplate = (rowData) => {
        if(isNullOrUndef(rowData.invoicePayments) || rowData.invoicePayments.length === 0)
            return

        return rowData.invoicePayments.map(p => {
            return <div key={p.id}>{moment(p.date).format("YYYY-MM-DD")}</div>
        })
        // if (isNullOrUndef(rowData["paidDate"]) || rowData["paidType"] === 'NOT_PAID')
        //     return
        //
        // return moment(rowData["paidDate"]).format("YYYY-MM-DD")
    }

    const getBodyTemplate = (key, rowData) => {
        switch (key) {
            case 'number':
            case 'totalSumWithVatByStandardCurrency':
            case 'totalSumByStandardCurrency':
            case 'leftToPayByStandardCurrency':
            case 'invoiceType':
                return !isNullOrUndef(rowData[key]) ? rowData[key] : ''
            case 'totalSumWithVat':
            case 'leftToPay':
                return !isNullOrUndef(rowData[key]) && !isNullOrUndef(rowData.currency) ? rowData[key] + ' ' + rowData.currency.code : ''
            case 'currency':
                return !isNullOrUndef(rowData[key]) && !isNullOrUndef(rowData.currency) ? rowData.currency.code : ''
            case 'issueDate':
            case 'dueDate':
                return !isNullOrUndef(rowData[key]) ? moment(rowData[key]).format("YYYY-MM-DD") : ''
            case 'paidDate':
                return paidDateTemplate(rowData)
            case 'paid':
                return !isNullOrUndef(rowData.paidType) ? paidNotPaidTemplate(rowData) : ''
            case 'tradeType':
                return !isNullOrUndef(rowData.bookings) ? tradeTypeTemplate(rowData) : ''
            case 'client':
                return !isNullOrUndef(rowData.client) ? rowData.client.title : ''
            case 'manager':
                return !isNullOrUndef(rowData.client) ? managerTemplate(rowData) : ''
            case 'status':
                return !isNullOrUndef(rowData.bookings) ? statusesTemplate(rowData) : ''
            case 'bookings':
                return !isNullOrUndef(rowData.bookings) ? bookingsTemplate(rowData) : ''
            case 'bookingDate':
                return !isNullOrUndef(rowData.bookings) ? bookingDateTemplate(rowData) : ''
            default:
                return !isNullOrUndef(rowData[key]) ? rowData[key] : ''
        }
    }

    const getFooterWidthSpace = () => {
        let widthColumns = 0
        selectedColumns.forEach(s => {
            if((s.order > 0 && s.order < 8)){
                widthColumns += Number(s.width.replace("px", ""));
            }
        })
        return widthColumns
    }

    const getColWidth = (col) => {
      let column = columns.find(c => c.field === col)
        return column.width
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="" footerStyle={{textAlign: 'right', padding: 0, width:getFooterWidthSpace()}}/>
            <Column footer={totalSumWithVatByStandardCurrency} footerStyle={{textAlign: 'center', width:getColWidth('totalSumWithVatByStandardCurrency')}}/>
            <Column footer={totalSumByStandardCurrency} footerStyle={{textAlign: 'center', width:getColWidth('totalSumByStandardCurrency')}}/>
            <Column footer={!isNullOrUndef(totalSumWithVat) ? totalSumWithVat + ' ' + selectedCurrency : 0} footerStyle={{textAlign: 'center', width:getColWidth('totalSumWithVat')}}/>
            <Column footer={leftToPayByStandardCurrency}  footerStyle={{textAlign: 'center', width:getColWidth('leftToPayByStandardCurrency')}}/>
            <Column footer={!isNullOrUndef(leftToPay) ? leftToPay + ' ' + selectedCurrency : 0}  footerStyle={{textAlign: 'center', width:getColWidth('leftToPay')}}/>
            <Column colSpan={4} />
        </Row>
    </ColumnGroup>;

    const columnComponents = selectedColumns.map(col => {
        return <Column style={{minWidth: col.width, width: col.width, flexDirection: 'column'}} key={col.key} field={col.field} sortable={false}
                       header={<LazySortMultiFields header={col.header} fieldKey={col.key} crud={'payments'}
                                                    field={col.field} lazyParams={lazyParams}
                                                    setLazyParams={setLazyParams}/>}
                       body={rowData => getBodyTemplate(col.key, rowData)}/>;
    });

    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                <DataTable ref={dt} value={paymentsList}
                           footerColumnGroup={footerGroup} size="small"
                           dataKey="id" paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                           onPage={(e) => setLazyParams(e)} totalRecords={totalRecords} lazy
                           stateStorage="local" scrollable
                           responsiveLayout="scroll"
                           scrollHeight="flex" id={'payments-list'}
                           stateKey="payments-dt-state-local"
                           className="datatable-responsive" loading={loading} first={lazyParams.first} rowHover
                           rows={lazyParams.rows}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                           emptyMessage="No items found." header={header}>
                    {columnComponents}
                    <Column body={actionBodyTemplate} style={{background: 'white'}}
                            frozen alignFrozen={'right'} bodyStyle={{textAlign: 'right'}}
                            hidden={!props.checkUserRole('ROLE_ADMIN')}/>
                </DataTable>
                </div>
            </div>
        </div>
    );

}