import nextId from "react-id-generator";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {Fragment, useEffect, useState} from "react";
import {Button} from "primereact/button";
import {isNullOrUndef} from "chart.js/helpers";
import {AutoComplete} from "primereact/autocomplete";
import {ClientService} from "../../../service/ClientService";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InvoicesService} from "../../../service/InvoicesService";
import classNames from "classnames";
import {confirmDialog} from "primereact";
import {useHistory} from "react-router-dom";

export const CostTab = (props) => {

    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [costItemsKey, setCostItemsKey] = useState(1);
    const [filteredClients, setFilteredClients] = useState(null);
    const [filteredInvoices, setFilteredInvoices] = useState(null)
    const [valid, setValid] = useState(false);
    const [showPriceByStandardCurrency, setShowPriceByStandardCurrency] = useState(false);


    const clientService = new ClientService();
    const invoicesService = new InvoicesService();
    const JSum = require('jsum')
    let history = useHistory();

    const emptyCostData = {
        currency: null,
        id: null,
        invoiceClient: null,
        invoiceId: null,
        tempId: 'Id_' + nextId(),
        costItemId: null,
        invoiceItemType: null,
        invoiceNumber: '',
        initialPlanCurrency: null,
        initialPlanPrice: '',
        planCurrency: '',
        planPrice: '',
        price: null,
        quantity: null,
        service: '',
        sum: null
    }

    useEffect(() => {
        setPriceByStandard()
    }, [props.booking.costs])

    const setPriceByStandard = () => {
        if (!isNullOrUndef(props.booking.costs) && props.booking.costs.length > 0) {
            let multiCurrency = props.booking.costs.filter(c => !isNullOrUndef(c.currency) ? c.currency.code !== 'EUR' : null)
            setShowPriceByStandardCurrency(multiCurrency.length > 0)
        }
    }

    const addNewService = () => {
        if (isNullOrUndef(editingRowId)) {
            let _costs = [...props.booking.costs]
            _costs.push(emptyCostData)
            props.booking.costs = _costs
            setActiveRowIndex(emptyCostData.tempId)
            setCostItemsKey(costItemsKey + 1)
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       disabled={props.isClosedIncomeCostTab}
                       title={'Add New'} onClick={() => addNewService()}/>
    }

    const deleteService = (costId) => {
        let _costs = [...props.booking.costs]
        _costs = _costs.filter(c => c.tempId !== costId)
        props.booking.costs = _costs
        setValid(false)
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        setCostItemsKey(costItemsKey + 1)
    }

    const deleteInvoice = (costId) => {
        let _costs = [...props.booking.costs]
        let index = findIndexById(costId, _costs)
        props.booking.costs[index].invoiceId = null
        props.booking.costs[index].id = null
        setValid(false)
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        setCostItemsKey(costItemsKey + 1)
    }

    const findIndexById = (id, obj) => {
        let index = -1;
        for (let i = 0; i < obj.length; i++) {
            if (obj[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const saveServiceRow = (data) => {
        if(isNullOrUndef(data.invoiceClient) || typeof data.invoiceClient !== "object" || isNullOrUndef(data.planCurrency) || data.planCurrency === ''
            || isNullOrUndef(data.planPrice) || data.planPrice === ''){
            setValid(true)
        } else {
            setValid(false)
            props.setActivatedEdit(false)
            setEditingRows({})
            setEditingRowId(null)
        }
    }
    const setActiveRowIndex = (itemId) => {
        let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
        props.setActivatedEdit(true)
        setEditingRows(_editingRows);
        setEditingRowId(itemId)
    }

    const canDeleteService = (data) => {
        if(!isNullOrUndef(data.initialPlanPrice) && data.initialPlanPrice > 0 && !props.checkUserRole('ROLE_ADMIN')){
            return false
        } else {
            return true
        }
    }

    const actionTemplate = (data) => {
        return <div key={data.tempId} className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.tempId ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => saveServiceRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        disabled={props.isClosedIncomeCostTab}
                        title={'Edit'} onClick={() => isNullOrUndef(editingRowId) && !props.activatedEdit ? setActiveRowIndex(data.tempId) : props.warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    title={'Delete'} disabled={!canDeleteService(data)} onClick={() => confirmDelete(data)}/>
        </div>
    }

    const onRowEditChange = (e) => {

    }

    const onRowEditComplete = (e) => {

    }

    const confirmDelete = (data) => {
        let title = !isNullOrUndef(data.invoiceClient) ? data.invoiceClient.title : ''
        confirmDialog({
            message: `Are you sure you want to remove this [${title}] vendor ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteService(data.tempId),
            reject: () => null
        });
    }

    const confirmDeleteInvoice = (data) => {
        confirmDialog({
            message: `Are you sure you want to remove this [${data.invoiceNumber}] invoice ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteInvoice(data.id),
            reject: () => null
        });
    }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.getClientGroupId('vendor')).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const searchInvoice = (event, client) => {
        let clientId = null
        if (!isNullOrUndef(client)) {
            clientId = client.id
        }
        if (event.query.trim().length > 2 && clientId) {
            invoicesService.searchInvoice(clientId, event.query.trim().toLowerCase()).then(data => {
                let uniqueData = [...new Map(data.map(item =>
                    [item['id'], item])).values()];
                if (!isNullOrUndef(data) && data.length > 0) {
                    uniqueData.forEach(d => {
                        if (!isNullOrUndef(d.invoiceItems) && d.invoiceItems.length > 0) {
                            d.invoiceItems.forEach(inv => {
                                inv['invoiceId'] = d.id
                                inv['invoiceNumber'] = d.number
                            })
                        }
                    })
                }
                setFilteredInvoices(uniqueData)
            });
        }
    }

    const onCellChange = (value, options) => {
        let {column, rowIndex, rowData} = options;
        let _costs = [...props.booking.costs]
        rowData[column.props.columnKey] = value;
        _costs[rowIndex] = rowData;
        props.booking.costs = _costs
        setCostItemsKey(costItemsKey + 1)
    }

    const onAutocompleteChange = (e, options) => {
        let {column, rowIndex, rowData} = options;
        let _costs = [...props.booking.costs]
        if (typeof e.value !== "object") {
            rowData[column.props.columnKey] = e.value;
            _costs[rowIndex] = rowData;
        }
        props.booking.costs = _costs
        setCostItemsKey(costItemsKey + 1)
    }


    const onCellSelect = (value, options) => {
        let _costs = [...props.booking.costs]
        _costs[options.rowIndex][options.column.props.columnKey] = value;
        props.booking.costs = _costs
        setCostItemsKey(costItemsKey + 1)
    }

    const onInvoiceSelect = (value, options) => {
        let _costs = [...props.booking.costs]
        _costs[options.rowIndex].invoiceId = value.invoiceId;
        _costs[options.rowIndex].invoiceNumber = value.invoiceNumber;
        _costs[options.rowIndex].invoiceItemType = value.invoiceType;
        _costs[options.rowIndex].service = value.service;
        _costs[options.rowIndex].sum = value.sum;
        _costs[options.rowIndex].id = value.id;
        _costs[options.rowIndex].price = value.price;
        _costs[options.rowIndex].quantity = value.quantity;
        _costs[options.rowIndex].currency = value.currency;
        _costs[options.rowIndex].priceByStandardCurrency = value.priceByStandardCurrency;
        props.booking.costs = _costs
        saveServiceRow(_costs[options.rowIndex])
        setCostItemsKey(costItemsKey + 1)
    }

    const vendorEditor = (options) => {
        return <Fragment>
            <div>
                <AutoComplete id={'costVendorAutoComplete'} value={options.value} suggestions={filteredClients}
                               completeMethod={searchClient} showEmptyMessage field="title"
                               tooltip={'Min 3 characters'} inputStyle={{width: '100%'}} style={{width: '100%'}}
                               onChange={(e) => onAutocompleteChange(e, options)}
                               onSelect={(e) => onCellSelect(e.value, options)}
                               minLength={3} placeholder="-- Select --" required
                               className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || typeof options.value !== "object")})}/>
            </div>
            <div style={{textAlign: 'left'}}>
                {valid && (isNullOrUndef(options.value) || typeof options.value !== "object")
                    && <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const groupedItemTemplate = (item) => {
        return <div style={{fontWeight: "bold", margin: "5px 5px"}}>
            {item.number}
        </div>
    }

    const itemTemplate = (item) => {
        return <div>{item.service}</div>
    }

    const invoiceEditor = (options) => {
        return <AutoComplete id={'costInvoiceAutoComplete'} value={options.value} suggestions={filteredInvoices}
                             completeMethod={e => searchInvoice(e, options.rowData.invoiceClient)} showEmptyMessage
                             field={'number'} optionGroupLabel={'service'} optionGroupChildren={'invoiceItems'}
                             tooltip={'Min 3 characters'} optionGroupTemplate={groupedItemTemplate} minLength={3}
                             onChange={(e) => onAutocompleteChange(e, options)}
                             onSelect={(e) => onInvoiceSelect(e.value, options)} style={{width: '100%'}}
                             inputStyle={{width: '100%'}} itemTemplate={itemTemplate} placeholder="-- Select --"/>
    }

    const vendorBodyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.invoiceClient))
            return

        return rowData.invoiceClient.title
    }

    const textEditor = (options) => {
        return <Fragment>
            <div>
                <InputText type="text" value={!isNullOrUndef(options.value) ? options.value : ''}
                            style={{width: '100%'}}
                            onChange={(e) => onCellChange(e.target.value, options)} required
                            className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')})}/>
            </div>
            <div style={{textAlign: 'left'}}>
                {valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const currencyEditor = (options) => {
        return <Fragment>
            <div>
                <Dropdown value={options.value} options={props.currencies} style={{width: '100%'}}
                          optionLabel={'code'} placeholder="-- Select --"
                          onChange={(e) => onCellChange(e.value, options)} required
                          className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')})}/>
            </div>
            <div style={{textAlign: 'left'}}>
                {valid && (isNullOrUndef(options.value) || options.value === '') &&
                    <small className="p-invalid">Required</small>}
            </div>
        </Fragment>
    }

    const currencyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.currency) || isNullOrUndef(rowData.invoiceId) || rowData.invoiceId === '')
            return

        return rowData.currency.code
    }
    const planCurrencyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.planCurrency))
            return

        return rowData.planCurrency.code
    }

    const serviceTemplate = (rowData) => {
        if (isNullOrUndef(rowData.planCurrency) || isNullOrUndef(rowData.invoiceId) || rowData.invoiceId === '')
            return

        return rowData.service
    }

    const realTemplate = (rowData) => {
        if (isNullOrUndef(rowData.planCurrency) || isNullOrUndef(rowData.invoiceId) || rowData.invoiceId === '')
            return

        return rowData.price
    }

    const eurTemplate = (rowData) => {
        if (isNullOrUndef(rowData.priceByStandardCurrency) || isNullOrUndef(rowData.invoiceId) || rowData.invoiceId === '')
            return

        return rowData.priceByStandardCurrency
    }

    const redirectToInvoice = (invoiceClientId) => {
        if(JSum.digest(props.booking, 'SHA256', 'hex') !== props.checkSum) {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Save booking',
                detail: 'Some data are changed! Please save booking first.'
            })
        } else {
            history.push(`/invoices/credit/view/new/${invoiceClientId}/${props.booking.id}/2`)
        }
    }

    const invoiceNumberTemplate = (rowData) => {
        if (isNullOrUndef(rowData.invoiceId) || rowData.invoiceId === '')
            return <div className="flex justify-content-center flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <Button label="Add new" className="p-button-link" disabled={props.isClosedIncomeCostTab}
                            onClick={() => redirectToInvoice(rowData.invoiceClient.id)} />
                </div>
                <div className="flex align-items-center justify-content-center w-1rem">|</div>
                <div className="flex align-items-center justify-content-center">
                    <Button label="Search" className="p-button-link" disabled={props.isClosedIncomeCostTab}
                            onClick={() => setActiveRowIndex(rowData.tempId)} />
                </div>
            </div>

        return <div>
            {rowData.invoiceNumber}
            <Button icon="pi pi-trash" className="p-button-text p-button-sm"
                    disabled={props.isClosedIncomeCostTab}
                    title={'Delete'} onClick={() => confirmDeleteInvoice(rowData)}/>
        </div>
    }

    const rowClassNameTemplate = (data) => {
        if(!isNullOrUndef(data.id) && data.id === editingRowId) {
            return 'boldClassRowEdit'
        } else if(data.tempId === editingRowId){
            return 'boldClassRowEdit'
        }
    }

    return <div className="card">
        <div className="flex card-container">
            <div className="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-left h-4rem">
                <b>Plan</b>: <span title={'Plan'}><u>{props.booking.costsPlan}</u></span> <span title={'initial Plan'}>({props.booking.costsInitialPlan})</span> {props.defaultCurrency.code}
            </div>
            <div className="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-left h-4rem">
                <b>Real</b>: {props.booking.costsReal} {props.defaultCurrency.code}
            </div>
        </div>
        <DataTable value={props.booking.costs}
                   showGridlines responsiveLayout="scroll"
                   size="small" editMode="row"
                   rowClassName={rowClassNameTemplate}
                   dataKey="tempId" editingRows={editingRows}
                   onRowEditChange={onRowEditChange}
                   onRowEditComplete={onRowEditComplete}>

            <Column columnKey={'invoiceClient'} style={{width: '150px'}} field={'invoiceClient'} header={'Vendor'}
                    body={vendorBodyTemplate} editor={(options) => vendorEditor(options)}/>

            <Column columnKey={'initialPlanPrice'} field={'initialPlanPrice'} header={'Plan'}
                    style={{width: '100px', textAlign:'center'}}/>

            <Column columnKey={'planPrice'} style={{width: '100px', textAlign:'center', textDecoration: 'underline'}}
                    field={'planPrice'} header={'Plan'} editor={(options) => textEditor(options)}/>

            <Column columnKey={'planCurrency'} field={'planCurrency'} style={{width: '100px', textAlign:'center'}} header={'Currency'}
                    editor={(options) => currencyEditor(options)} body={planCurrencyTemplate}/>

            <Column columnKey={'invoiceNumber'} style={{width: '120px', textAlign:'center'}} field={'invoiceNumber'} header={'Invoice'}
                    body={invoiceNumberTemplate} editor={(options) => invoiceEditor(options)}/>

            <Column columnKey={'service'} style={{width: '250px'}} field={'service'}
                    header={'Service'} body={serviceTemplate}/>

            <Column columnKey={'price'} style={{width: '100px', textAlign:'center'}}
                    field={'price'} header={'Real'} body={realTemplate}/>

            <Column columnKey={'currency'} style={{width: '100px', textAlign:'center'}}
                    field={'currency.code'} header={'Currency'} body={currencyTemplate}/>

            <Column columnKey={'priceByStandardCurrency'} hidden={!showPriceByStandardCurrency} header={'EUR'}
                    style={{width: '100px', textAlign:'center'}} field={'priceByStandardCurrency'} body={eurTemplate}/>

            <Column columnKey={'action'} headerClassName={'center'} header={headerTemplate}
                    style={{width: '75px'}} body={actionTemplate}/>
        </DataTable>
    </div>
}