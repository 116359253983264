import React, {useState, useEffect} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ClientService} from "../service/ClientService";
import moment from "moment";

export const ClientTypeRegister = (props) => {

    const [clientTypeRegister, setClientTypeRegister] = useState(null);
    const [loading, setLoading] = useState(true);
    const clientService = new ClientService();

    useEffect(() => {
        clientService.getClientTypeRegister(props.client.id).then(data => {
            setClientTypeRegister(data);
            setLoading(false)
        });
    }, [props.client]);

    const changeDateTimeBodyTemplate = (rowData) => {
        return moment(rowData.changeDateTime).format('YYYY-MM-DD HH:MM')
    }
    return (
        <div className="p-grid table-demo">
            <div className="p-col-12">
                <DataTable value={clientTypeRegister} paginator className="p-datatable-customers" rows={10}
                           dataKey="id" rowHover emptyMessage="No register entry found." loading={loading}>
                    <Column field="user.login" header="User" sortable />
                    <Column field="oldType" header="Old Type" sortable />
                    <Column field="newType" header="New Type" sortable />
                    <Column field="changeDateTime" header="Change date" sortable body={changeDateTimeBodyTemplate}/>
                </DataTable>
            </div>
        </div>
    )
}
