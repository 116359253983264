import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {IncotermsService} from '../service/IncotermsService';
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";

export const Incoterms = () => {

    let emptyIncoterm = {
        id: null,
        title: ''
    };

    const [incoterms, setIncoterms] = useState(null);
    const [incotermDialog, setIncotermDialog] = useState(false);
    const [deleteIncotermDialog, setDeleteIncotermDialog] = useState(false);
    const [incoterm, setIncoterm] = useState(emptyIncoterm);
    const [selectedIncoterm, setSelectedIncoterm] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const incotermsService = new IncotermsService();


    useMountEffect(() => {
        incotermsService.getIncoterms()
            .then(data => {
                setIncoterms(data);
                setLoading(false);
            });
    });

    const openNew = () => {
        setIncoterm(emptyIncoterm);
        setSubmitted(false);
        setIncotermDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setIncotermDialog(false);
    }

    const hideDeleteIncotermDialog = () => {
        setDeleteIncotermDialog(false);
    }

    const saveIncoterm = () => {
        setSubmitted(true);

        if (incoterm.title.trim()) {
            let _incoterms = [...incoterms];
            let _incoterm = {...incoterm};

            incotermsService.saveIncoterm(_incoterm)
                .then(data => {
                    if (incoterm.id) {
                        const index = findIndexById(incoterm.id);
                        _incoterms[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Incoterm Updated',
                            life: 3000
                        });
                    } else {
                        _incoterms.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Incoterm Created',
                            life: 3000
                        });
                    }
                    setIncoterms(_incoterms);
                    setIncotermDialog(false);
                    setIncoterm(emptyIncoterm);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editIncoterm = (incoterm) => {
        setIncoterm({ ...incoterm });
        setIncotermDialog(true);
    }

    const confirmDeleteIncoterm = (incoterm) => {
        setIncoterm(incoterm);
        setDeleteIncotermDialog(true);
    }

    const deleteIncoterm = () => {
        incotermsService.deleteIncoterm(incoterm.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeleteIncotermDialog(false);
                    setIncoterm(emptyIncoterm);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _incoterms = incoterms.filter(val => val.id !== incoterm.id);
                    setIncoterms(_incoterms);
                    setDeleteIncotermDialog(false);
                    setIncoterm(emptyIncoterm);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Incoterm Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < incoterms.length; i++) {
            if (incoterms[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _incoterm = { ...incoterm };
        _incoterm[`${name}`] = val;

        setIncoterm(_incoterm);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editIncoterm(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'} onClick={() => confirmDeleteIncoterm(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Incoterms</h5>
        </div>
    );

    const incotermDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveIncoterm} />
        </>
    );
    const deleteIncotermDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIncotermDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIncoterm} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={incoterms} selection={selectedIncoterm} onSelectionChange={(e) => setSelectedIncoterm(e.value)}
                               dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} incoterms"
                               emptyMessage="No Incoterms found." header={header}>
                        {/*<Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>*/}
                        <Column field="title" header="Title" sortable body={titleBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={incotermDialog} style={{ width: '450px' }} header="Incoterm Details" modal className="p-fluid" footer={incotermDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={incoterm.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !incoterm.title })} />
                            {submitted && !incoterm.title && <small className="p-invalid">Title is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteIncotermDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteIncotermDialogFooter} onHide={hideDeleteIncotermDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {incoterm && <span>Are you sure you want to delete <b>{incoterm.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
