import React, {Fragment, useState, useRef, useEffect} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Divider} from 'primereact/divider';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ClientService} from '../../service/ClientService';
import {ClientContactPersons} from "../../components/ClientContactPersons";
import {TradeInformation} from "../../components/TradeInformation";
import {CountryService} from "../../service/CountryService";
import {UserService} from "../../service/UserService";
import {Events} from "../../components/Events";
import {MultiSelect} from "primereact/multiselect";
import {TradeTypesService} from "../../service/TradeTypesService";
import {TabPanel, TabView} from "primereact/tabview";
import {InputTextarea} from "primereact/inputtextarea";
import {ClientTypeRegister} from "../../components/ClientTypeRegister";
import {useMountEffect} from "primereact";
import {isNullOrUndef} from "chart.js/helpers";
import {ToggleButton} from "primereact/togglebutton";
import {SplitButton} from "primereact/splitbutton";
import {ClientsFilters} from "../../components/ClientsFilters";
import {ExportFileService} from "../../service/ExportFileService";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {
    getAddressField, getCityField, getClientGroupsField,
    getCountryField, getEmailField, getPhoneField, getVatCodeField,
    getPostalCodeField, getRegistrationCodeField, getTitleField, getPhysicalPersonField
} from "./ClientsFormFieldsAccount";
import {
    getCreditTimeField,
    getOurCreditTimeField,
    getSecondManagerField, getTradeTypesField,
    getUsersField, getVendorManagerField,
    getWageRateField
} from "./ClientsFormFieldsSettings";

export const Clients = (props) => {

    let emptyClient = {
        id: null,
        title: '',
        physicalPerson: false,
        registrationCode: '',
        vatCode: '',
        country: null,
        city: '',
        address: '',
        postalCode: '',
        email: '',
        phone: '',
        wageRate: 0,
        creditTime: 0,
        ourCreditTime: 0,
        users: props.userData !== null ? [props.userData] : null,
        costsManager: null,
        secondManager: null,
        tradeTypes: [],
        clientGroups: [],
        clientTypeId: props.clientsTypes[0].value,
        comment: ''
    };
    const [clients, setClients] = useState([]);
    const [clientDialog, setClientDialog] = useState(false);
    const [clientTypeRegisterDialog, setClientTypeRegisterDialog] = useState(false);
    const [contactPersonsDialog, setContactPersonsDialog] = useState(false);
    const [eventsDialog, setEventsDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [client, setClient] = useState(emptyClient);
    const [selectedClients, setSelectedClients] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [validFormFields, setValidFormFields] = useState([]);
    const [countries, setCountries] = useState(null);
    const [managers, setManagers] = useState([]);
    const [tradeTypes, setTradeTypes] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, setFilters] = useState('');
    const [filter, setFilter] = useState(false);
    const [searchClients, setSearchClients] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const [showAboutFields, setShowAboutFields] = useState([]);
    const [showSettingsFields, setShowSettingsFields] = useState([]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);
    const clientService = new ClientService();
    const countryService = new CountryService();
    const userService = new UserService();
    const tradeTypeService = new TradeTypesService();
    const exportFileService = new ExportFileService();
    let loadLazyTimeout = null;
    const columns = [
        {key: 'title', field: "title", header: "Title"},
        {key: 'clientGroups', field: "clientGroups", header: "Client Groups"},
        {key: 'physicalPerson', field: "physicalPerson", header: "Physical Person"},
        {key: 'registrationCode', field: "registrationCode", header: "Reg. Code"},
        {key: 'vatCode', field: "vatCode", header: "VAT Code"},
        {key: 'country.title', field: "country.title", header: "Country"},
        {key: 'city', field: "city", header: "City"},
        {key: 'address', field: "address", header: "Address"},
        {key: 'postalCode', field: "postalCode", header: "Postal Code"},
        {key: 'email', field: "email", header: "Email"},
        {key: 'phone', field: "phone", header: "Phone"},
        {key: 'user', field: "user", header: "Manager"},
        {key: 'costsManager', field: "costsManager", header: "Vendor manager"}, //Change title from const Manager to Vendor Manager
        {key: 'secondManager', field: "secondManager", header: "Second manager"},
        {key: 'clientType.title', field: "clientType.title", header: "Client Type"},
        {key: 'comment', field: "comment", header: "Comment"},
    ];
    const columnsOptions = [
        {key: "title", width: '150px', order: 1},
        {key: "clientGroups", width: '150px', order: 2},
        {key: "physicalPerson", width: '50px', order: 3},
        {key: "registrationCode", width: '150px', order: 4},
        {key: "vatCode", width: '150px', order: 5},
        {key: "country.title", width: '200px', order: 6},
        {key: "city", width: '150px', order: 7},
        {key: "address", width: '200px', order: 8},
        {key: "postalCode", width: '150px', order: 9},
        {key: "email", width: '200px', order: 10},
        {key: "phone", width: '150px', order: 11},
        {key: "user", width: '200px', order: 12},
        {key: "costsManager", width: '200px', order: 13},
        {key: "secondManager", width: '200px', order: 14},
        {key: "clientType.title", width: '150px', order: 15},
        {key: "comment", width: '200px', order: 15},
    ];

    const clientsFormFields = [
        {
            ['registrationCode']: {
                value: 'registrationCode',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['vatCode']: {
                value: 'vatCode',
                type: 'text',
                customer: {show: true, required: false},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['phone']: {
                value: 'phone',
                type: 'text',
                customer: {show: true, required: false},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['email']: {
                value: 'email',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: true, required: true},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['country']: {
                value: 'country',
                type: 'obj',
                customer: {show: true, required: true},
                vendor: {show: true, required: true},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: true},
            }
        },
        {
            ['city']: {
                value: 'city',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: true, required: true},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: true},
            }
        },
        {
            ['address']: {
                value: 'address',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['postalCode']: {
                value: 'postalCode',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['creditTime']: {
                value: 'creditTime',
                type: 'text',
                customer: {show: false, required: false},
                vendor: {show: true, required: true},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: true},
            }
        },
        {
            ['ourCreditTime']: {
                value: 'ourCreditTime',
                type: 'text',
                customer: {show: true, required: true},
                vendor: {show: false, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: false, required: false},
            }
        },
        {
            ['users']: {
                value: 'users',
                type: 'array',
                customer: {show: true, required: true},
                vendor: {show: true, required: true},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: true},
            }
        },
        {
            ['secondManager']: {
                value: 'secondManager',
                type: 'array',
                customer: {show: true, required: false},
                vendor: {show: false, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: false, required: false},
            }
        },
        {
            ['vendorManager']: {
                value: 'vendorManager',
                type: 'obj',
                customer: {show: false, required: false},
                vendor: {show: true, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: true, required: false},
            }
        },
        {
            ['tradeTypes']: {
                value: 'tradeTypes',
                type: 'array',
                customer: {show: true, required: false},
                vendor: {show: false, required: false},
                shipper: {show: false, required: false},
                consignee: {show: false, required: false},
                forwarder: {show: false, required: false},
                office: {show: false, required: false},
            }
        }
    ];

    useMountEffect(() => {
        countryService.getCountries().then(data => setCountries(data));
        userService.getManagers().then(data => setManagers(data));
        tradeTypeService.getTradeTypes().then(data => setTradeTypes(data))
        const columnsStorage = JSON.parse(window.localStorage.getItem('clients-datatable-columns'));
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('clients-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage)
        } else {
            setSelectedColumns(columns)
            window.localStorage.setItem('clients-datatable-columns', JSON.stringify(columns))
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('clients-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        setLoading(true);
        getClients()
    });

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getClients()
            }, 350);
        }
    }, [lazyParams, filters]);

    useEffect(() => {
        if (!isNullOrUndef(client)) {
            checkClientGroup()
        }
    }, [client, submitted])

    const confirmVat = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'VAT is empty field. Still want continue save this record?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => saveClient(),
        });
    };

    const checkRequiredField = (type, value) => {
      if(isNullOrUndef(value)){
          return true;
      } else {
          if(type === 'text') {
              return value.toString().trim().length === 0
          } else {
              return value.length === 0
          }
      }
    }

    const getClients = () => {
        let filterParams = null
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('clients-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('clients-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('clients-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('clients-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if (!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        clientService.getClients(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(r => {
                setTotalRecords(r["totalElements"]);
                setClients(r.content)
                setLoading(false);
                setLoadFirstTime(false)
            });
    }

    const checkClientGroup = () => {
        if (!isNullOrUndef(client.clientGroups) && client.clientGroups.length > 0) {
            const groupTitles = client.clientGroups.map(group => group.title.toLowerCase());
            const fieldsByGroup = []
            clientsFormFields.forEach(field => {
                Object.entries(field).forEach(([key, value]) => {
                    const fieldData = value;
                    const isVisibleForGroup = groupTitles.some(group => fieldData[group].show);
                    if (isVisibleForGroup) {
                        fieldsByGroup.push({
                            value: fieldData.value,
                            type: fieldData.type,
                            required: fieldData.value === 'registrationCode' && client.physicalPerson ? false : groupTitles.some(group => fieldData[group].required)
                        });
                    }
                });
            });
            let showAboutFieldsList = [];
            let showSettingsFieldsList = [];
            setValidFormFields(fieldsByGroup);
            if (fieldsByGroup.length > 0) {
                fieldsByGroup.forEach(field => {
                    switch (field.value) {
                        case 'registrationCode':
                            showAboutFieldsList.push(getRegistrationCodeField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'vatCode':
                            showAboutFieldsList.push(getVatCodeField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'phone':
                            showAboutFieldsList.push(getPhoneField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'email':
                            showAboutFieldsList.push(getEmailField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'country':
                            showAboutFieldsList.push(getCountryField(client, submitted, field.required, countries, onValueChange, checkRequiredField));
                            break;
                        case 'city':
                            showAboutFieldsList.push(getCityField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'address':
                            showAboutFieldsList.push(getAddressField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'postalCode':
                            showAboutFieldsList.push(getPostalCodeField(client, submitted, field.required, onInputChange, checkRequiredField));
                            break;
                        case 'creditTime':
                            showSettingsFieldsList.push(getCreditTimeField(client, submitted, field.required, onInputNumberChange, checkRequiredField));
                            break;
                        case 'ourCreditTime':
                            showSettingsFieldsList.push(getOurCreditTimeField(client, submitted, field.required, onInputNumberChange, checkRequiredField));
                            break;
                        case 'users':
                            showSettingsFieldsList.push(getUsersField(client, submitted, field.required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField));
                            break;
                        case 'secondManager':
                            showSettingsFieldsList.push(getSecondManagerField(client, submitted, field.required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField));
                            break;
                        case 'vendorManager':
                            showSettingsFieldsList.push(getVendorManagerField(client, submitted, field.required, managers, selectedManagerTemplate, managerOptionTemplate, onValueChange, checkRequiredField));
                            break;
                        case 'tradeTypes':
                            showSettingsFieldsList.push(getTradeTypesField(client, submitted, field.required, tradeTypes, onValueChange, checkRequiredField));
                            break;
                        default:
                            console.log('Neatpažinta reikšmė:', field.value);
                            break;
                    }
                });
                setShowAboutFields(showAboutFieldsList);
                setShowSettingsFields(showSettingsFieldsList);
            } else {
                setShowAboutFields([]);
                setShowSettingsFields([]);
            }
        } else {
            setShowAboutFields([]);
            setShowSettingsFields([]);
        }
    }

    const openNew = () => {
        setClient(emptyClient);
        setSubmitted(false);
        setClientDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setActiveIndex(0)
        setClientDialog(false);
    }

    const hideDeleteClientDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteClientsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const hideContactPersonsDialog = () => {
        setContactPersonsDialog(false);
    }

    const hideEventsDialog = () => {
        setEventsDialog(false);
    }

    const hideClientTypeRegisterDialog = () => {
        setClientTypeRegisterDialog(false);
    }

    const checkRequired = (e) => {
        setSubmitted(true);
        let validForm = true;

        if (validFormFields.length === 0) {
            validFormFields.push({
                value: 'title',
                type: "text",
                required: true
            })
            validFormFields.push({
                value: 'clientGroups',
                type: "array",
                required: true
            })
        }
        let vatFieldEmpty = false;
        validFormFields.forEach(vf => {
            if(vf.required && checkRequiredField(vf.type, client[vf.value])){
                validForm = false;
            }
            if(client.physicalPerson === false && client.clientGroups.length > 0
                && client.clientGroups.some(group => group.title.toLowerCase() === 'customer' || group.title.toLowerCase() === 'vendor')
                    && (isNullOrUndef(client.vatCode) || client.vatCode.trim().length === 0)){
                vatFieldEmpty = true;
            }
        })
        if (validForm) {
            if(vatFieldEmpty){
                confirmVat(e);
            } else {
                saveClient();
            }
        }
    }

    const getCostManager = (client) => {
        if (!isNullOrUndef(client.costsManager)) {
            return {
                id: client.costsManager.id,
                firstName: client.costsManager.firstName,
                lastName: client.costsManager.lastName,
                email: client.costsManager.email,
            }
        } else {
            return null
        }
    }

    const getSecondManager = (client) => {
        if (!isNullOrUndef(client.secondManager)) {
            return {
                id: client.secondManager.id,
                firstName: client.secondManager.firstName,
                lastName: client.secondManager.lastName,
                email: client.secondManager.email,
            }
        } else {
            return null
        }
    }

    const saveClient = () => {
        let _clients = [...clients];
        let _client;
        if(client.clientGroups.some(group => group.title === 'Customer' || group.title === 'Vendor')) {
            _client = {
                id: client.id,
                countryId: client.country.id,
                title: client.title,
                registrationCode: client.registrationCode,
                vatCode: client.vatCode,
                city: client.city,
                address: client.address,
                postalCode: client.postalCode,
                email: client.email,
                phone: client.phone,
                creditTime: client.creditTime,
                wageRate: client.wageRate,
                ourCreditTime: client.ourCreditTime,
                userIds: client.users !== null ? client.users.filter(u => !isNullOrUndef(u)).map(u => u.id) : null,
                costsManager: getCostManager(client),
                tradeTypeIds: client.tradeTypes !== null ? client.tradeTypes.map(u => u.id) : null,
                clientGroupIds: client.clientGroups.map(u => u.id),
                secondManager: getSecondManager(client),
                physicalPerson: client.physicalPerson,
                clientTypeId: client.clientTypeId,
                comment: client.comment,
            };
        } else {
            _client = {
                id: client.id,
                title: client.title,
                wageRate: client.wageRate,
                clientGroupIds: client.clientGroups.map(u => u.id),
                comment: client.comment,
            };
        }
        clientService.saveClient(_client)
            .then(data => {
                if (client.id) {
                    const index = findIndexById(client.id);
                    _clients[index] = data;
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Account Updated',
                        life: 3000
                    });
                    setClientDialog(false);
                    setClient(emptyClient);
                    clientService.updateBookings(client.id).then()
                } else {
                    _clients.push(data);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Account Created',
                        life: 3000
                    });
                    if (data.clientType !== null) {
                        data['clientTypeId'] = data.clientType.id
                    }
                    setClient(data);
                    clientService.updateBookings(data.id).then()
                }
                setClients(_clients);
                setActiveIndex(0)
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const editClient = (client) => {
        if (client.clientType !== null) {
            client['clientTypeId'] = client.clientType.id
        }
        setClient({...client});
        setClientDialog(true);
    }

    const confirmDeleteClient = (client) => {
        setClient(client);
        setDeleteClientDialog(true);
    }

    const deleteClient = () => {
        clientService.deleteClient(client.id)
            .then(data => {
                if (!isNullOrUndef(data) && !data.success) {
                    setDeleteClientDialog(false);
                    setClient(emptyClient);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _clients = clients.filter(val => val.id !== client.id);
                    setClients(_clients);
                    setDeleteClientDialog(false);
                    setClient(emptyClient);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Client Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const showConcatPersonsList = (client) => {
        setClient(client);
        setContactPersonsDialog(true);
    }

    const showEventsList = (client) => {
        setClient(client);
        setEventsDialog(true);
    }

    const showClientTypeRegisterList = (client) => {
        setClient(client);
        setClientTypeRegisterDialog(true);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const deleteSelectedClients = () => {
        let _clients = clients.filter(val => !selectedClients.includes(val));
        setClients(_clients);
        setDeleteClientsDialog(false);
        setSelectedClients(null);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'Clients Deleted', life: 3000});
    }

    const onValueChange = (e, name) => {
        let _client = {...client};
        _client[`${name}`] = e.value;
        setClient(_client);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _client = {...client};
        _client[`${name}`] = val;

        setClient(_client);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _client = {...client};
        _client[`${name}`] = val;

        setClient(_client);
    }

    const managerBodyTemplate = (rowData) => {
        return rowData.users.map(user => <p key={user.id} style={{margin: 0, lineHeight: 'inherit'}}>
            {user.firstName} {user.lastName}
        </p>)
    }

    const clientGroupsBodyTemplate = (rowData) => {
        return rowData.clientGroups.map(cg => <p key={cg.id} style={{margin: 0, lineHeight: 'inherit'}}>
            {cg.title}
        </p>)
    }

    const physicalPersonBodyTemplate = (rowData) => {
        return rowData.physicalPerson ? 'Yes' : 'No';
    }

    const costManagerBodyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.costsManager))
            return

        return rowData.costsManager.firstName + ' ' + rowData.costsManager.lastName
    }

    const secondManagerBodyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.secondManager))
            return

        return rowData.secondManager.firstName + ' ' + rowData.secondManager.lastName
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="actions">
            <Button icon="pi pi-user" className="p-button-rounded p-button-sm p-button-info p-button-text"
                    title={'Contact persons'} onClick={() => showConcatPersonsList(rowData)}/>
            <Button icon="pi pi-calendar" className="p-button-rounded p-button-sm p-button-primary p-button-text"
                    title={'Events'} onClick={() => showEventsList(rowData)}/>
            <Button icon="pi pi-book" className="p-button-rounded p-button-sm p-button-warning p-button-text"
                    title={'Logs'} onClick={() => showClientTypeRegisterList(rowData)}/>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-sm p-button-success p-button-text"
                    title={'Edit'} onClick={() => editClient(rowData)}/>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-sm p-button-danger p-button-text"
                    title={'Delete'} onClick={() => confirmDeleteClient(rowData)}/>
        </div>);
    }

    const clientDialogFooter = (<>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
        <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={checkRequired}/>
    </>);

    const deleteClientDialogFooter = (<>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientDialog}/>
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteClient}/>
    </>);

    const deleteClientsDialogFooter = (<>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientsDialog}/>
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedClients}/>
    </>);

    const managerOptionTemplate = (option) => {
        return (<div className="manager-item">
            <div>{option.firstName + ' ' + option.lastName}</div>
        </div>);
    }

    const selectedManagerTemplate = (option, props) => {
        if (!isNullOrUndef(props) && (props.id === 'costsManager' || props.id === 'secondManager')) {
            option = props.value
        }
        if (option) {
            return (
                <div className="manager-item manager-item-value">
                    {option.firstName + ' ' + option.lastName}
                </div>
            );
        }
        return <span>-- Select --</span>;
    }



    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
        window.localStorage.setItem('clients-datatable-columns', JSON.stringify(selectedColumns))
    }

    const columnComponents = selectedColumns.map(col => {
        let bodyTemplate = null
        if (col.field === 'user') {
            bodyTemplate = managerBodyTemplate;
        } else if (col.field === 'costsManager') {
            bodyTemplate = costManagerBodyTemplate;
        } else if (col.field === 'secondManager') {
            bodyTemplate = secondManagerBodyTemplate;
        } else if(col.field === 'clientGroups') {
            bodyTemplate = clientGroupsBodyTemplate
        } else if(col.field === 'physicalPerson') {
            bodyTemplate = physicalPersonBodyTemplate
        }
        let colWidth = columnsOptions.find(cw => cw.key === col.key);

        return <Column key={col.key} field={col.field} style={{width: colWidth.width}}
                       header={<LazySortMultiFields header={col.header} fieldKey={col.key} crud={'clients'}
                                                    field={col.field} lazyParams={lazyParams}
                                                    setLazyParams={setLazyParams}/>}
                       sortable={false} body={bodyTemplate}/>;
    });

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('clients-filter-toggle', JSON.stringify(e.value))
    }

    const renderHeader = () => {
        let items = [
            {
                label: 'Export XLS',
                style: {textAlign: 'center'},
                icon: 'pi pi-file-excel',
                command: () => exportFileService.exportExcel(clients, 'Clients', toast)
            }
        ]
        return (<Fragment>
            <div className="table-header">
                <h5 className="p-mb-2">Accounts Management</h5>
            </div>
            <ConfirmPopup />
            <div className="flex justify-content-between flex-wrap card-container">
                <div className="flex align-items-center justify-content-center">
                    <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                                 placeholder="Select Item" id={'clientsList-selectColumns'} filter
                                 optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                    <ToggleButton checked={filter} onChange={onFilterToggle}
                                  onLabel="" offLabel="" className={'p-ml-3'}
                                  onIcon="pi pi-filter-slash" offIcon="pi pi-filter" aria-label="Filters"/>
                    <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchClients(searchClients + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <SplitButton label="New" icon={'pi pi-plus'} onClick={openNew}
                                 model={items} className="p-button-success mr-2"/>
                </div>
            </div>
            <div>
                <ClientsFilters loading={loading} selectedColumns={selectedColumns} filter={filter} clientGroups={props.clientGroups}
                                searchClients={searchClients} clearFilter={clearFilter} managers={managers}
                                getClients={getClients} filters={filters} setFilters={setFilters}/>
            </div>
        </Fragment>)
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                    <DataTable ref={dt} value={clients} dataKey="id" totalRecords={totalRecords}
                               onPage={(e) => setLazyParams(e)}
                               header={renderHeader} paginator scrollHeight="flex"
                               rows={lazyParams.rows} lazy rowHover rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                               first={lazyParams.first} id={'clients-list'} size="small" loading={loading}
                               scrollable resizableColumns columnResizeMode="fit"
                               stateStorage="local" stateKey="clients-dt-state-local"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                               emptyMessage="No clients found.">
                        {columnComponents}
                        <Column body={actionBodyTemplate} header={'Actions'} frozen={true}
                                style={{minWidth: '190px', background: 'white'}}
                                alignFrozen={'right'}/>
                    </DataTable>
                </div>

                <Dialog visible={clientDialog} id={'clientDialog'} style={{width: '75vw'}} header="Account Details"
                        modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Account info">
                            <div className="p-fluid grid">
                                <Divider type="solid" align="left">
                                    <div className="inline-flex align-items-center">
                                        <i className="pi pi-user mr-2"></i>
                                        <b>About account</b>
                                    </div>
                                </Divider>
                                {getTitleField(client, submitted, onInputChange)}
                                {getClientGroupsField(client, submitted, props.clientGroups, onValueChange)}
                                {getPhysicalPersonField(client, submitted, props.clientGroups, onValueChange)}
                                {showAboutFields}
                                <Divider type="solid" align="left">
                                    <div className="inline-flex align-items-center">
                                        <i className="pi pi-user mr-2"></i>
                                        <b>Account settings</b>
                                    </div>
                                </Divider>
                                {getWageRateField(client, props.checkUserRole, onInputNumberChange)}
                                {showSettingsFields}
                                <div className="p-field col-12">
                                    <Divider type="solid" align="left">
                                        <div className="inline-flex align-items-center">
                                            <i className="pi pi-comment mr-2"></i>
                                            <b>Comment</b>
                                        </div>
                                    </Divider>
                                    <InputTextarea id="comment"
                                                   value={!isNullOrUndef(client.comment) ? client.comment : ''}
                                                   rows={5} onChange={(e) => onInputChange(e, 'comment')}/>
                                </div>

                            </div>
                        </TabPanel>
                        {
                            client.tradeTypes.map(type =>
                                <TabPanel key={type.id} header={type.name}>
                                    <TradeInformation toast={toast} tradeType={type}
                                                      selectedManagerTemplate={selectedManagerTemplate}
                                                      managerOptionTemplate={managerOptionTemplate}
                                                      client={client} managers={managers}/>
                                </TabPanel>)
                        }

                    </TabView>
                </Dialog>

                <Dialog visible={deleteClientDialog} style={{width: '450px'}} header="Confirm" modal
                        footer={deleteClientDialogFooter} onHide={hideDeleteClientDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {client && <span>Are you sure you want to delete <b>{client.title}</b>?</span>}
                    </div>
                </Dialog>

                <Dialog visible={deleteClientsDialog} style={{width: '450px'}} header="Confirm" modal
                        footer={deleteClientsDialogFooter} onHide={hideDeleteClientsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {client && <span>Are you sure you want to delete the selected clients?</span>}
                    </div>
                </Dialog>

                <Dialog visible={contactPersonsDialog} style={{width: '50vw'}} header="Contact Persons" modal
                        onHide={hideContactPersonsDialog}>
                    <ClientContactPersons client={client}/>
                </Dialog>
                <Dialog visible={eventsDialog} style={{width: '70vw'}} header="Events" modal
                        onHide={hideEventsDialog}>
                    <Events client={client} userData={props.userData}/>
                </Dialog>
                <Dialog visible={clientTypeRegisterDialog} style={{width: '50vw'}} header="Client Type Register" modal
                        onHide={hideClientTypeRegisterDialog}>
                    <ClientTypeRegister client={client} userData={props.userData}/>
                </Dialog>
            </div>
        </div>
    );
}
