import axios from 'axios';
import authHeader from "./auth-header";

export class BookingsService {

    getBookings = (page, size, sortFields, filterParams) => {
        return axios.get(`/api/booking?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res);
    }

    getBookingsDto = (page, size, sortFields, filterParams) => {
        return axios.get(`/api/booking/dto?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res);
    }

    getBooking = (bookingId) => {
        return axios.get(`/api/booking/${bookingId}`, {headers: authHeader()})
            .then(res => res.data);
    }

    saveBooking = (booking) => {
        return axios.post(`/api/booking/`, booking, {headers: authHeader()})
            .then(res => res.data);
    }

    getBillOfLadingStatuses() {
        return axios.get('/api/bill-of-lading-status', {headers: authHeader()})
            .then(res => res.data);
    }

    getImporterSecurityFilling() {
        return axios.get('/api/importer-security-filling', {headers: authHeader()})
            .then(res => res.data);
    }

    getPodUpdateRegister = (bookingId) => {
        return axios.get(`/api/booking/pod-update-register/${bookingId}`, {headers: authHeader()})
            .then(res => res.data);
    }

    copyBooking = (bookingId) => {
        return axios.post(`/api/booking/${bookingId}/copy`, null,{headers: authHeader()})
            .then(res => res.data);
    }

    exportToExcel(type, sortFields, filterParams, columns) {
        return axios.get(`/api/booking/export/excel?type=${type}&sort=${sortFields}${filterParams}&columns=${columns}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

    exportOperationsToExcel(type, sortFields, filterParams, columns) {
        return axios.get(`/api/bookings-operations-report/export/excel?type=${type}&sort=${sortFields}${filterParams}&columns=${columns}`, {headers: authHeader(), responseType: 'blob'})
            .then(res => res);
    }

    getPlanRealSummary(filterParams, type) {
        return axios.get(`/api/booking/summary?type=${type}${filterParams}`, {headers: authHeader()})
            .then(res => res.data);
    }

    getAuditLog = (bookingId) => {
        return axios.get(`/api/booking/audit/${bookingId}/changes`, {headers: authHeader()})
            .then(res => res.data);
    }

    initiateTransfer = (bookingId, body) => {
        return axios.post(`/api/booking/${bookingId}/initiate-transfer`, body,{headers: authHeader()})
            .then(res => res.data);
    }

    cancelTransfer = (bookingId, body) => {
        return axios.post(`/api/booking/${bookingId}/cancel-transfer`, body,{headers: authHeader()})
            .then(res => res.data);
    }

    acceptTransfer = (bookingId, body) => {
        return axios.post(`/api/booking/${bookingId}/accept-transfer`, body,{headers: authHeader()})
            .then(res => res.data);
    }

    rejectTransfer = (bookingId, body) => {
        return axios.post(`/api/booking/${bookingId}/reject-transfer`, body,{headers: authHeader()})
            .then(res => res.data);
    }

    getBookingsOperationsReport = (page, size, sortFields, filterParams) => {
        return axios.get(`/api/bookings-operations-report?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res);
    }
    getBookingsOperationsReportSummary = (page, size, sortFields, filterParams) => {
        return axios.get(`/api/bookings-operations-report/summary?page=${page}&size=${size}&sort=${sortFields}${filterParams}`, {headers: authHeader()})
            .then(res => res);
    }
}
