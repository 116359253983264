import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {PlacesService} from "../service/PlacesService";
import {isNullOrUndef} from "chart.js/helpers";
import {FilterMatchMode} from "primereact/api";

export const Places = () => {

    let emptyPlace = {
        id: null,
        name: ''
    };

    const [places, setPlaces] = useState(null);
    const [placeDialog, setPlaceDialog] = useState(false);
    const [deletePlaceDialog, setDeletePlaceDialog] = useState(false);
    const [place, setPlace] = useState(emptyPlace);
    const [selectedPlaces, setSelectedPlaces] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loading, setLoading] = useState(true);
    const placeService = new PlacesService()
    const filters = {
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    }

    useEffect(() => {
        placeService.getPlaces()
            .then(data => {
                setPlaces(data);
                setLoading(false);
            });
    }, []);

    const openNew = () => {
        setPlace(emptyPlace);
        setSubmitted(false);
        setPlaceDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPlaceDialog(false);
    }

    const hideDeletePlaceDialog = () => {
        setDeletePlaceDialog(false);
    }

    const savePlace = () => {
        setSubmitted(true);

        if (place.name.trim()) {
            let _places = [...places];
            let _place = {...place};

            placeService.addNewPlace(_place)
                .then(data => {
                    if (place.id) {
                        const index = findIndexById(place.id);
                        _places[index] = data;
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Direction Updated',
                            life: 3000
                        });
                    } else {
                        _places.push(data);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Country Direction Created',
                            life: 3000
                        });
                    }
                    setPlaces(_places);
                    setPlaceDialog(false);
                    setPlace(emptyPlace);
                })
                .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
                });
        }
    }

    const editPlace = (place) => {
        setPlace({ ...place });
        setPlaceDialog(true);
    }

    const confirmDeletePlace = (place) => {
        setPlace(place);
        setDeletePlaceDialog(true);
    }

    const deletePlace = () => {
        placeService.deletePlace(place.id)
            .then(data => {
                if(!isNullOrUndef(data) && !data.success) {
                    setDeletePlaceDialog(false);
                    setPlace(emptyPlace);
                    toast.current.show({severity: 'error', summary: 'Error', detail: data.errorMessage, life: 3000});
                } else {
                    let _places = places.filter(val => val.id !== place.id);
                    setPlaces(_places);
                    setDeletePlaceDialog(false);
                    setPlace(emptyPlace);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Country Direction Deleted',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < places.length; i++) {
            if (places[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _place = { ...place };
        _place[`${name}`] = val;

        setPlace(_place);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Place</span>
                {rowData.name}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                        title={'Edit'} onClick={() => editPlace(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text p-button-sm"
                        title={'Delete'}  onClick={() => confirmDeletePlace(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Places</h5>
        </div>
    );

    const placeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePlace} />
        </>
    );
    const deletePlaceDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePlaceDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePlace} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}/>

                    <DataTable ref={dt} value={places} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" loading={loading}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} country directions"
                        emptyMessage="No country directions found." header={header} filterDisplay="row" filters={filters}>
                        <Column field="name" header="Place" sortable filter body={titleBodyTemplate}/>
                        <Column body={actionBodyTemplate} bodyStyle={{textAlign: 'right'}}/>
                    </DataTable>

                    <Dialog visible={placeDialog} style={{ width: '450px' }} header="Places Details"
                            modal className="p-fluid" footer={placeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Place</label>
                            <InputText id="name" value={place.name} onChange={(e) => onInputChange(e, 'name')}
                                       required autoFocus className={classNames({ 'p-invalid': submitted && !place.name })} />
                            {submitted && !place.name && <small className="p-invalid">Place is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePlaceDialog} style={{ width: '450px' }} header="Confirm"
                            modal footer={deletePlaceDialogFooter} onHide={hideDeletePlaceDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {place && <span>Are you sure you want to delete <b>{place.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
