import axios from 'axios'
import authHeader from "./auth-header";

export class ShippingLinesService {

    getShippingLines() {
        return axios.get('/api/shipping-line', { headers: authHeader() })
            .then(res => res.data);
    }

    saveShippingLine(shippingLine) {
        return axios.post('/api/shipping-line', shippingLine, { headers: authHeader() })
            .then(res => res.data);
    }

    deleteShippingLine(shippingLineId) {
        return axios.delete(`/api/shipping-line/${shippingLineId}`, { headers: authHeader() })
            .then(res => res.data);
    }
}