import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {isNullOrUndef} from "chart.js/helpers";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";
import classNames from "classnames";
import {Button} from "primereact/button";
import nextId from "react-id-generator";
import {Calendar} from "primereact/calendar";
import moment from "moment/moment";
import {InputNumber} from "primereact/inputnumber";

export const PaymentForm = (props) => {

    const [paymentKey, setPaymentKey] = useState(1);
    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [valid, setValid] = useState(false);

    const paidUnpaidOptions = [
        {title: 'Paid', paidType: 'PAID'},
        {title: 'Paid/Unpaid', paidType: 'PARTIALLY_PAID'},
        {title: 'Unpaid', paidType: 'NOT_PAID'}
    ]

    const emptyPaymentData = {
        id: 'Id_' + nextId(),
        date: null,
        sum: 0,
        currency: null
    }

    const confirmDelete = (data, rowId) => {
        let info = Number.isFinite(data.id) ? `${moment(data.date).format('YYYY-MM-DD')} - ${data.sum} ${data.currency.code}` : ''
        confirmDialog({
            message: `Are you sure you want to remove this [${info}] payment ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deletePaymentItem(rowId),
            reject: () => null
        });
    }

    const deletePaymentItem = (paymentId) => {
        let _payments = [...props.invoice.invoicePayments]
        _payments = _payments.filter(c => c.id !== paymentId)
        props.invoice.invoicePayments = _payments
        setValid(false)
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        props.recalcInvoice()
        setPaymentKey(paymentKey + 1)
    }

    const setActiveRowIndex = (itemId) => {
        let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
        setEditingRows(_editingRows);
        props.setActivatedEdit(true)
        setEditingRowId(itemId)
    }

    const savePaymentRow = (data) => {
        if (isNullOrUndef(data.date) || isNullOrUndef(data.currency) || data.currency === ''
            || isNullOrUndef(data.sum) || data.sum === '') {
            setValid(true)
        } else {
            setEditingRows({})
            setEditingRowId(null)
            setValid(false)
            props.setActivatedEdit(false)
            props.recalcInvoice()
        }
    }

    const actionTemplate = (data) => {
        return <div key={data.id} className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.id ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => savePaymentRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        title={'Edit'} onClick={() => isNullOrUndef(editingRowId) && !props.activatedEdit
                    ? setActiveRowIndex(data.id) : props.warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    title={'Delete'} onClick={() => confirmDelete(data, data.id)}/>
        </div>
    }

    const onCellChange = (value, options) => {
        let {column, rowIndex, rowData} = options;
        let _payments = [...props.invoice.invoicePayments]
        rowData[column.props.columnKey] = value;
        _payments[rowIndex] = rowData;
        props.invoice.invoicePayments = _payments
        setPaymentKey(paymentKey + 1)
    }

    const currencyEditor = (options) => {
        return <div>
            <Dropdown value={options.value} options={props.currencies}
                      optionLabel={'code'} placeholder="-- Select --"
                      onChange={(e) => onCellChange(e.value, options)} required
                      className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')})}/>
            {valid && (isNullOrUndef(options.value) || options.value === '') &&
                <small className="p-invalid">Required</small>}
        </div>
    }

    const dateEditor = (options) => {
        return <div>
            <Calendar showIcon dateFormat={'yy-mm-dd'} locale={'lt'}
                      value={!isNullOrUndef(options.value) ? moment(options.value).toDate() : null}
                      onChange={(e) => onCellChange(e.value, options)}
                      className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')})}/>
            {valid && (isNullOrUndef(options.value) || options.value === '') &&
                <small className="p-invalid">Required</small>}
        </div>
    }

    const numberEditor = (options) => {
        return <div>
            <InputNumber value={!isNullOrUndef(options.value) ? options.value : ''} useGrouping={false} minFractionDigits={0} maxFractionDigits={4}
                       onChange={(e) => onCellChange(e.value, options)} mode="decimal"
                       className={classNames({'p-invalid': valid && (isNullOrUndef(options.value) || options.value === '')})}/>
            {valid && (isNullOrUndef(options.value) || options.value === '' || options.value) &&
                <small className="p-invalid">Required</small>}
        </div>
    }

    const currencyTemplate = (rowData) => {
        if (isNullOrUndef(rowData.currency))
            return

        return rowData.currency.code
    }

    const addNewPayment = () => {
        if (isNullOrUndef(editingRowId)) {
            let _payments = [...props.invoice.invoicePayments]
            const newPayment = emptyPaymentData;
            _payments.push(newPayment)
            props.invoice.invoicePayments = _payments
            setActiveRowIndex(newPayment.id)
            setPaymentKey(paymentKey + 1)
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       title={'Add New'} onClick={() => addNewPayment(null, null)}/>
    }

    const dateBodyTemplate = (data, type) => {
        if (isNullOrUndef(data[type]) || data[type] === '')
            return

        return <div>
            {moment(data[type]).format('YYYY-MM-DD')}
        </div>
    }

    const payAll = (type) => {
        if (isNullOrUndef(editingRowId)) {
            let _payments = [...props.invoice.invoicePayments]
            const newPayment = emptyPaymentData;
            if(type === 'leftEur'){
                newPayment.date = new Date()
                newPayment.sum = props.invoice["leftToPayByStandardCurrency"]
                newPayment.currency = props.currencies.find(c => c.standard)
            } else {
                newPayment.date = new Date()
                newPayment.sum = props.invoice["leftToPay"]
                newPayment.currency = props.currencies.find(c => c.code === props.invoice.currency.code)
            }
            _payments.push(newPayment)
            props.invoice.invoicePayments = _payments
            props.invoice.paidType = 'PAID'
            setPaymentKey(paymentKey + 1)
            props.recalcInvoice()
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const onChangeInput = (value, type) => {
        props.invoice[type] = value
        setPaymentKey(paymentKey + 1)
    }

    return <div className={'p-grid'}>
        <div className="p-col-2">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentInvoiceNr">Invoice number</label>
                    <InputText id={'paymentInvoiceNr'} value={props.invoice.number} disabled/>
                </div>
            </div>
        </div>
        <div className="p-col-2">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentClient">Client</label>
                    <InputText id={'paymentClient'} value={props.invoice.client.title} disabled/>
                </div>
            </div>
        </div>
        <div className="p-col-1">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentTotalEur">Total EUR</label>
                    <InputText id={'paymentTotalEur'} value={props.invoice["totalSumWithVatByStandardCurrency"]} disabled/>
                </div>
            </div>
        </div>
        <div className="p-col-1" style={{display: props.invoice.currency.code !== 'EUR' ? 'block' : 'none'}}>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentTotalEur">Total By Invoice</label>
                    <InputNumber id={'paymentTotalEur'} value={props.invoice["totalSumWithVat"]} disabled
                              suffix={!isNullOrUndef(props.invoice.currency) ? ' '+props.invoice.currency.code : ''} />
                </div>
            </div>
        </div>
        <div className="p-col-1">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentLeftEur">Left EUR
                        <Button title="Pay all" icon={'pi pi-wallet'} className="p-button-link payments"
                                onClick={() => payAll('leftEur')} style={{width: "auto", marginLeft: "5px", padding: "0"}}/>
                    </label>
                    <InputText id={'paymentLeftEur'} value={props.invoice["leftToPayByStandardCurrency"]} disabled/>
                </div>
            </div>
        </div>
        <div className="p-col-1" style={{display: props.invoice.currency.code !== 'EUR' ? 'block' : 'none'}}>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paymentLeftEur">Left by Invoice
                        <Button title="Pay all" icon={'pi pi-wallet'} className="p-button-link payments"
                                onClick={() => payAll('leftByInvoice')} style={{width: "auto", marginLeft: "5px", padding: "0"}}/>
                    </label>
                    <InputNumber id={'paymentLeftEur'} value={props.invoice["leftToPay"]} disabled
                               suffix={!isNullOrUndef(props.invoice.currency) ? ' '+props.invoice.currency.code : ''}/>
                </div>
            </div>
        </div>
        <div className="p-col-1">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="paidUnpaidOption">Paid/Unpaid</label>
                    <Dropdown value={props.invoice.paidType} options={paidUnpaidOptions} optionValue={'paidType'}
                              optionLabel={'title'} onChange={e => onChangeInput(e.value, 'paidType')}  placeholder="-- Select --" />
                </div>
            </div>
        </div>
        <div className="p-col-12">
            <DataTable header={'Payments'} value={props.invoice.invoicePayments}
                       showGridlines responsiveLayout="scroll"
                       size="small" editMode="row" dataKey="id" editingRows={editingRows}
                       onRowEditChange={() => {}} onRowEditComplete={() => {}}>
                <Column columnKey={'date'} field={'date'} header={'Payment date'}
                        body={data => dateBodyTemplate(data, 'date')}
                        editor={(options) => dateEditor(options)}/>
                <Column columnKey={'sum'} field={'sum'} header={'Sum'} editor={(options) => numberEditor(options)}/>
                <Column columnKey={'currency'} field={'currency'} editor={(options) => currencyEditor(options)}
                        body={currencyTemplate} header={'Currency'}/>
                <Column field={'currencyRate'} header={'Exchange Rates'}/>
                <Column columnKey={'action'} headerClassName={'center'} header={headerTemplate}
                        style={{width: '90px'}} body={actionTemplate}/>
            </DataTable>
        </div>
    </div>
}
